import {createContext, useContext, useState} from 'react';

import {COLORS} from '@shared/const/appPalette';
import {CheckedIcon, CloseIcon} from '@src/components/common/icons';
import {Modal} from '@src/components/common/modal';

import {Button} from '../..';

import {useEmotionWrapper} from './confirm-dialog.s';

interface TOpenDialog {
  title: string;
  message: string | JSX.Element;
  actionCallback?: Function;
  confirmButton?: {
    text?: string;
    color?: string;
  };
  cancelButton?: {
    text?: string;
    color?: string;
  };
  additionalButton?: {
    text: string;
    onClick: Function;
    color?: string;
  };
}

interface IConfirmationDialog {
  open: boolean;
  title: string | JSX.Element;
  message: string | JSX.Element;
  onConfirm: Function;
  onDismiss: Function;
  confirmButton?: {
    text?: string;
    color?: string;
  };
  cancelButton?: {
    text?: string;
    color?: string;
  };
  additionalButton?: {
    text: string;
    onClick: Function;
    color?: string;
  };
  resetDialog: Function;
}

interface TConfirmationDialogContext {
  openDialog: (options: TOpenDialog) => void;
}

type TDialogConfig = {
  title: string;
  message: string | JSX.Element;
  actionCallback?: Function;
  confirmButton?: {
    text?: string;
    color?: string;
  };
  cancelButton?: {
    text?: string;
    color?: string;
  };
  additionalButton?: {
    text: string;
    onClick: Function;
    color?: string;
  };
} | null;

const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onDismiss,
  cancelButton,
  confirmButton,
  additionalButton,
  resetDialog,
}: IConfirmationDialog) => {
  const {classes} = useEmotionWrapper();

  if (!open) return null;

  return (
    <Modal opened={open} onClose={() => onDismiss()} title={title}>
      <span className={classes.message}>{message}</span>
      <div
        style={{justifyContent: additionalButton ? 'space-between' : 'flex-end'}}
        className={classes.buttonsContainer}
      >
        {additionalButton && (
          <Button
            onClick={() => {
              additionalButton?.onClick();
              resetDialog();
            }}
            color={COLORS.BLACK_40}
            variant="text"
          >
            {additionalButton?.text}
          </Button>
        )}
        <div className={classes.buttonsBlock}>
          <Button
            icon={<CloseIcon size="12" />}
            variant="outlined"
            color={cancelButton?.color ? cancelButton.color : COLORS.BLACK_40}
            onClick={() => onDismiss()}
          >
            {cancelButton?.text || 'Отменить'}
          </Button>
          <Button
            icon={<CheckedIcon size="24" />}
            variant="outlined"
            color={confirmButton?.color ? confirmButton.color : COLORS.RED_100}
            onClick={() => onConfirm()}
          >
            {confirmButton?.text || 'Подтвердить'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ConfirmationDialogContext = createContext<TConfirmationDialogContext>({} as TConfirmationDialogContext);

const ConfirmationDialogProvider = ({children}: {children: React.ReactNode}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<TDialogConfig>(null);

  const openDialog = ({title, message, actionCallback, confirmButton, cancelButton, additionalButton}: TOpenDialog) => {
    setDialogOpen(true);
    setDialogConfig({title, message, actionCallback, confirmButton, cancelButton, additionalButton});
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(null);
  };

  const onConfirm = () => {
    dialogConfig && dialogConfig.actionCallback && dialogConfig.actionCallback(true);
    resetDialog();
  };

  const onDismiss = () => {
    dialogConfig && dialogConfig.actionCallback && dialogConfig.actionCallback(false);
    resetDialog();
  };

  return (
    <ConfirmationDialogContext.Provider value={{openDialog}}>
      <ConfirmationDialog
        resetDialog={resetDialog}
        open={dialogOpen}
        title={dialogConfig ? dialogConfig?.title : ''}
        message={dialogConfig ? dialogConfig?.message : ''}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        confirmButton={dialogConfig?.confirmButton}
        cancelButton={dialogConfig?.cancelButton}
        additionalButton={dialogConfig?.additionalButton}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const {openDialog} = useContext<TConfirmationDialogContext>(ConfirmationDialogContext);

  const getConfirmation = ({title, message, ...options}: TOpenDialog) =>
    new Promise((res) => {
      openDialog({title, message, actionCallback: res, ...options});
    });
  return {getConfirmation};
};

export default ConfirmationDialog;
export {ConfirmationDialogProvider, useConfirmationDialog};
