import {toast} from 'react-toastify';
import axios from 'axios';

import {getJWTToken} from '../utils/getJWTToken';

const api = axios.create({
  baseURL: import.meta.env.WEB_API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const accessToken = getJWTToken().accessToken;
  if (!accessToken) {
    return Promise.reject();
  }
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
});

api.interceptors.request.use((config) => {
  const {params, method} = config;
  if (params && method === 'get') {
    const newReq = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            newReq.append(key, val);
          });
        } else {
          value && newReq.append(key, value.toString());
        }
      }
    });

    config.params = newReq;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const res = error?.response;
    const errorType = res?.data?.data?.type;
    if (error?.code === 'ERR_NETWORK') {
      toast.error('Пожалуйста, проверьте подключение и попробуйте ещё раз.');
      return Promise.reject(error);
    } else if (errorType === 'IncorrectMaxBidderFeePct') {
      toast.error(`Ставка не может быть меньше - ${res?.data?.data.min_fee}% и больше - ${res?.data?.data.max_fee}%`);
      return Promise.reject(error);
    } else if (res?.data.data?.error === 'AuthError') {
      return Promise.reject(error);
    } else if (res?.data?.error === 'AuthError') {
      return Promise.reject(error);
    } else {
      toast.error(res?.data.message);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default api;
