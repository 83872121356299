import {useMemo} from 'react';

import {TARIFFS_TYPES} from '@src/shared/enums/tariffs';
import {useStore} from '@src/stores';

export const useTariff = (tariffId?: number) => {
  const {tariffs} = useStore();

  const tariff = tariffId ? tariffs.list.find((t) => t.id === tariffId) : null;

  const getLimitPriceMonitoringItems = useMemo(() => {
    switch (tariff?.type) {
      case TARIFFS_TYPES.ECONOMY:
        return 100;
      case TARIFFS_TYPES.PROFESSIONAL:
        return 500;
      case TARIFFS_TYPES.LEADER:
        return 1500;

      default:
        return 0;
    }
  }, [tariff?.id]);

  return {
    tariff: tariff,
    limitPriceMonitoringItems: getLimitPriceMonitoringItems,
  };
};
