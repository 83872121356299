import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
  },
  conditionList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '12vh',
    overflow: 'auto',
  },
  buttonsBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  conditionsTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  conditionsTypeButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 20,
  },
}));
