export const CopyToClipboardIcon = ({size = '12', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66667 4.66667C5.66667 3.19391 6.86057 2 8.33333 2H11.6667C11.8435 2 12.013 2.07024 12.1381 2.19526L16.1381 6.19526C16.2631 6.32029 16.3333 6.48986 16.3333 6.66667V12.6667C16.3333 14.1394 15.1394 15.3333 13.6667 15.3333C13.6667 16.8061 12.4728 18 11 18H5.66667C4.19391 18 3 16.8061 3 15.3333V8C3 6.52724 4.19391 5.33333 5.66667 5.33333V4.66667ZM12.3333 15.3333H8.33333C6.86057 15.3333 5.66667 14.1394 5.66667 12.6667V6.66667C4.93029 6.66667 4.33333 7.26362 4.33333 8V15.3333C4.33333 16.0697 4.93029 16.6667 5.66667 16.6667H11C11.7364 16.6667 12.3333 16.0697 12.3333 15.3333ZM8.33333 3.33333C7.59695 3.33333 7 3.93029 7 4.66667V6V12.6667C7 13.403 7.59695 14 8.33333 14H13H13.6667C14.403 14 15 13.403 15 12.6667V7.33333H13C11.8954 7.33333 11 6.4379 11 5.33333V3.33333H8.33333ZM12.3333 4.27614L14.0572 6H13C12.6318 6 12.3333 5.70152 12.3333 5.33333V4.27614Z"
        fill={color}
      />
    </svg>
  );
};
