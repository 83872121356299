import {useContext} from 'react';
import axios from 'axios';

import {StoreContext} from '../stores';

export const TELEGRAM_NOTIFY_CONFIG = {
  WEBHOOK_BASE_URL: 'https://eowavozyhrytif2.m.pipedream.net',
  BUGS_MESSAGE_TYPE: {
    PAYMENT: 'payment',
    ITEMS_LOAD: 'items',
    CREATE_INVOICE: 'create_invoice',
    AUTH_ERROR: 'auth_error',
  },
  PLATFORM: 'tg',
};

export const useCreateBugNotify = () => {
  const store = useContext(StoreContext);
  const {user, mpAccounts} = store;

  const payment = (e?: string) => {
    axios.post(TELEGRAM_NOTIFY_CONFIG.WEBHOOK_BASE_URL, {
      platform: TELEGRAM_NOTIFY_CONFIG.PLATFORM,
      data: {
        chatId: import.meta.env.WEB_TELEGRAM_NOTIFY_CHAT_ID,
        mode: import.meta.env.WEB_APP_MODE,
        error: TELEGRAM_NOTIFY_CONFIG.BUGS_MESSAGE_TYPE.PAYMENT + '. ' + 'Info: ' + e,
        email: user.data.email,
        accountName: mpAccounts.current?.name,
      },
    });
  };

  const loadItems = () =>
    axios.post(TELEGRAM_NOTIFY_CONFIG.WEBHOOK_BASE_URL, {
      platform: TELEGRAM_NOTIFY_CONFIG.PLATFORM,
      data: {
        chatId: import.meta.env.WEB_TELEGRAM_NOTIFY_CHAT_ID,
        mode: import.meta.env.WEB_APP_MODE,
        error: TELEGRAM_NOTIFY_CONFIG.BUGS_MESSAGE_TYPE.ITEMS_LOAD,
        email: user.data.email,
        accountName: mpAccounts.current?.name,
      },
    });

  const createInvoice = () =>
    axios.post(TELEGRAM_NOTIFY_CONFIG.WEBHOOK_BASE_URL, {
      platform: TELEGRAM_NOTIFY_CONFIG.PLATFORM,
      data: {
        chatId: import.meta.env.WEB_TELEGRAM_NOTIFY_CHAT_ID,
        mode: import.meta.env.WEB_APP_MODE,
        error: TELEGRAM_NOTIFY_CONFIG.BUGS_MESSAGE_TYPE.CREATE_INVOICE,
        email: user.data.email,
        accountName: mpAccounts.current?.name,
      },
    });

  const authError = () =>
    axios.post(TELEGRAM_NOTIFY_CONFIG.WEBHOOK_BASE_URL, {
      platform: TELEGRAM_NOTIFY_CONFIG.PLATFORM,
      data: {
        chatId: import.meta.env.WEB_TELEGRAM_NOTIFY_CHAT_ID,
        mode: import.meta.env.WEB_APP_MODE,
        error: TELEGRAM_NOTIFY_CONFIG.BUGS_MESSAGE_TYPE.AUTH_ERROR,
        email: user.data.email || '',
        accountName: mpAccounts.current?.name || '',
      },
    });

  const createBugNotify = {
    payment: payment,
    loadItems: loadItems,
    createInvoice: createInvoice,
    authError: authError,
  };
  return createBugNotify;
};
