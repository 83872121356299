export enum LOG_TYPE {
  UPDATE = 'update',
  BUGFIX = 'bugfix',
  FEATURE = 'feature',
}

export const LOGS_TYPES = {
  [LOG_TYPE.UPDATE]: 'ОБНОВИЛИ',
  [LOG_TYPE.BUGFIX]: 'ИСПРАВИЛИ',
  [LOG_TYPE.FEATURE]: 'ДОБАВИЛИ',
};
