import {type FC, useEffect, useState} from 'react';
import {SingleValue} from 'react-select';

import {COLORS} from '@shared/const/appPalette';
import {CheckedIcon, SettingsIcon} from '@src/components/common/icons';
import {RECOMMENDATION_STRATEGY, TRecommendationStrategyValues} from '@src/shared/enums/bidder-strategy';

import {Button, CustomSelect, Modal, Typography} from '../..';

import {useEmotionWrapper} from './strategy-settings-modal.s';

interface IStrategySettingsModal {
  opened: boolean;
  onClose: () => void;
  onUpdateShowPercent?: (value: RECOMMENDATION_STRATEGY) => void;
  value: TRecommendationStrategyValues;
}

const SHOW_PERCENT_VALUES = [
  {
    label: '85-100%',
    value: RECOMMENDATION_STRATEGY.HIGH,
  },
  {
    label: '60-85%',
    value: RECOMMENDATION_STRATEGY.MEDIUM,
  },
  {
    label: '30-60%',
    value: RECOMMENDATION_STRATEGY.LOW,
  },
];

type TCurrentShowPercent = SingleValue<{label: string; value: RECOMMENDATION_STRATEGY}> | undefined;

export const StrategySettingsModal: FC<IStrategySettingsModal> = ({
  opened = true,
  onClose,
  onUpdateShowPercent,
  value,
}) => {
  const {classes} = useEmotionWrapper();
  const [currentShowPercent, setCurrentShowPercent] = useState<TCurrentShowPercent>({
    label: '85-100%',
    value: RECOMMENDATION_STRATEGY.HIGH,
  });

  const onClickUpdatePercent = () => {
    if (currentShowPercent?.value) onUpdateShowPercent && onUpdateShowPercent(currentShowPercent?.value);
    onClose();
  };

  useEffect(() => {
    const findCurrentShowPercent = SHOW_PERCENT_VALUES.find((item) => item.value === value);
    setCurrentShowPercent(findCurrentShowPercent);
  }, [value]);

  return (
    <Modal icon={<SettingsIcon />} title="Настройки стратегии" opened={opened} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.percentBlock}>
          <Typography>Процент показов</Typography>
          <CustomSelect
            value={currentShowPercent}
            onChange={(e) => setCurrentShowPercent(e)}
            defaultValue={SHOW_PERCENT_VALUES[0]}
            options={SHOW_PERCENT_VALUES}
            width={120}
          />
        </div>
        <Button
          onClick={onClickUpdatePercent}
          color={COLORS.GREEN_100}
          icon={<CheckedIcon size="22px" />}
          variant="outlined"
        >
          Cохранить
        </Button>
      </div>
    </Modal>
  );
};
