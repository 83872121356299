import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {Tooltip, Typography} from '@src/components';
import {ChevronIcon, UserAvatarIcon} from '@src/components/common/icons';
import {COLORS} from '@src/shared/const/appPalette';
import {
  IS_VIEWED_ONBOARDING_TOUR,
  USER_AUTH_ACCESS_TOKEN,
  USER_AUTH_REFRESH_TOKEN,
} from '@src/shared/const/localStorage';
import {getRoutePricing, getRouteProducts, getRouteSettings} from '@src/shared/const/router';
import {extractUsernameFromEmail} from '@src/shared/utils/extract-user-name-from-email';
import {useStore} from '@src/stores';

import packageJson from '../../../../package.json';

import {useEmotionWrapper} from './user-profile-dropdown.s';

export const UserProfileDropdown = observer(() => {
  const {classes} = useEmotionWrapper();
  const {user} = useStore();
  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const {setIsOpen, setCurrentStep} = useTour();

  const getUserLogin = () => {
    return (
      <Typography color={COLORS.DARK_70} size={14} weight="bold">
        {extractUsernameFromEmail(user.data.email)}
      </Typography>
    );
  };

  const handleClickLogout = () => {
    localStorage.removeItem(USER_AUTH_ACCESS_TOKEN);
    localStorage.removeItem(USER_AUTH_REFRESH_TOKEN);
    window.open('https://smartseller.me/', '_self');
  };

  function Menu() {
    return (
      <div className={classes.menuRoot}>
        <a
          className={classes.menuItem}
          onClick={() => {
            navigate(getRouteSettings());
            setIsOpenMenu(false);
          }}
        >
          <Typography size={13} color={COLORS.DARK_70}>
            Настройки
          </Typography>
        </a>
        <a
          onClick={() => {
            navigate(getRoutePricing());
            setIsOpenMenu(false);
          }}
          className={classes.menuItem}
        >
          <Typography size={13} color={COLORS.DARK_70}>
            Тарифы
          </Typography>
        </a>
        <a
          onClick={() => {
            navigate(getRouteProducts());
            localStorage.removeItem(IS_VIEWED_ONBOARDING_TOUR);
            setCurrentStep(0);
            setIsOpen(true);
          }}
          className={classes.menuItem}
        >
          <Typography size={13} color={COLORS.DARK_70}>
            Обучающий тур
          </Typography>
        </a>
        <a onClick={() => handleClickLogout()} className={classes.menuItem}>
          <Typography size={13} color={COLORS.DARK_70}>
            Выйти
          </Typography>
        </a>
        <Typography size={11} color={COLORS.BLACK_50}>
          Версия: {packageJson.version}
        </Typography>{' '}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Tooltip
        position="bottom-end"
        visible={isOpenMenu}
        onClickOutside={() => setIsOpenMenu(false)}
        delay={0}
        arrow={false}
        trigger="click"
        appendTo="parent"
        interactive
        content={<Menu />}
      >
        <div
          data-cy="dropdown-user-profile"
          className={classes.userLoginBlock}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          {getUserLogin()}
          <div className={classes.dropdown}>
            <UserAvatarIcon />
            <ChevronIcon
              style={{transform: isOpenMenu ? 'rotate(-90deg)' : 'rotate(90deg)', transition: 'all .2s ease-in-out'}}
              color={COLORS.BLACK_40}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
});
