import {type FC} from 'react';

import {CustomSelect, IconButton, Input, Tooltip, Typography, useConfirmationDialog} from '@src/components';
import {RemoveIcon} from '@src/components/common/icons';
import {
  ITEM_FEE_CONDITIONS,
  ITEM_FEE_CONDITIONS_SELECT_OPTIONS,
  ITEM_FEE_CONDITIONS_TYPES,
} from '@src/shared/enums/fee-conditions';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';
import {TFeeConditionsModel} from '@src/stores/shared-models/fee-conditions-model';

import {useEmotionWrapper} from './storage-or-margin-condition.s';

interface IStorageOrMarginCondition {
  condition: TFeeConditionsModel;
  removeCondition: (index: number) => void;
  onChangeCondition: (index: number, condition: TFeeConditionsModel) => void;
  isValidConditions: boolean;
  conditionsCount: number;
  hidePromoType: boolean;
}

export const StorageOrMarginCondition: FC<IStorageOrMarginCondition> = ({
  condition,
  removeCondition,
  onChangeCondition,
  isValidConditions,
  hidePromoType,
}) => {
  const {classes} = useEmotionWrapper();
  const {getConfirmation} = useConfirmationDialog();
  const {maxBidderFeePct, type, min, max} = condition;
  const handleRemoveCondition = async () => {
    const confirm = await getConfirmation({
      title: 'Удалить условие?',
      message: (
        <div className={classes.confirmDialogContainer}>
          <Typography weight="bold">
            Установить ДРР {maxBidderFeePct}%{' '}
            {ITEM_FEE_CONDITIONS_SELECT_OPTIONS.find((option) => option.value === type)?.label} от {min} до {max}{' '}
            {type === ITEM_FEE_CONDITIONS.MARGIN ? '%   ' : 'шт'}
          </Typography>
          <Typography>Вы уверены, что хотите удалить это условие?</Typography>
        </div>
      ),
    });
    if (confirm) {
      condition.id !== null && removeCondition(condition.id);
    }
  };

  return (
    <Tooltip destroy={isValidConditions} content="Это условие противоречит условию, которое было создано ранее">
      <div className={classes.conditionItem}>
        <CustomSelect
          isError={!isValidConditions}
          width={185}
          isClearable={false}
          isSearchable={false}
          onChange={(e) => {
            condition.id && e?.value && onChangeCondition(condition.id, {...condition, type: e?.value});
          }}
          value={ITEM_FEE_CONDITIONS_TYPES.find((option) => option.value === type)}
          options={
            hidePromoType
              ? ITEM_FEE_CONDITIONS_TYPES.filter((option) => option.value !== ITEM_FEE_CONDITIONS.PROMO)
              : ITEM_FEE_CONDITIONS_TYPES
          }
        />
        <Typography whiteSpace="nowrap" color={!isValidConditions ? 'red' : 'black'}>
          Установить ДРР{' '}
        </Typography>
        <div style={{display: 'flex', gap: 3, alignItems: 'center'}}>
          <Input
            placeholder="0"
            data-cy="condition-max-bidder-fee-pct"
            isError={!isValidConditions}
            width={55}
            onChange={(e) => {
              condition.id !== null &&
                onChangeCondition(condition.id, {...condition, maxBidderFeePct: stringToNumberWithDot(e.target.value)});
            }}
            value={maxBidderFeePct}
          />
          <Typography color={!isValidConditions ? 'red' : 'black'}> % </Typography>
        </div>
        <Typography color={!isValidConditions ? 'red' : 'black'}> от </Typography>
        <Input
          data-cy="condition-min"
          placeholder="0"
          isError={!isValidConditions}
          width={55}
          onChange={(e) =>
            condition.id !== null &&
            onChangeCondition(condition.id, {...condition, min: stringToNumberWithDot(e.target.value)})
          }
          value={min || undefined}
        />
        <Typography color={!isValidConditions ? 'red' : 'black'}> до </Typography>
        <div style={{display: 'flex', gap: 3, alignItems: 'center'}}>
          <Input
            placeholder="0"
            data-cy="condition-max"
            isError={!isValidConditions}
            width={55}
            onChange={(e) =>
              condition.id !== null &&
              onChangeCondition(condition.id, {...condition, max: stringToNumberWithDot(e.target.value)})
            }
            value={(max && max.toString()) || undefined}
          />
          <Typography color={!isValidConditions ? 'red' : 'black'}>
            {type === ITEM_FEE_CONDITIONS.MARGIN ? '%   ' : 'шт'}{' '}
          </Typography>
        </div>
        <IconButton style={{marginLeft: 'auto'}} data-cy="remove-condition" onClick={handleRemoveCondition}>
          <RemoveIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};
