export const SortDescIcon = ({size = '16px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.54687 0.390668C4.42584 0.265808 4.2593 0.195334 4.08539 0.195334C3.91147 0.195334 3.74493 0.265808 3.6239 0.390668L0.195333 3.92638C-0.0518663 4.18126 -0.0456102 4.58827 0.209304 4.83539C0.464218 5.08259 0.871188 5.07633 1.1183 4.82142L3.44253 2.42459V11.1239C3.44253 11.4789 3.73036 11.7668 4.08539 11.7668C4.44041 11.7668 4.72824 11.4789 4.72824 11.1239V2.42459L7.05247 4.82142C7.29958 5.07633 7.70656 5.08259 7.96147 4.83539C8.21639 4.58827 8.22264 4.18126 7.97544 3.92638L4.54687 0.390668Z"
        fill="#9E6CF7"
      />
    </svg>
  );
};
