import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    fontWeight: 500,
    fontSize: 12,
    color: COLORS.BLACK_50,
    height: 56,
    padding: 16,
    whiteSpace: 'nowrap',
    '&:last-child': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    '&:first-of-type': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
  },
}));
