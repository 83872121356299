import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  tableBody: {
    tr: {
      '&: not(:last-child)': {
        borderBottom: `1.2px dashed ${COLORS.BLACK_15}`,
      },
      td: {
        height: 70,
        padding: 16,
      },
    },
  },
}));
