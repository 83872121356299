import {COLORS} from '@src/shared/const/appPalette';
import {emotionWrapper} from '@src/shared/lib';

export const useEmotionWrapper = emotionWrapper({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  dropdown: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    cursor: 'pointer',
  },
  menuItem: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: COLORS.PURPLE_100,
      transition: 'color .3s',
    },
    '&:active': {
      color: COLORS.PURPLE_100,
    },
  },
  menuRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    width: 120,
  },
  disabledMeneItem: {
    cursor: 'not-allowed',
  },
  userLoginBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
    span: {
      transition: 'color .3s',
      userSelect: 'none',
    },
    '&:hover': {
      span: {
        textDecoration: 'underline',
        color: COLORS.PURPLE_100,
        transition: 'color .3s',
      },
    },
  },
});
