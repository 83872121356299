import dayjs from 'dayjs';

export const decodeJWT = (JWT: string | null) => {
  const jwtRegex = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

  if (JWT) {
    if (jwtRegex.test(JWT)) {
      const splittedJWT = JWT.split('.');
      return dayjs.unix(JSON.parse(atob(splittedJWT[1])).exp);
    } else {
      return null;
    }
  }
};
