export const CopyIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M17.6 7.38995L10.8754 14.1146C10.602 14.3879 10.1588 14.3879 9.88543 14.1146C9.61206 13.8412 9.61206 13.398 9.88543 13.1246L16.6101 6.4H12.7C12.3134 6.4 12 6.0866 12 5.7C12 5.3134 12.3134 5 12.7 5H18.3C18.6866 5 19 5.3134 19 5.7V11.3C19 11.6866 18.6866 12 18.3 12C17.9134 12 17.6 11.6866 17.6 11.3V7.38995ZM17.6 14.8C17.6 14.4134 17.9134 14.1 18.3 14.1C18.6866 14.1 19 14.4134 19 14.8V16.2C19 17.7464 17.7464 19 16.2 19H7.8C6.2536 19 5 17.7464 5 16.2V7.8C5 6.2536 6.2536 5 7.8 5H9.2C9.5866 5 9.9 5.3134 9.9 5.7C9.9 6.0866 9.5866 6.4 9.2 6.4H7.8C7.0268 6.4 6.4 7.0268 6.4 7.8V16.2C6.4 16.9732 7.0268 17.6 7.8 17.6H16.2C16.9732 17.6 17.6 16.9732 17.6 16.2V14.8Z"
        fill={color}
      />
    </svg>
  );
};
