import React from 'react';

import createCache, {EmotionCache} from '@emotion/cache';
import {CSSObject, Global} from '@emotion/react';
import {serializeStyles} from '@emotion/serialize';
import {getRegisteredStyles, insertStyles} from '@emotion/utils';

import {normalize} from '../../../shared/lib/emotion/normalize';

export interface EmotionProviderProps {
  withNormalize?: boolean;
  global?: CSSObject | CSSObject[];
  children: React.ReactNode;
}

export type CLSX = Array<string | Array<string> | Record<string, boolean>>;

export const EmotionProvider = ({withNormalize, global, children}: EmotionProviderProps) => {
  return (
    <React.Fragment>
      {(withNormalize || global) && (
        <Global styles={([] as CSSObject[]).concat(withNormalize ? normalize : [], global || [])} />
      )}
      {children}
    </React.Fragment>
  );
};

export const clsx = (...args: CLSX) => {
  return [
    ...new Set(
      args.reduce((previous: string[], current) => {
        if (typeof current === 'string' || Array.isArray(current)) {
          return previous.concat(current);
        } else {
          return previous.concat(
            Object.entries(current).reduce((previous: string[], current) => {
              return current[1] ? previous.concat(current[0]) : previous;
            }, [])
          );
        }
      }, [])
    ),
  ]
    .filter((value) => value !== '')
    .join(' ');
};

export const useCache = (() => {
  let cache: EmotionCache;

  return () => {
    cache === undefined && (cache = createCache({key: 'css'}));
    return cache;
  };
})();

export const useInserter = (cache: EmotionCache) => {
  return (...styles: any) => {
    const serialized = serializeStyles(styles, cache.registered);
    insertStyles(cache, serialized, false);

    return `${cache.key}-${serialized.name}`;
  };
};

export const useMerger = (cache: EmotionCache, clsx: string) => {
  const inserter = useInserter(cache);
  const styles: string[] = [];
  getRegisteredStyles(cache.registered, styles, clsx);

  return inserter(styles);
};

export const emotionWrapper = <Key extends string = string, Props = void>(
  styles: Record<Key, CSSObject> | ((props: Props) => Record<Key, CSSObject>)
) => {
  return (props: Props) => {
    const cache = useCache();
    const inserter = useInserter(cache);

    const stylesFromWrapper = typeof styles === 'function' ? styles(props) : styles;

    const classes = (Object.keys(stylesFromWrapper) as Array<Key>).reduce((previous, current) => {
      return Object.assign(previous, {
        [current]: inserter(stylesFromWrapper[current]),
      });
    }, {}) as Record<Key, string>;

    const cx = (...args: CLSX) => useMerger(cache, clsx(...args));

    return {classes, cx};
  };
};
