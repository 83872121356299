import {Dispatch, type FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {StepType, TourProvider} from '@reactour/tour';
import {Button} from '@src/components';
import {Typography} from '@src/components/common/typography';
import {COLORS} from '@src/shared/const/appPalette';
import {IS_VIEWED_ONBOARDING_TOUR} from '@src/shared/const/localStorage';
import {getRouteProducts, getRouteRules} from '@src/shared/const/router';
import {useStore} from '@src/stores';

import {ContentWrapper} from './components/content-wrapper/content-wrapper';

interface IUserOnboardingTour {
  children: React.ReactNode;
}

type TStyle = {[key: string]: string};
type TBtnFnProps = {
  Button: React.FC<React.PropsWithChildren<{onClick?: () => void; kind?: 'next' | 'prev'; hideArrow?: boolean}>>;
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  stepsLength: number;
  currentStep: number;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  steps?: StepType[];
};

export const UserOnboardingTour: FC<IUserOnboardingTour> = observer(({children}) => {
  const navigate = useNavigate();
  const {rules} = useStore();

  const steps: StepType[] = [
    {
      selector: '#onboarding-welcome',
      action: () => navigate(getRouteProducts()),
      position: 'center',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Добро пожаловать в Smart Seller! С нами управлять ставкой Буста на Яндекс Маркете можно с помощью
            искусственного интеллекта, оптимизовать расходы на рекламу и увеличивать продажи.
            <br />
            <br />
            Провести небольшой тур по приложению?
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-current-market-place-account',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Можно выбрать Кабинет для работы. На вашем Яндекс ID может быть несколько Кабинетов
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-products-filters',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Наш сервис работает со ставками по Магазинам (моделям доставки). По-умолчанию на главном экране выбраны Все
            магазины.
            <br />
            <br />
            Если товар находится в нескольких магазинах, то он будет отображаться одной стопкой товаров, чтобы удобнее
            управлять ставками и стратегиями по Магазинам.
            <br />
            <br />
            Так вы сможете в зависимости от расходов по каждой модели доставки назначать свои рекламные бюджеты.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-first-product',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            На главном экране вы можете видеть свои товары в статусе “Готов к продаже”, их цены, остатки, стратегию
            продвижения для Буста продаж, текущую ставку и максимальную ставку, установленную вами как ограничение.
            <br />
            <br />
            Обратите внимание, что мы не отображаем текущие стратегии и ставки, установленные вами на самом Яндекс
            Маркете.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-product-analytics-button',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Для товара доступна детальная Аналитика по продажам и конверсиям карточки.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-product-price-button',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Цену на товар можно менять сразу из нашего сервиса по всем доступным для товара Магазинам.
            <br />
            <br />
            Для эффективной работы Буста продаж можно указать Маржинальность товара и настроить условия для работы
            стратегии.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-product-current-fee-pct',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Тут отображается текущая ставка, которую наш сервис отправил на Яндекс Маркет.
            <br />
            <br />
            Ставки обновляются каждые 2 часа.
            <br />
            <br />
            При наведении на ставку можно посмотреть рекомендации Яндекс Маркета по ставке на этот товар.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-product-strategy',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Настроить стратегию можно как на товар отдельно, так и массово в разделе Буст продаж.
            <br />
            <br />
            Для это необходимо просто выбрать одну из стратегий: Искусственный интеллект, Рекомендации Я.Маркета или
            Ручное управление и указать Максимальный порог ставки (ДРР) за который наш сервис не должен выходить.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-product-storage-count',
      action: () => {
        navigate(getRouteProducts());
      },
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Остатки обновляются раз в сутки автоматически, но не переживайте, если необходимо сделать это срочно, то
            есть соответствующая кнопка в Настройках (они прячутся под иконкой профиля)
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sidebar-groups',
      position: 'right',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Товары можно группировать, а затем на группы товаров назначать стратегии
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sidebar-analytics',
      position: 'right',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            В Аналитике можно увидеть информацию по всем продажам, продажам с бустом и без буста, расходам на буст,
            кликам и показам, процентам возвратов и отмен. Функциональность аналитики постоянно дополняется новыми
            метриками и дашбордами.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sidebar-price-monitoring',
      position: 'right',
      actionAfter: () => navigate(getRouteProducts()),
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Раздел Мониторинг цен позволяет автоматически управлять ценой товара (репрайсер). Автоматическое управление
            Софинансом от Яндекс Маркета, рекомендации по привлекательности цен. Мониторинг цен конкурентов и
            динамические стратегии.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sidebar-sales-boost',
      position: 'right',
      actionAfter: () => navigate(getRouteRules()),
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            А тут можно настроить стратегии Буста продаж на товары и задать дополнительные условия работы для наибольшей
            эффективности
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost',
      position: 'center',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            В разделе Буст продаж находятся все ваши стратегии.
            <br />
            <br />
            Стратегию можно задать как на все Магазины, так и на один или несколько.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-default-strategy',
      action: () => rules.changeRuleModalStatus(false),
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Для старта Буста продаж на все товары можете включить стратегию Все товары.
            <br />
            <br />
            Для этого нажмите на кнопку Выключено, выберете необходимую стратегию и рекламную ставку.
            <br />
            <br />
            Продвижение запустится в течении 15 минут.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-strategy-button',
      position: 'center',
      actionAfter: () => rules.changeRuleModalStatus(true),
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Для настройки на Магазины, Категории или Бренды перейдите в создание стратегии.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-strategy',
      position: 'center',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Это окно редактирования и создания стратегии.
            <br />
            <br />
            Давайте приступим к настройке.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-strategy-name',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Укажите название стратегии
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-strategy-filters',
      position: 'left',
      styles: {
        // maskWrapper: (base: {[key: string]: string}) => ({...base, zIndex: 1, padding: 0}),
        // maskArea: (base: TStyle) => ({...base, rx: 10}),
      },
      padding: {
        mask: [10, 10, 200, 10],
      },
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Выберете фильтры, на которые хотите чтобы действовала стратегия. Это могут быть Магазины, Категории, Бренды
            или Группы.
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-strategy-select',
      position: 'left',
      styles: {
        // maskWrapper: (base: {[key: string]: string}) => ({...base, zIndex: 1}),
      },
      padding: {
        mask: [40, 90, 160, 10],
      },
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Выберете стратегию Искусственный интеллект
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-strategy-percent',
      position: 'left',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Укажите максимальный рекламный расход от цены товара в процентах
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-fee-conditions',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Для глубоких настроек можно добавить доп. условия для работы стратегии. Гибкое управление ставкой по
            расписанию или при изменении остатков или маржинальности товара
          </Typography>
        </ContentWrapper>
      ),
    },
    {
      selector: '#onboarding-sales-boost-create-save-button',
      content: (
        <ContentWrapper>
          <Typography paragraph align="justify">
            Нажмите Сохранить.
            <br />
            <br />
            Все!
            <br />
            Продвижение запустится в течении 15 минут
          </Typography>
        </ContentWrapper>
      ),
    },
  ];

  const getNextButtonLabel = (currentStep: number) => {
    const step = currentStep === steps.length - 1 ? -1 : currentStep;

    switch (step) {
      case -1:
        return 'Закрыть';
      case 12:
        return 'Перейти в буст продаж';
      case 15:
        return 'Создать стратегию';
      default:
        return 'Далее';
    }
  };

  const styles = {
    popover: (base: TStyle) => ({
      ...base,
      '--reactour-accent': COLORS.PURPLE_100,
      borderRadius: 10,
      border: `2px solid ${COLORS.BLACK_40}`,
    }),
    maskArea: (base: TStyle) => ({...base, rx: 10}),
    badge: (base: TStyle) => ({
      ...base,
      right: 'auto',
      left: '-0.8125em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    controls: (base: TStyle) => ({...base, marginTop: 10}),
  };

  const prevButton = ({currentStep, setCurrentStep, setIsOpen}: TBtnFnProps) => {
    const isFirst = currentStep === 0;
    return (
      <Button
        variant="outlined"
        color={COLORS.BLACK_100}
        onClick={() => {
          if (isFirst) {
            setIsOpen(false);
            localStorage.setItem(IS_VIEWED_ONBOARDING_TOUR, 'true');
          } else {
            setCurrentStep((s) => s - 1);
          }
        }}
      >
        {isFirst ? 'Пропустить обучение' : 'Назад'}
      </Button>
    );
  };

  const nextButton = ({currentStep, stepsLength, setIsOpen, setCurrentStep, steps}: TBtnFnProps) => {
    if (currentStep === 19) return null;

    const isLast = currentStep === stepsLength - 1;

    if (isLast) {
      localStorage.setItem(IS_VIEWED_ONBOARDING_TOUR, 'true');
    }

    return (
      <Button
        onClick={() => {
          if (isLast) {
            setIsOpen(false);
          } else {
            steps && setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
          }
        }}
      >
        {getNextButtonLabel(currentStep)}
      </Button>
    );
  };

  return (
    <TourProvider
      onClickClose={({setIsOpen}) => {
        localStorage.setItem(IS_VIEWED_ONBOARDING_TOUR, 'true');
        setIsOpen(false);
      }}
      steps={steps}
      showDots={false}
      startAt={0}
      onClickMask={() => {}}
      badgeContent={({totalSteps, currentStep}) => currentStep + 1 + '/' + totalSteps}
      prevButton={prevButton}
      nextButton={nextButton}
      styles={styles}
    >
      {children}
    </TourProvider>
  );
});
