export const QuestionIcon = ({size = '54px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M10.9248 38.0199V37.5341C10.9589 34.3636 11.2742 31.8409 11.8708 29.9659C12.4845 28.0909 13.3538 26.5739 14.4788 25.4148C15.6038 24.2557 16.9589 23.1989 18.5441 22.2443C19.5669 21.5966 20.4873 20.8722 21.3055 20.071C22.1237 19.2699 22.7714 18.3494 23.2487 17.3097C23.7259 16.2699 23.9646 15.1193 23.9646 13.858C23.9646 12.3409 23.6066 11.0284 22.8907 9.92046C22.1748 8.8125 21.2203 7.96023 20.0271 7.36364C18.8509 6.75 17.5384 6.44318 16.0896 6.44318C14.7771 6.44318 13.5242 6.71591 12.3311 7.26137C11.1379 7.80682 10.1492 8.65909 9.36515 9.81818C8.58106 10.9602 8.12935 12.4347 8.01003 14.2415H0.237305C0.356623 11.1733 1.13219 8.58239 2.56401 6.46875C3.99583 4.33807 5.88787 2.72727 8.24015 1.63636C10.6095 0.545454 13.2259 0 16.0896 0C19.2259 0 21.9703 0.588069 24.3225 1.76421C26.6748 2.9233 28.4987 4.55114 29.7941 6.64773C31.1066 8.72727 31.7629 11.1563 31.7629 13.9347C31.7629 15.8438 31.4646 17.5653 30.868 19.0994C30.2714 20.6165 29.4191 21.9716 28.3112 23.1648C27.2203 24.358 25.9078 25.4148 24.3737 26.3352C22.9248 27.2386 21.7487 28.1761 20.8453 29.1477C19.9589 30.1193 19.3112 31.2699 18.9021 32.5994C18.493 33.929 18.2714 35.5739 18.2373 37.5341V38.0199H10.9248ZM14.7856 53.5653C13.3879 53.5653 12.1862 53.071 11.1805 52.0824C10.1748 51.0767 9.67196 49.8665 9.67196 48.4517C9.67196 47.054 10.1748 45.8608 11.1805 44.8722C12.1862 43.8665 13.3879 43.3636 14.7856 43.3636C16.1663 43.3636 17.3595 43.8665 18.3651 44.8722C19.3879 45.8608 19.8992 47.054 19.8992 48.4517C19.8992 49.3892 19.6606 50.25 19.1833 51.0341C18.7231 51.8011 18.1095 52.4148 17.3424 52.875C16.5754 53.3352 15.7231 53.5653 14.7856 53.5653Z"
        fill={color}
      />
    </svg>
  );
};
