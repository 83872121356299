export const ChevronIcon = ({size = '16px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M6 3.33366L10.6667 8.00033L6 12.667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
