import {FC} from 'react';

import {PlusIcon} from '@src/components/common/icons';

import {useEmotionWrapper} from './create-button.s';

interface ICreateButton {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

export const CreateButton: FC<ICreateButton> = ({title, onClick, disabled = false, ...props}) => {
  const {classes} = useEmotionWrapper();

  return (
    <button disabled={disabled} onClick={onClick} className={classes.root} {...props}>
      <div className={classes.buttonContent}>
        <PlusIcon size="16" />
        {title}
      </div>
    </button>
  );
};
