import {type FC, useEffect, useRef, useState} from 'react';

import {COLORS} from '@shared/const/appPalette';
import {DropdownIcon} from '@src/components/common/icons';

import {Button, Typography} from '../..';

import {useEmotionWrapper} from './custom-dropdown.s';

type TMenuItem = {
  title: string;
  onClick: () => void;
};

interface ICustomDropdown {
  items: TMenuItem[];
}
export const CustomDropdown: FC<ICustomDropdown> = ({items}) => {
  const {classes} = useEmotionWrapper();
  const targetRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={targetRef} className={classes.root}>
      <Button color={COLORS.PURPLE_100} variant="outlined" onClick={handleOpen}>
        <div className={classes.buttonContent}>
          <Typography size={13} color={COLORS.PURPLE_100}>
            Помощь
          </Typography>
          <DropdownIcon
            style={{transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.2s ease'}}
            size="10px"
            color={COLORS.PURPLE_100}
          />
        </div>
      </Button>
      {open ? (
        <ul className={classes.menu}>
          {(items || []).map((item, index) => (
            <li
              onClick={() => {
                setOpen(false);
                item.onClick();
              }}
              key={index}
              className="menu-item"
            >
              {item.title}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
