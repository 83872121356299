import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

interface IProductCardsTooltipStyle {
  isDisabled: boolean;
}

export const useEmotionWrapper = emotionWrapper(({isDisabled}: IProductCardsTooltipStyle) => ({
  root: {
    width: 330,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    gap: 10,
  },
  linkList: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    li: {
      height: 28,
      filter: isDisabled ? 'grayscale(100%)' : 'none',
      pointerEvents: isDisabled ? 'none' : 'auto',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
  },
  liTitle: {
    display: 'flex',
    alignItems: 'center',
    span: {
      maxWidth: 270,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:hover': {
        color: COLORS.PURPLE_100,
        transition: 'color .3s ease',
      },
    },
  },
  copyToClipboard: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      cursor: 'pointer',
      '&:hover': {
        color: COLORS.PURPLE_100,
        transition: 'color .3s ease',
      },
    },
  },
  openTariffModalButton: {
    color: COLORS.PURPLE_100,
    cursor: 'pointer',
    '&:hover': {
      transition: 'color .3s ease',
      textDecoration: 'underline',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));
