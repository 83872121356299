import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLORS.BLACK_10,
  },
}));
