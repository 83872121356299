import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    width: 590,
  },
  consultationButton: {
    float: 'right',
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
}));
