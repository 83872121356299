import {FC, ReactNode, useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

import {ANIMATION_TIME} from '../const';

import {useEmotionWrapper} from './layout.s';

interface ILogout {
  onClose: () => void;
  opened: boolean;
  children: ReactNode;
}

export const Layout: FC<ILogout> = ({onClose, children, opened}) => {
  const {classes} = useEmotionWrapper();
  const overlayRef = useRef(null);
  const contentRef = useRef(null);
  const [animationIn, setAnimationIn] = useState(false);

  const overlayAnimation = {
    enter: classes.overlayEnter,
    enterActive: classes.overlayEnterActive,
    exit: classes.overlayExit,
    exitActive: classes.overlayExitActive,
  };

  const contentAnimation = {
    enter: classes.contentEnter,
    enterActive: classes.contentEnterActive,
    exit: classes.contentExit,
    exitActive: classes.contentExitActive,
  };

  useEffect(() => {
    setAnimationIn(opened);
  }, [opened]);

  return (
    <div className={classes.container}>
      <CSSTransition
        in={animationIn}
        nodeRef={overlayRef}
        timeout={ANIMATION_TIME}
        mountOnEnter
        unmountOnExit
        classNames={overlayAnimation}
      >
        <div ref={overlayRef} className={classes.overlay} onClick={onClose} />
      </CSSTransition>
      <CSSTransition
        in={animationIn}
        nodeRef={contentRef}
        timeout={ANIMATION_TIME}
        mountOnEnter
        unmountOnExit
        classNames={contentAnimation}
      >
        <div ref={contentRef} className={classes.content}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};
