import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  conditionItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  confirmDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
}));
