import React, {type FC} from 'react';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {LimitedAccount} from '@src/components/specific/limited-account/limited-account';
import {PriceMonitoringNotify} from '@src/components/specific/price-monitoring-notify/price-monitoring-notify';
import {TariffNotification} from '@src/components/specific/tariff-notification';
import {TrialOfferNotification} from '@src/components/specific/trial-offer-notification/trial-offer-notification';
import {useCurrentTariff} from '@src/hooks/useCurrenTariff';
import {getRoutePriceMonitoring} from '@src/shared/const/router';
import {TARIFFS_TYPES} from '@src/shared/enums/tariffs';

import {Notifications} from '../..';
import {Header} from '../header';
import {Sidebar} from '../sidebar';

import {useEmotionWrapper} from './app-container.s';

interface IAppContainer {
  children: React.ReactNode;
}

export const AppContainer: FC<IAppContainer> = observer(({children}) => {
  const {classes} = useEmotionWrapper();
  const {currentTariff} = useCurrentTariff();
  const location = useLocation();

  const isViewLimitedNotify = () => {
    return (
      (currentTariff.hadPaidTariff &&
        currentTariff.isTrial === false &&
        currentTariff.isLimited &&
        location.pathname !== getRoutePriceMonitoring()) ||
      (currentTariff.type === TARIFFS_TYPES.START && location.pathname === getRoutePriceMonitoring())
    );
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.appContainer}>
        {isViewLimitedNotify() && (
          <Notifications>
            <LimitedAccount />
          </Notifications>
        )}
        <TariffNotification />
        <PriceMonitoringNotify />
        <TrialOfferNotification />
        <div className={classes.contentContainer}>
          <Header />
          <div id="content" className={classes.contentBlock}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});
