import {type FC} from 'react';
import {observer} from 'mobx-react-lite';

import {IconButton} from '@src/components/common/buttons/icon-button';
import {CustomSelect} from '@src/components/common/custom-select';
import {RemoveIcon} from '@src/components/common/icons';
import {Input} from '@src/components/common/input';
import {Typography} from '@src/components/common/typography';
import {ITEM_FEE_CONDITIONS_TYPES} from '@src/shared/enums/fee-conditions';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';
import {TFeeConditionsModel} from '@src/stores/shared-models/fee-conditions-model';

interface IPromoCondition {
  condition: TFeeConditionsModel;
  onChangeCondition: (index: number, condition: TFeeConditionsModel) => void;
  removeCondition: (index: number) => void;
}

export const PromoCondition: FC<IPromoCondition> = observer(({condition, onChangeCondition, removeCondition}) => {
  return (
    <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
      <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
        <CustomSelect
          width={185}
          isClearable={false}
          isSearchable={false}
          onChange={(e) => {
            condition.id && e?.value && onChangeCondition(condition.id, {...condition, type: e?.value});
          }}
          value={ITEM_FEE_CONDITIONS_TYPES.find((option) => option.value === condition.type)}
          options={ITEM_FEE_CONDITIONS_TYPES}
        />
        <Typography>Если товар участвует в акции установить ДРР</Typography>
        <Input
          width={55}
          value={condition.maxBidderFeePct}
          onChange={(value) =>
            condition.id &&
            onChangeCondition(condition.id, {...condition, maxBidderFeePct: stringToNumberWithDot(value.target.value)})
          }
        />
        <Typography>%</Typography>
      </div>
      <IconButton
        style={{marginLeft: 'auto'}}
        data-cy="remove-condition"
        onClick={() => condition.id && removeCondition(condition.id)}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
});
