export const PriceMonitoringIcon = ({size = '18px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8001 3.9C4.8001 3.40294 4.39716 3 3.9001 3C3.40305 3 3.00011 3.40294 3.00011 3.9V15.5852C2.99996 15.5945 2.99996 15.6038 3.00011 15.6131V17.4C3.00011 19.3882 4.61188 21 6.60009 21H20.1C20.5971 21 21 20.597 21 20.1C21 19.6029 20.5971 19.2 20.1 19.2H6.60009C5.60598 19.2 4.8001 18.3941 4.8001 17.4V14.8643L8.03759 11.8282L10.4484 14.7747C11.2619 15.769 12.8251 15.6155 13.4297 14.4819L15.751 6.12941L18.4973 13.5622C18.8078 13.9504 19.3741 14.0133 19.7623 13.7028C20.1504 13.3923 20.2133 12.8259 19.9028 12.4378L17.1566 5.00496C16.3488 3.99516 14.7714 4.14131 14.1628 5.28235L11.8415 13.6349L9.43071 10.6884C8.65656 9.74219 7.18457 9.82648 6.52348 10.8548L4.8001 12.5357V3.9Z"
        fill={color}
      />
    </svg>
  );
};
