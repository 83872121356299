import {TCompetitorsPricesFromWs} from '../types';

export const mappedCompetitorsPricesFromWs = (competitors_prices: TCompetitorsPricesFromWs[]) => {
  return competitors_prices.map((competitor_price) => {
    const {is_self_price, model_prices, position, price, shop, total_offers} = competitor_price;

    return {
      position,
      shop,
      totalOffers: total_offers,
      isSelfPrice: is_self_price,
      modelPrices: model_prices
        ? {
            minPrice: model_prices.min_price / 100,
            maxPrice: model_prices.max_price / 100,
            avgPrice: model_prices.avg_price / 100,
          }
        : null,
      price: price && price / 100,
    };
  });
};
