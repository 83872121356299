import {Instance, types} from 'mobx-state-tree';

import {ITEM_FEE_CONDITIONS} from '@src/shared/enums/fee-conditions';

export const FeeConditionsScheduleModel = types.model('FeeConditionsScheduleModel', {
  timeFrom: types.string,
  timeTo: types.string,
  days: types.array(types.number),
});

export const FeeConditionsModel = types.model('FeeConditionsModel', {
  id: types.maybeNull(types.number),
  type: types.enumeration('FeeConditionType', [
    ITEM_FEE_CONDITIONS.TIME,
    ITEM_FEE_CONDITIONS.STORAGE_COUNT,
    ITEM_FEE_CONDITIONS.MARGIN,
    ITEM_FEE_CONDITIONS.PROMO,
  ]),
  min: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
  maxBidderFeePct: types.number,
  schedule: types.maybeNull(FeeConditionsScheduleModel),
});

export type TFeeConditionsModel = Instance<typeof FeeConditionsModel>;
export type TFeeConditionsSchedule = Instance<typeof FeeConditionsScheduleModel>;
