import {flow, types} from 'mobx-state-tree';
import api from '../../shared/configs/axiosConfig';
import {AxiosResponse} from 'axios';

import {handlingConsoleRequestError} from '@shared/utils/handlingConsoleRequestError';
import {EXTERNAL_LINK_TYPE} from '@src/shared/enums/external-links';
import {TExternalLink, TExternalLinkLog, TMpAccountDetailsResponse, TMpAccountItem} from './types';

import {CURRENT_MP_ACCOUNT} from '../../shared/const/localStorage';

export const RegionItem = types.model('RegionItem', {
  id: types.number,
  regionId: types.number,
  name: types.string,
});

export const MpTariff = types.model('MpTariff', {
  tariffId: types.number,
  periodFrom: types.string,
  periodTo: types.string,
  isLimited: types.boolean,
  isTrial: types.boolean,
});

export const MpAccount = types.model('MpAccount', {
  id: types.identifierNumber,
  name: types.string,
  uuidSk: types.string,
  isUpdating: types.boolean,
  lastDataUpdatedAt: types.string,
  updateDelay: types.number,
  isTesting: types.optional(types.boolean, false),
});

export const MpAccountCurrentModel = types.model('MpAccountCurrentModel', {
  id: types.identifierNumber,
  name: types.string,
  activeSubscription: types.boolean,
  hadPaidTariff: types.maybeNull(types.boolean),
  lastDataUpdatedAt: types.string,
  updateDelay: types.number,
  isUpdating: types.boolean,
  nextPaymentDate: types.maybeNull(types.string),
  tariff: types.maybeNull(MpTariff),
  uuidSk: types.string,
  businessId: types.number,
  analyticsDashboardUrl: types.maybeNull(types.string),
  isRulesRevision: types.optional(types.boolean, false),
  isTesting: types.optional(types.boolean, false),
  onlyDefaultPriceAvailable: types.optional(types.boolean, false),
  onlyDefaultPriceEnabled: types.optional(types.boolean, false),
});

export const mpAccountStore = types
  .model('MpAccountStore', {
    items: types.optional(types.array(MpAccount), []),
    current: types.maybeNull(MpAccountCurrentModel),
    count: types.maybe(types.number),
    regionList: types.optional(types.array(RegionItem), []),
  })
  .actions((self) => ({
    getMpAccountList: flow(function* () {
      try {
        const data = yield api.get(`/market_place_account/list`).then((value) => value.data);
        self.items = data.items.map((item: TMpAccountItem) => ({
          id: item.id,
          name: item.name,
          uuidSk: item.uuidSk,
          isUpdating: item.isUpdating,
          lastDataUpdatedAt: item.lastDataUpdatedAt,
          updateDelay: item.updateDelay,
          isTesting: item.isTesting,
        }));
        self.count = data.count;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getMpAccountDetails: flow(function* (marketPlaceAccountId: number) {
      try {
        return yield api.get(`/market_place_account/${marketPlaceAccountId}`).then((value) => value.data);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    reloadMpAccount: flow(function* (marketPlaceAccountId: number) {
      try {
        yield api
          .post(`/market_place_account/${marketPlaceAccountId}/reload`)
          .then((value) => value)
          .catch((e) => handlingConsoleRequestError(e));
        const findMpAcc = self.items.find((item) => item.id === marketPlaceAccountId);
        if (findMpAcc) {
          findMpAcc.isUpdating = true;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateReloadStatusMpAccount: function (
      marketPlaceAccountId: number,
      reloadData: {is_updating: boolean; update_at: string}
    ) {
      const findIndex = self.items.findIndex((item) => item.id === marketPlaceAccountId);
      if (findIndex !== -1) {
        self.items[findIndex].isUpdating = reloadData.is_updating;
        self.items[findIndex].lastDataUpdatedAt = reloadData.update_at;
      }
    },
    getRegionList: flow(function* (marketPlaceAccountId: number) {
      try {
        const data = yield api.get(`/market_place_account/${marketPlaceAccountId}/region/list`);
        self.regionList = data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getExternalLink: flow(function* (marketPlaceAccountId: number, type: EXTERNAL_LINK_TYPE) {
      try {
        const data: AxiosResponse<TExternalLink> = yield api.get(
          `/market_place_account/${marketPlaceAccountId}/external_link`,
          {params: {type}}
        );
        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getExternalLinkLogs: flow(function* (marketPlaceAccountId: number, externalLinkId: number) {
      try {
        const data: AxiosResponse<TExternalLinkLog[]> = yield api.get(
          `/market_place_account/${marketPlaceAccountId}/external_link/${externalLinkId}/log`,
          {params: {limit: 100}}
        );
        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateExternalLink: flow(function* (marketPlaceAccountId: number, externalLinkData: TExternalLink) {
      try {
        yield api.patch(`/market_place_account/${marketPlaceAccountId}/external_link`, {
          url: externalLinkData.url,
          type: externalLinkData.type,
          autoParse: externalLinkData.autoParse,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateRuleRevisionState: function (marketPlaceAccountId: number, state: boolean) {
      if (marketPlaceAccountId === self.current?.id) {
        self.current.isRulesRevision = state;
      }
    },
  }))
  .actions((self) => ({
    setActiveAccount: flow(function* (id: number) {
      const account = self.items.find((item) => item.id === id);
      localStorage.setItem(CURRENT_MP_ACCOUNT, String(id));

      if (account) {
        try {
          const mpAccountDetails: TMpAccountDetailsResponse = yield self.getMpAccountDetails(id).then((value) => value);
          self.current = {
            id: account.id,
            name: account.name,
            activeSubscription: mpAccountDetails.activeSubscription,
            hadPaidTariff: mpAccountDetails.hadPaidTariff,
            lastDataUpdatedAt: account.lastDataUpdatedAt,
            updateDelay: account.updateDelay,
            isUpdating: account.isUpdating,
            nextPaymentDate: mpAccountDetails.nextPaymentDate,
            tariff: mpAccountDetails.tariff,
            uuidSk: account.uuidSk,
            businessId: mpAccountDetails.businessId,
            isRulesRevision: mpAccountDetails.isRulesRevision,
            isTesting: account.isTesting,
            onlyDefaultPriceAvailable: mpAccountDetails.onlyDefaultPriceAvailable,
            onlyDefaultPriceEnabled: mpAccountDetails.onlyDefaultPriceEnabled,
            analyticsDashboardUrl: mpAccountDetails.analyticsDashboardUrl,
          };
        } catch (e) {
          handlingConsoleRequestError(e);
        }
      }
    }),
    updateOnlyDefaultPrice: flow(function* (marketPlaceAccountId: number, state: boolean) {
      try {
        yield api.patch(`/market_place_account/${marketPlaceAccountId}/only_default_price`, {enabled: state});
        if (self.current) {
          self.current.onlyDefaultPriceEnabled = state;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    checkOnlyDefaultPrice: flow(function* (marketPlaceAccountId: number) {
      try {
        const data = yield api.post(`/market_place_account/${marketPlaceAccountId}/only_default_price/check`);
        if (self.current) {
          self.current.onlyDefaultPriceAvailable = data.data.onlyDefaultPriceAvailable;
          self.current.onlyDefaultPriceEnabled = data.data.onlyDefaultPriceEnabled;
        }
        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    afterCreate: function () {
      self.getMpAccountList().catch(() => {});
    },
  }));
