export const NoResultsIcon = ({...others}) => {
  return (
    <svg width="600" height="436" viewBox="0 0 600 436" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M298.565 401.538C403.208 401.538 488.038 316.065 488.038 210.629C488.038 105.193 403.208 19.7196 298.565 19.7196C193.921 19.7196 109.091 105.193 109.091 210.629C109.091 316.065 193.921 401.538 298.565 401.538Z"
        fill="#EBD2FA"
      />
      <path d="M449.285 336.011L386.127 303.059V142.57L449.285 172.776V336.011Z" fill="white" />
      <path
        d="M387.562 144.848V302.189L447.849 333.643V173.681L387.562 144.848ZM384.691 140.293L450.72 171.872V338.379L384.691 303.929V140.293Z"
        fill="#707070"
      />
      <path d="M387.563 140.294H140.673V303.93H387.563V140.294Z" fill="white" />
      <path d="M386.127 141.729H142.108V302.495H386.127V141.729Z" stroke="#707070" />
      <path d="M450.721 171.873H203.831V338.38H450.721V171.873Z" fill="white" />
      <path d="M449.285 173.308H205.266V336.945H449.285V173.308Z" stroke="#707070" />
      <path
        d="M450.224 173.309H448.188L387.267 142.848L425.08 110.437L488.277 143.41L450.224 173.309Z"
        fill="#FCF7FF"
      />
      <path
        d="M425.277 112.159L389.842 142.531L448.527 171.873H449.727L485.642 143.654L425.277 112.159ZM424.883 108.715L490.912 143.165L450.72 174.744H447.849L384.691 143.165L424.883 108.715Z"
        fill="#707070"
      />
      <path d="M476.595 213.497H233.265L203.792 173.306H447.122L476.595 213.497Z" fill="#FCF7FF" />
      <path
        d="M206.624 174.741L233.993 212.062H473.763L446.394 174.741H206.624ZM200.959 171.871H447.849L479.428 214.933H232.538L200.959 171.871Z"
        fill="#707070"
      />
      <path d="M384.73 141.728H141.363L109.21 101.537H355.257L384.73 141.728Z" fill="#FCF7FF" />
      <path
        d="M381.898 140.293L354.529 102.972H112.196L142.053 140.293H381.898ZM387.563 143.164H140.673L106.223 100.102H355.984L387.563 143.164Z"
        fill="#707070"
      />
      <path d="M205.265 336.011L142.108 303.059V142.57L205.265 172.776V336.011Z" fill="white" />
      <path
        d="M143.543 144.848V302.189L203.83 333.643V173.681L143.543 144.848ZM140.672 140.293L206.701 171.872V338.379L140.672 303.929V140.293Z"
        fill="#707070"
      />
      <path d="M166.313 204.6L103.115 171.627L141.169 141.728H143.204L204.125 172.189L166.313 204.6Z" fill="#FCF7FF" />
      <path
        d="M166.116 202.878L201.55 172.506L142.865 143.164H141.665L105.75 171.383L166.116 202.878ZM166.51 206.321L100.481 171.872L140.672 140.293H143.543L206.701 171.872L166.51 206.321Z"
        fill="#707070"
      />
      <path
        d="M294.261 305.363C297.16 298.608 301.959 292.84 308.075 288.763C314.191 284.685 321.361 282.474 328.711 282.396C343.843 282.396 354.744 292.252 360.29 305.363"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M300.003 260.868C303.967 260.868 307.18 257.655 307.18 253.691C307.18 249.727 303.967 246.514 300.003 246.514C296.039 246.514 292.826 249.727 292.826 253.691C292.826 257.655 296.039 260.868 300.003 260.868Z"
        fill="#717171"
      />
      <path
        d="M354.548 260.868C358.512 260.868 361.725 257.655 361.725 253.691C361.725 249.727 358.512 246.514 354.548 246.514C350.584 246.514 347.371 249.727 347.371 253.691C347.371 257.655 350.584 260.868 354.548 260.868Z"
        fill="#717171"
      />
      <path
        d="M492.347 288.138L495.218 308.234L466.51 273.784C462.082 267.447 458.238 260.72 455.027 253.688L452.156 250.817C450.037 246.34 449.051 241.411 449.285 236.463V230.722C458.232 246.17 468.82 260.607 480.864 273.784C485.031 278.287 488.868 283.084 492.347 288.138Z"
        fill="white"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M499.527 319.717C509.833 319.717 518.188 311.362 518.188 301.056C518.188 290.751 509.833 282.396 499.527 282.396C489.221 282.396 480.867 290.751 480.867 301.056C480.867 311.362 489.221 319.717 499.527 319.717Z"
        fill="#FCF7FF"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M483.738 319.717C482.905 305.839 486.993 294.741 503.833 291.009C523.2 286.703 531.735 294.686 535.412 291.009C539.09 287.331 547.122 276.881 552.637 282.396C553.989 283.809 555.003 285.51 555.601 287.372C556.199 289.233 556.366 291.207 556.089 293.142C555.813 295.078 555.1 296.926 554.005 298.545C552.909 300.165 551.46 301.515 549.766 302.492C539.856 307.51 536.331 308.575 535.412 316.846C534.494 325.117 532.542 347.618 532.542 351.296C532.542 354.973 530.477 363.813 526.8 365.65C527.262 366.385 527.555 367.213 527.658 368.075C527.762 368.937 527.673 369.811 527.399 370.634C527.124 371.458 526.671 372.21 526.071 372.837C525.471 373.465 524.739 373.951 523.929 374.262C521.161 375.347 518.085 375.347 515.317 374.262C515.317 374.262 510.267 375.761 503.833 365.65C497.4 355.539 484.286 328.892 483.738 319.717Z"
        fill="white"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M521.055 336.944C521.055 336.944 522.072 343.421 523.926 351.299C525.04 356.041 526.389 362.96 526.797 365.653"
        fill="white"
      />
      <path
        d="M521.055 336.944C521.055 336.944 522.072 343.421 523.926 351.299C525.04 356.041 526.389 362.96 526.797 365.653"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M515.314 374.268C516.639 373.33 517.765 372.138 518.627 370.763C519.489 369.387 520.07 367.855 520.337 366.253C520.604 364.652 520.551 363.014 520.182 361.433C519.812 359.852 519.134 358.36 518.185 357.043C514.872 351.868 511.023 347.056 506.701 342.689"
        fill="white"
      />
      <path
        d="M515.314 374.268C516.639 373.33 517.765 372.138 518.627 370.763C519.489 369.387 520.07 367.855 520.337 366.253C520.604 364.652 520.551 363.014 520.182 361.433C519.812 359.852 519.134 358.36 518.185 357.043C514.872 351.868 511.023 347.056 506.701 342.689"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M509.575 299.621C509.575 299.621 517.613 305.021 518.188 311.104C518.925 318.907 512.446 325.458 512.446 325.458"
        fill="white"
      />
      <path
        d="M509.575 299.621C509.575 299.621 517.613 305.021 518.188 311.104C518.925 318.907 512.446 325.458 512.446 325.458"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M139.234 285.267L136.364 305.363C146.97 293.755 156.573 281.271 165.072 268.042C176.19 249.729 188.742 224.489 185.167 227.851C181.593 231.212 162.327 255.212 150.718 268.042C146.137 273.244 142.275 279.038 139.234 285.267Z"
        fill="white"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M132.06 316.846C142.366 316.846 150.721 307.849 150.721 296.75C150.721 285.652 142.366 276.655 132.06 276.655C121.755 276.655 113.4 285.652 113.4 296.75C113.4 307.849 121.755 316.846 132.06 316.846Z"
        fill="#FCF7FF"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M147.853 316.846C148.685 302.968 144.597 288.999 127.757 285.267C108.39 280.961 99.8555 288.944 96.178 285.267C92.5005 281.589 84.4679 274.01 78.9531 279.525C77.6009 280.938 76.5876 282.639 75.9895 284.501C75.3914 286.362 75.2244 288.336 75.5009 290.271C75.7774 292.207 76.4903 294.055 77.5857 295.674C78.6811 297.294 80.1303 298.644 81.8239 299.621C91.7311 304.639 95.2593 302.833 96.178 311.104C97.0966 319.375 99.0488 341.876 99.0488 345.554C99.0488 349.232 98.2421 358.071 101.92 359.908C101.92 359.908 101.434 368.52 107.661 371.391C111.769 373.292 116.274 368.52 116.274 368.52C116.274 368.52 121.323 370.019 127.757 359.908C134.19 349.797 147.299 326.021 147.853 316.846Z"
        fill="white"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M110.529 331.203C109.873 336.991 108.914 342.74 107.658 348.428C106.544 353.17 102.324 357.218 101.917 359.911"
        fill="white"
      />
      <path
        d="M110.529 331.203C109.873 336.991 108.914 342.74 107.658 348.428C106.544 353.17 102.324 357.218 101.917 359.911"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M116.271 368.521C116.271 368.521 106.682 364.697 113.4 354.167C116.712 348.991 120.561 344.18 124.883 339.812"
        fill="white"
      />
      <path
        d="M116.271 368.521C116.271 368.521 106.682 364.697 113.4 354.167C116.712 348.991 120.561 344.18 124.883 339.812"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M122.004 296.75C122.004 296.75 113.966 299.279 113.391 305.363C113.23 311.236 114.205 317.084 116.262 322.587"
        fill="white"
      />
      <path
        d="M122.004 296.75C122.004 296.75 113.966 299.279 113.391 305.363C113.23 311.236 114.205 317.084 116.262 322.587"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        d="M386.131 101.533L397.61 78.5648"
        stroke="white"
        strokeWidth="1.00012"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M374.645 95.7923L368.901 75.6978"
        stroke="white"
        strokeWidth="1.00012"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M397.617 107.275L417.711 101.531"
        stroke="white"
        strokeWidth="1.00012"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.1"
        d="M8.61244 183.356C13.369 183.356 17.2249 179.5 17.2249 174.744C17.2249 169.987 13.369 166.131 8.61244 166.131C3.85592 166.131 0 169.987 0 174.744C0 179.5 3.85592 183.356 8.61244 183.356Z"
        fill="#717171"
      />
      <path
        opacity="0.1"
        d="M508.134 74.265C512.891 74.265 516.746 70.4091 516.746 65.6526C516.746 60.8961 512.891 57.0402 508.134 57.0402C503.378 57.0402 499.522 60.8961 499.522 65.6526C499.522 70.4091 503.378 74.265 508.134 74.265Z"
        fill="#717171"
      />
      <path
        opacity="0.1"
        d="M577.034 230.724C588.925 230.724 598.565 221.727 598.565 210.629C598.565 199.53 588.925 190.533 577.034 190.533C565.142 190.533 555.502 199.53 555.502 210.629C555.502 221.727 565.142 230.724 577.034 230.724Z"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.2"
        d="M159.33 402.973C167.258 402.973 173.684 396.547 173.684 388.619C173.684 380.692 167.258 374.265 159.33 374.265C151.403 374.265 144.976 380.692 144.976 388.619C144.976 396.547 151.403 402.973 159.33 402.973Z"
        stroke="#717171"
        stroke-miterlimit="10"
      />
      <path opacity="0.4" d="M119.139 1.05933L84.689 32.6383" stroke="#717171" stroke-miterlimit="10" />
      <path opacity="0.4" d="M119.139 32.6383L84.689 1.05933" stroke="#717171" stroke-miterlimit="10" />
      <path opacity="0.4" d="M406.223 411.583L383.256 434.549" stroke="#717171" stroke-miterlimit="10" />
      <path opacity="0.4" d="M406.223 434.549L383.256 411.583" stroke="#717171" stroke-miterlimit="10" />
    </svg>
  );
};
