import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
    width: 245,
  },
  actionsBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  faqBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    width: '100%',
  },
}));
