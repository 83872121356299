import {Skeleton} from '@src/components/common/skeleton';

export const LinkSkeleton = () => {
  return (
    <Skeleton>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <Skeleton.Element width="100%" height={16} />
        <Skeleton.Element width="100%" height={16} />
      </div>
    </Skeleton>
  );
};
