import {PRODUCT_ANALYSIS_BASE_URL} from '../consts';

interface IGetAnalysisLink {
  businessId: number;
  shopId: number;
  searchQuery: string;
}

export const getAnalysisLink = ({businessId, shopId, searchQuery}: IGetAnalysisLink): string => {
  const link = PRODUCT_ANALYSIS_BASE_URL;
  const search = encodeURIComponent(searchQuery);

  return `${link}business/${businessId}/explainer?campaignId=${shopId}&text=${search}`;
};
