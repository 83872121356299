export const sortFields = [
  'id',
  '-id',
  'price',
  '-price',
  'currentFeePct',
  '-currentFeePct',
  'maxBidderFeePct',
  '-maxBidderFeePct',
] as const;

export const SORT_VARIANTS = {
  ID_DESC: '-id',
  ID_ASC: 'id',
  PRICE_DESC: '-price',
  PRICE_ASC: 'price',
  CURRENT_FEE_PCT_DESC: '-currentFeePct',
  CURRENT_FEE_PCT_ASC: 'currentFeePct',
  MAX_BIDDER_FEE_PCT_DESC: '-maxBidderFeePct',
  MAX_BIDDER_FEE_PCT_ASC: 'maxBidderFeePct',
} as const;
