export const CircularClockArrowIcon = ({size = '22px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <g clipPath="url(#clip0_6105_11833)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8837 16.4051C19.0793 14.8819 19.7359 13.0052 19.7506 11.0689C19.7653 9.13264 19.1373 7.24618 17.965 5.70505C17.1001 4.56724 15.9697 3.6586 14.6726 3.05855C13.3755 2.45851 11.9511 2.18533 10.524 2.2629C9.09695 2.34047 7.71058 2.76642 6.48614 3.50353C5.26169 4.24064 4.23644 5.26645 3.5 6.4913V3.50005H2.25V7.87505L2.875 8.50005H7.25V7.25005H4.51375C5.34879 5.80644 6.6406 4.68185 8.18547 4.05361C9.73034 3.42537 11.4404 3.32922 13.0459 3.78033C14.6515 4.23144 16.0612 5.20413 17.0528 6.54504C18.0444 7.88594 18.5615 9.51877 18.5225 11.186C18.4835 12.8533 17.8906 14.4602 16.8374 15.7532C15.7841 17.0463 14.3305 17.952 12.7056 18.3275C11.0807 18.703 9.377 18.527 7.86321 17.8272C6.34941 17.1274 5.11162 15.9436 4.345 14.4626L3.23625 15.0401C3.91141 16.3387 4.90155 17.4472 6.11602 18.2642C7.33049 19.0811 8.73049 19.5803 10.1878 19.7161C11.6452 19.8519 13.1133 19.62 14.4578 19.0415C15.8023 18.4631 16.9803 17.5566 17.8837 16.4051ZM13.6825 15.1926L14.5675 14.3088L11 10.74V6.00005H9.75V11L9.9325 11.4425L13.6825 15.1926Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6105_11833">
          <rect width="20" height="20" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
