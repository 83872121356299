import {useEffect, useMemo, useRef} from 'react';
import {observer} from 'mobx-react-lite';

//@ts-ignore
import Chatra from '@chatra/chatra';
import {useTariff} from '@src/hooks/useTariff';

import {useStore} from '../../../stores';

export const ChatraInitialize = observer(() => {
  const {user} = useStore();
  const {mpItems, mpAccounts} = useStore();
  const {tariff} = useTariff(mpAccounts.current?.tariff?.tariffId);
  const isFirstInitChatra = useRef(false);
  const prevChatraConfig = useRef({});

  const chatraConfig = useMemo(() => {
    return {
      ID: import.meta.env.WEB_CHATRA_ID,
      integration: {
        name: user?.data?.firstName.length > 0 ? user?.data?.firstName + ' ' + user?.data?.lastName : user?.data.email,
        email: user?.data.email,
        notes: `Текущий кабинет: ${mpAccounts.current?.name} / id: ${mpAccounts.current?.id} - ${tariff?.name.toUpperCase()}\nВсего кабинетов: ${mpAccounts?.items.length}\nВсего магазинов: ${mpItems?.filters.shops.length}\n\nCписок кабинетов: ${mpAccounts?.items
          .map((item) => ` \n${item.name} / id: ${item.id}`)
          .slice(0, 5)}\n\nСписок магазинов: ${mpItems?.filters.shops
          .map((item) => `\n${item.name} / id: ${item.marketShopId}`)
          .slice(0, 5)}`,
      },
    };
  }, [mpAccounts?.items.length, mpItems?.filters.shops.length, tariff?.id, mpAccounts?.current?.id]);

  useEffect(() => {
    if (
      isFirstInitChatra.current === false &&
      JSON.stringify(chatraConfig) !== JSON.stringify(prevChatraConfig.current)
    ) {
      Chatra('init', chatraConfig);
      prevChatraConfig.current = chatraConfig;
      isFirstInitChatra.current = true;
    } else {
      if (
        chatraConfig.integration.name &&
        chatraConfig.integration.email &&
        mpAccounts.current?.name &&
        tariff?.name &&
        JSON.stringify(chatraConfig) !== JSON.stringify(prevChatraConfig.current)
      ) {
        Chatra('updateIntegrationData', {
          email: chatraConfig.integration.email,
          name: chatraConfig.integration.name,
          notes: chatraConfig.integration.notes,
        });
        isFirstInitChatra.current = true;
      }
    }
  }, [JSON.stringify(chatraConfig)]);

  return null;
});
