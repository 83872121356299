import {useNavigate} from 'react-router-dom';

import {COLORS} from '@shared/const/appPalette';
import {ChevronIcon} from '@src/components/common/icons';
import {getRoutePricing} from '@src/shared/const/router';

import {Button, Typography} from '../..';

import {useEmotionWrapper} from './limited-account.s';

export const LimitedAccount = () => {
  const {classes} = useEmotionWrapper();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Typography weight="bold" color={COLORS.DARK_100} size={16}>
        Для продолжения работы, пожалуйста, оплатите тарифный план.
      </Typography>
      <div>
        <Button onClick={() => navigate(getRoutePricing())}>
          <div className={classes.button}>
            Выбрать тариф
            <ChevronIcon />
          </div>
        </Button>
      </div>
    </div>
  );
};
