export const WhatsAppIcon = ({size = '24px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <g clip-path="url(#clip0_11573_5705)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9971 2C6.48428 2 2 6.48553 2 11.9999C2 14.1868 2.70527 16.215 3.90417 17.8612L2.65816 21.5766L6.50185 20.3481C8.08275 21.3946 9.96934 22 12.0029 22C17.5157 22 22 17.5143 22 12.0001C22 6.48571 17.5157 2.00017 12.0029 2.00017L11.9971 2ZM9.20535 7.07951C9.01145 6.61511 8.86449 6.59753 8.57074 6.58558C8.47072 6.57978 8.35925 6.57397 8.23568 6.57397C7.85352 6.57397 7.45394 6.68563 7.21294 6.93252C6.91918 7.23233 6.19034 7.93182 6.19034 9.36633C6.19034 10.8008 7.23649 12.1882 7.37748 12.3823C7.52444 12.5761 9.41699 15.5626 12.3555 16.7798C14.6535 17.7321 15.3354 17.6439 15.8584 17.5322C16.6224 17.3676 17.5804 16.803 17.8214 16.1213C18.0624 15.4392 18.0624 14.8572 17.9918 14.7337C17.9213 14.6103 17.7272 14.5399 17.4335 14.3928C17.1397 14.2458 15.7114 13.5403 15.441 13.4462C15.1765 13.3463 14.9239 13.3817 14.7242 13.6639C14.442 14.0578 14.1658 14.4576 13.9424 14.6985C13.7661 14.8867 13.478 14.9102 13.2371 14.8102C12.9139 14.6751 12.0089 14.3574 10.8921 13.3639C10.0281 12.5939 9.4404 11.6357 9.27005 11.3477C9.09954 11.0539 9.25245 10.8831 9.38747 10.7245C9.53443 10.5422 9.67543 10.4129 9.82239 10.2424C9.96935 10.072 10.0516 9.9837 10.1457 9.78378C10.2457 9.58982 10.175 9.38989 10.1045 9.24289C10.034 9.09589 9.44636 7.66138 9.20535 7.07951Z"
          fill="#67C15E"
        />
      </g>
      <defs>
        <clipPath id="clip0_11573_5705">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
