import {SELECTED_FILTERS_ON_LOCAL_STORAGE} from '@shared/const/localStorage';
import {useStore} from '@src/stores';
import {TFilters} from '@src/stores/market-place-account-item/types';

import {TmpAccountOnLocalStorage} from '../products';

export const getIndexOnLocalStorage = (
  selectedFiltersOnLocalStorage: TmpAccountOnLocalStorage[],
  mpAccountId?: number
) => {
  if (!mpAccountId) {
    return -1;
  }
  return selectedFiltersOnLocalStorage.findIndex((item) => item.mpAccountId === mpAccountId);
};

export const useItemFiltersFromLocalStorage = (marketPlaceAccountId: number | undefined) => {
  const {mpItems} = useStore();

  const setFiltersOnLocalStorage = (selectedFiltersOnLocalStorage: TmpAccountOnLocalStorage[]) => {
    localStorage.setItem(SELECTED_FILTERS_ON_LOCAL_STORAGE, JSON.stringify(selectedFiltersOnLocalStorage));
  };

  const getSelectedFilters = () => {
    return [...JSON.parse(localStorage.getItem(SELECTED_FILTERS_ON_LOCAL_STORAGE) || '[]')];
  };
  const getSelectedFiltersByMarketPlaceAccountId = () => {
    const selectedFiltersOnLocalStorage = getSelectedFilters();
    const findIndex = getIndexOnLocalStorage(selectedFiltersOnLocalStorage, marketPlaceAccountId);
    if (findIndex !== -1) {
      return selectedFiltersOnLocalStorage[findIndex];
    } else {
      return null;
    }
  };

  const getMpAccountIndex = () => {
    if (!marketPlaceAccountId) {
      return -1;
    }
    const selectedFiltersOnLocalStorage = getSelectedFilters();
    return selectedFiltersOnLocalStorage.findIndex((item) => item.mpAccountId === marketPlaceAccountId);
  };

  const resetSelectedFilters = () => {
    if (!marketPlaceAccountId) {
      return;
    }
    const selectedFiltersOnLocalStorage = getSelectedFilters();
    const mpAccIndexFromLocalStorage = getSelectedFiltersByMarketPlaceAccountId();
    if (mpAccIndexFromLocalStorage !== -1) {
      selectedFiltersOnLocalStorage.splice(mpAccIndexFromLocalStorage, 1);
      setFiltersOnLocalStorage(selectedFiltersOnLocalStorage);
    }
  };

  const saveFiltersOnLocalStorage = (filterData: TAddSelectedFilters) => {
    const {filterType, filterValue} = filterData;
    if (marketPlaceAccountId === undefined && marketPlaceAccountId === null) {
      return;
    } else {
      const getSelectedFilters = () => {
        return [...JSON.parse(localStorage.getItem(SELECTED_FILTERS_ON_LOCAL_STORAGE) || '[]')];
      };
      const filtersFromLocalStorage = getSelectedFilters();
      const indexMpAccount = getIndexOnLocalStorage(filtersFromLocalStorage, marketPlaceAccountId);

      if (indexMpAccount !== -1) {
        filtersFromLocalStorage[indexMpAccount] = {
          ...filtersFromLocalStorage[indexMpAccount],
          [filterType]: filterValue,
        };
      } else {
        filtersFromLocalStorage.push({
          mpAccountId: marketPlaceAccountId,
          vendors: mpItems.filters.selected.vendors,
          shops: mpItems.filters.selected.shops,
          categories: mpItems.filters.selected.categories,
          groups: mpItems.filters.selected.groups,
          search: mpItems.filters.selected.search,
        });
      }
      setFiltersOnLocalStorage(filtersFromLocalStorage);
    }
  };

  return {
    getSelectedFilters,
    getMpAccountIndex,
    resetSelectedFilters,
    saveFiltersOnLocalStorage,
    getSelectedFiltersByMarketPlaceAccountId,
    setFiltersOnLocalStorage,
  };
};

type TAddSelectedFilters = {
  filterType: TFilters;
  filterValue?: {id: number; name: string}[] | {id: number | undefined; name: string | undefined} | string;
};
