import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: COLORS.BLACK_20,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundImage: `radial-gradient(circle at center,  ${COLORS.BLACK_20}, ${COLORS.BLACK_10})`,
      transform: 'scale(1.2)',
      boxShadow: `0 0 8px ${COLORS.DARK_20}`,
    },
  },
}));
