import {Instance, types} from 'mobx-state-tree';

import {BIDDER_STRATEGY} from '@src/shared/enums/bidder-strategy';

export const BidderStrategyModel = types.enumeration('BidderStrategy', [
  BIDDER_STRATEGY.AI,
  BIDDER_STRATEGY.MANUAL,
  BIDDER_STRATEGY.OFF,
  BIDDER_STRATEGY.RECOMMENDED,
]);

export type TBidderStrategyModel = Instance<typeof BidderStrategyModel>;
