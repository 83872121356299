import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {Button} from '@src/components/common/buttons/button';
import {Typography} from '@src/components/common/typography';
import {IS_SENDED_USER_CONTACTS} from '@src/shared/const/localStorage';
import {yandexMetrika} from '@src/shared/utils/yandex-metrika';
import {useStore} from '@src/stores';

import {Notifications} from '../notifications';

export const TrialOfferNotification = observer(() => {
  const {utils, mpAccounts} = useStore();

  const isTrialTariff = mpAccounts.current?.tariff?.isTrial;
  const isHasPaidTariff = mpAccounts.current?.hadPaidTariff;
  const isSendedUserContacts = localStorage.getItem(IS_SENDED_USER_CONTACTS) === 'true';

  useEffect(() => {
    if (!isTrialTariff && !isHasPaidTariff && !isSendedUserContacts && mpAccounts.current?.id) {
      setTimeout(() => {
        utils.openOfferTrialModal();
      }, 2000);
    }
  }, [mpAccounts.current?.id]);

  return isTrialTariff || isHasPaidTariff || !mpAccounts.current?.id ? null : (
    <Notifications>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
          <Typography whiteSpace="nowrap" size={16} weight="bold">
            Для включения сервиса оплатите тариф или{' '}
          </Typography>
          <Button
            variant="text"
            onClick={() => {
              yandexMetrika().targetFulfilled.tryFree();
              utils.openOfferTrialModal();
            }}
          >
            <Typography size={16} weight="bold" style={{textDecoration: 'underline'}}>
              продолжайте пользоваться бесплатно
            </Typography>
          </Button>
        </div>

        <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
          <Button
            onClick={() => {
              yandexMetrika().targetFulfilled.tryFree();
              utils.openOfferTrialModal();
            }}
          >
            Продолжить пользоваться бесплатно
          </Button>
        </div>
      </div>
    </Notifications>
  );
});
