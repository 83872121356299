export const NoImageIcon = ({size = '40px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <g clipPath="url(#clip0_9640_5807)">
        <path
          d="M37.5 4.26762L35.7324 2.5L2.5 35.7324L4.26762 37.5L6.76763 35H32.5C33.1628 34.9991 33.7981 34.7354 34.2668 34.2668C34.7354 33.7981 34.9991 33.1628 35 32.5V6.76763L37.5 4.26762ZM32.5 32.5H9.26763L19.0087 22.7588L21.9823 25.7321C22.4511 26.201 23.087 26.4643 23.75 26.4643C24.413 26.4643 25.0489 26.201 25.5177 25.7321L27.5 23.75L32.5 28.7466V32.5ZM32.5 25.2102L29.2677 21.9779C28.7989 21.509 28.163 21.2457 27.5 21.2457C26.837 21.2457 26.2011 21.509 25.7323 21.9779L23.75 23.9602L20.7787 20.9889L32.5 9.26763V25.2102Z"
          fill={color}
        />
        <path
          d="M7.5 27.5V23.75L13.75 17.5042L15.4666 19.2209L17.2365 17.4509L15.5177 15.7321C15.0489 15.2633 14.413 14.9999 13.75 14.9999C13.087 14.9999 12.4511 15.2633 11.9823 15.7321L7.5 20.2145V7.5H27.5V5H7.5C6.83716 5.00066 6.20166 5.26427 5.73296 5.73296C5.26427 6.20166 5.00066 6.83716 5 7.5V27.5H7.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9640_5807">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
