import {emotionWrapper, keyframes} from '@shared/lib';

export interface IStyledLoader {
  size?: string;
}

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export const useEmotionWrapper = emotionWrapper(({size}: IStyledLoader) => ({
  root: {
    display: 'inline-block',
    width: size || '14px',
    height: size || '14px',
    border: '2px solid #f8f8f8',
    borderTop: '2px solid #393939',
    borderRadius: '50%',
    animation: `${spin} 1.5s linear infinite`,
  },
}));
