export enum BIDDER_STRATEGY {
  AI = 'AI',
  MANUAL = 'MANUAL',
  RECOMMENDED = 'RECOMMENDED',
  OFF = 'OFF',
}

export type StrategyOption = {
  value: BIDDER_STRATEGY;
  label: string;
};

export const BIDDER_STRATEGY_LABELS: Record<BIDDER_STRATEGY, string> = {
  [BIDDER_STRATEGY.AI]: 'Искусственный интеллект',
  [BIDDER_STRATEGY.MANUAL]: 'Ручное управление',
  [BIDDER_STRATEGY.RECOMMENDED]: 'Рекомендации Я.Маркета',
  [BIDDER_STRATEGY.OFF]: 'Выключено',
} as const;

export const strategyOptions: StrategyOption[] = [
  {value: BIDDER_STRATEGY.AI, label: BIDDER_STRATEGY_LABELS[BIDDER_STRATEGY.AI]},
  {value: BIDDER_STRATEGY.RECOMMENDED, label: BIDDER_STRATEGY_LABELS[BIDDER_STRATEGY.RECOMMENDED]},
  {value: BIDDER_STRATEGY.MANUAL, label: BIDDER_STRATEGY_LABELS[BIDDER_STRATEGY.MANUAL]},
  {value: BIDDER_STRATEGY.OFF, label: BIDDER_STRATEGY_LABELS[BIDDER_STRATEGY.OFF]},
];

export enum RECOMMENDATION_STRATEGY {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export type TRecommendationStrategyValues = 'HIGH' | 'MEDIUM' | 'LOW';

export const SHOW_RECOMMENDATION_PERCENT_OPTIONS = [
  {
    label: '85-100%',
    value: RECOMMENDATION_STRATEGY.HIGH,
  },
  {
    label: '60-85%',
    value: RECOMMENDATION_STRATEGY.MEDIUM,
  },
  {
    label: '30-60%',
    value: RECOMMENDATION_STRATEGY.LOW,
  },
];
