import React, {FC, forwardRef, InputHTMLAttributes, useEffect, useRef, useState} from 'react';

import {Button} from '@components/index';
import {COLORS} from '@shared/const/appPalette';
import {CloseIcon} from '@src/components/common/icons';

import {useEmotionWrapper} from './input.s';

type IInput = InputHTMLAttributes<HTMLInputElement> & {
  ref?: React.Ref<HTMLInputElement>;
  width?: string | number;
  isClearButton?: boolean;
  value: string | number | undefined;
  onChange: (value: {target: {value: string}}) => void;
  isBordered?: boolean;
  icon?: React.ReactNode;
  height?: string | number;
  isError?: boolean;
  disabled?: boolean;
  tabindex?: number;
  pattern?: string;
};

//@ts-ignore
export const Input: FC<IInput> = forwardRef<HTMLInputElement, IInput>(
  (
    {
      width,
      value,
      icon,
      isClearButton = false,
      isError = false,
      isBordered = true,
      onChange,
      disabled = false,
      height = 32,
      tabIndex,
      pattern,
      ...props
    },
    ref
  ) => {
    const {classes} = useEmotionWrapper({width, isBordered, height, isError, disabled});
    const containerRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);
    const handleInputFocus = () => {
      setIsFocused(true);
    };

    const handleInputBlur = () => {
      setIsFocused(false);
    };

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onChange(e);
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <div ref={containerRef} className={`${classes.root} ${isFocused ? classes.focused : ''}`}>
        <input
          tabIndex={tabIndex ? tabIndex : 0}
          disabled={disabled}
          onChange={onChangeValue}
          className={classes.input}
          ref={ref}
          pattern={pattern}
          value={inputValue}
          {...props}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {value && isClearButton && value?.toString().length > 0 ? (
          <Button
            onClick={() => onChange({target: {value: ''}})}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            style={{marginLeft: 'auto', width: 32}}
            size="small"
            variant="icon"
            icon={<CloseIcon color={COLORS.BLACK_40} size="12px" />}
          />
        ) : icon ? (
          <div onClick={handleInputFocus} className={classes.icon}>
            {icon}
          </div>
        ) : null}
      </div>
    );
  }
);
