import {keyframes} from '@emotion/react';

import {COLORS} from '../../../shared/const/appPalette';
import {emotionWrapper} from '../../../shared/lib';

interface IDropdownStyles {
  isOpen: boolean;
}

const slideIn = keyframes`
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }`;

export const useEmotionWrapper = emotionWrapper(({isOpen}: IDropdownStyles) => ({
  root: {
    border: 'none',
    outline: 'none',
    background: 'none',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    gap: 5,
    color: COLORS.PURPLE_100,
  },
  icon: {
    transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
    position: 'absolute',
    right: -10,
    transition: 'all .1s ease-in-out',
  },
  hideContent: {
    display: 'none',
  },
  dropdownContent: {
    display: 'flex',
    zIndex: 10,
    width: 'max-content',
    maxHeight: 450,
    overflow: 'auto',
    borderRadius: 7,
    flexDirection: 'column',
    position: 'absolute',
    animation: `${slideIn} .8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both`,
    top: 25,
    left: 0,
    backgroundColor: 'white',

    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
    div: {
      padding: 10,
      color: COLORS.DARK_100,
      width: '100%',
      fontWeight: 'bold',
      fontSize: 13,
      textAlign: 'left',
      textOverflow: 'collapse',
      whiteSpace: 'nowrap',
      borderRadius: '7px 7px 7px 7px',
      border: 'none',
      outline: 'none',
      background: 'none',
      borderBottom: '1px solid #f3f4f6',
      backgroundColor: 'white',
      '&: hover': {
        cursor: 'pointer',
        backgroundColor: '#f3f4f6',
        transition: 'all .2s ease-in-out',
      },
    },
  },
  search: {
    padding: 0,
    display: 'flex',

    input: {
      border: 'none',
      outline: 'none',
      height: 16,
      backgroundColor: 'transparent',
      paddingRight: 4,
      flexGrow: 1,
    },
  },
}));
