export const FaqIcon = ({size = '18', color = 'currentColor', ...others}) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.526 14.969a1.2 1.2 0 0 1 .272 1.344 1.282 1.282 0 0 1-.682.669 1.318 1.318 0 0 1-.995-.001 1.279 1.279 0 0 1-.676-.668 1.184 1.184 0 0 1-.098-.477 1.2 1.2 0 0 1 .371-.867 1.273 1.273 0 0 1 .897-.361 1.318 1.318 0 0 1 .911.36Zm2.418-6.447a2.9 2.9 0 0 1 .201 1.098c0 .426-.068.792-.202 1.088a2.751 2.751 0 0 1-.503.758c-.2.207-.417.39-.646.54l-.63.414a2.68 2.68 0 0 0-.472.391.61.61 0 0 0-.173.436v.371h-1.872v-.359c0-.383.064-.707.19-.96.126-.25.282-.468.465-.647.184-.178.386-.334.601-.46a4.9 4.9 0 0 0 .576-.397c.17-.137.314-.296.43-.474.112-.172.168-.399.168-.675 0-.147-.02-.29-.06-.424a.83.83 0 0 0-.214-.348 1.141 1.141 0 0 0-.442-.253c-.193-.066-.449-.099-.759-.099-.379 0-.712.044-.988.132a2.787 2.787 0 0 0-.68.308L9.8 9.05v-1.6c.25-.183.526-.285.87-.382.341-.097.77-.146 1.27-.146.527 0 .995.065 1.391.192.4.13.737.314 1.005.55.27.24.473.527.607.858ZM12 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12 17.799 22.5 12 22.5Zm0-2a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z"
        fill={color}
      ></path>
    </svg>
  );
};
