import {FC, useEffect, useRef, useState} from 'react';

import {COLORS} from '@shared/const/appPalette';
import {BIDDER_STRATEGY} from '@shared/enums/bidder-strategy';

import {useEmotionWrapper} from './pulse-wrapper.s';

interface IPulseWrapper {
  children: React.ReactNode;
  quantity?: number;
  duration?: number;
  triggerVariable: {
    value?: BIDDER_STRATEGY | null | boolean;
    color?: string;
  };
  destroy?: boolean;
}

export const PulseWrapper: FC<IPulseWrapper> = ({children, destroy, quantity = 3, duration = 1, triggerVariable}) => {
  const {classes} = useEmotionWrapper({color: triggerVariable.color || COLORS.PURPLE_100});

  const [isAnimated, setIsAnimated] = useState(false);

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    setIsAnimated(false); // stop current animation

    // Отменяем предыдущий таймер перед созданием нового
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (triggerVariable.value) {
      timerRef.current = setTimeout(() => {
        setIsAnimated(true);
        const finishTimer = setTimeout(() => setIsAnimated(false), duration * quantity * 1000);
        timerRef.current = finishTimer; // сохраняем ссылку на таймер окончания анимации
      });
    } else {
      setIsAnimated(false);
    }
  }, [triggerVariable]);

  if (!children || destroy) return children;

  return (
    <div className={isAnimated ? classes.pulse : classes.default}>
      <div className={classes.childrenPointerEventsAuto}>{children}</div>
    </div>
  );
};
