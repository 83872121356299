export const RocketIcon = ({size = '25', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M8.3854 15.3333L7.34373 14.2916L4.11456 15.3228H4.0104C3.95964 15.326 3.90878 15.3184 3.86119 15.3004C3.81361 15.2824 3.77038 15.2546 3.73436 15.2187L2.97915 14.4739C2.91814 14.4159 2.8791 14.3385 2.86866 14.255C2.85822 14.1714 2.87701 14.0868 2.92186 14.0156L3.80206 12.4999C4.34726 11.5669 5.10785 10.7779 6.02029 10.1989C6.93272 9.61994 7.97049 9.26776 9.04686 9.17181L10.3437 9.05722C11.0972 8.16139 11.7916 7.39403 12.4271 6.75514C13.6327 5.47808 15.0718 4.44381 16.6666 3.70826C18.2922 3.10065 20.0206 2.81582 21.7552 2.86972C21.8601 2.87066 21.9607 2.91165 22.0364 2.98431C22.0725 3.01743 22.1013 3.05768 22.121 3.1025C22.1408 3.14733 22.151 3.19575 22.151 3.24472C22.1866 4.98448 21.8768 6.71402 21.2396 8.33326C20.523 9.93277 19.5003 11.3766 18.2291 12.5833C17.5885 13.2291 16.8177 13.9218 15.9271 14.6666L15.8125 15.9583C15.719 17.0341 15.3696 18.072 14.7933 18.9853C14.2171 19.8985 13.4308 20.6607 12.5 21.2083L10.9948 22.0885C10.9354 22.1198 10.8692 22.1359 10.8021 22.1353C10.7024 22.1333 10.6066 22.0964 10.5312 22.0312L9.77602 21.2708C9.72726 21.2241 9.69261 21.1647 9.67603 21.0993C9.65945 21.0339 9.66161 20.9652 9.68227 20.901L10.6875 17.6353L9.67706 16.6249M19.4896 7.65618C19.4909 7.09073 19.268 6.54782 18.8696 6.14652C18.4713 5.74523 17.93 5.51831 17.3646 5.51556C17.0749 5.50126 16.7854 5.54596 16.5135 5.64695C16.2417 5.74794 15.9932 5.90311 15.7831 6.10306C15.5731 6.303 15.4059 6.54353 15.2916 6.81007C15.1773 7.0766 15.1184 7.36358 15.1184 7.65358C15.1184 7.94358 15.1773 8.23055 15.2916 8.49709C15.4059 8.76362 15.5731 9.00416 15.7831 9.2041C15.9932 9.40404 16.2417 9.55921 16.5135 9.6602C16.7854 9.7612 17.0749 9.8059 17.3646 9.7916C17.926 9.79242 18.4651 9.57155 18.8646 9.17701C19.0655 8.97874 19.2241 8.74168 19.3306 8.48025C19.4372 8.21881 19.4895 7.93845 19.4844 7.65618H19.4896Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.9479 14.668L10.6875 17.6367" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3437 9.05859L7.36975 14.319" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.77087 18.2292C8.85421 17.8437 12.5521 14.375 12.9844 12.0156C10.6094 12.4687 7.14067 16.1458 6.77087 18.2292Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
