import {COLORS} from '@shared/const/appPalette';
import {Typography} from '@src/components/common/typography';

const getColorPlaceholder = (length: number) => {
  return length > 0 ? COLORS.PURPLE_100 : COLORS.BLACK_40;
};

const getPlaceholder = (length: number, placeholder: string) => {
  switch (length) {
    case 0:
      return placeholder;
    default:
      return `${placeholder} (${length})`;
  }
};

export const getSelectPlaceholder = ({
  lengthFilterArray,
  title,
  fontSize,
  fontWeight,
}: {
  lengthFilterArray: number;
  title: string;
  fontSize?: number;
  fontWeight?: 'normal' | 'bold' | 'lighter' | 'bolder' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  color?: string;
}) => {
  const size = fontSize || 12;
  const weight = fontWeight || 500;
  return (
    <Typography size={size} weight={weight} color={getColorPlaceholder(lengthFilterArray)}>
      {getPlaceholder(lengthFilterArray, title)}
    </Typography>
  );
};
