import {emotionWrapper, keyframes} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';

export interface StyledSkeletonElementProps {
  width?: string | number;
  height?: string | number;
  radius?: string | number;
  background?: string;
  highlightColor?: string;
  duration?: string;
}

const backgroundAnimation = keyframes({
  '100%': {
    transform: 'translateX(100%)',
  },
});

export const useEmotionWrapper = emotionWrapper(
  ({
    width,
    height,
    background = COLORS.BLACK_6,
    highlightColor = 'rgba(0,0,0,0.2)',
    radius = 4,
    duration,
  }: StyledSkeletonElementProps) => ({
    root: {
      position: 'relative',
      display: width ? 'block ' : 'inline-flex',
      width: width || '100%',
      height: height || 'auto',
      overflow: 'hidden',
      backgroundColor: background,
      borderRadius: radius,
      lineHeight: '1',
      flexGrow: 1,
      '&:after': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `linear-gradient(90deg, ${background}, ${highlightColor}, ${background})`,
        transform: 'translateX(-100%)',
        animationName: backgroundAnimation,
        animationDirection: 'normal',
        animationDuration: duration || '1.5s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
      },
    },
  })
);
