import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Loader} from '@src/components';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './summary-analytics.s';

const baseQueryParamsIframe = {
  _embedded: '1',
  _no_controls: '1',
  _theme: 'light',
};

export const SummaryAnalyticsPage = observer(() => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts} = useStore();
  const [iframeUrl, setIframeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (mpAccounts.current?.analyticsDashboardUrl) {
      const url = new URL(mpAccounts.current?.analyticsDashboardUrl);

      Object.entries(baseQueryParamsIframe).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });

      setIframeUrl(url.toString());
    }
  }, [mpAccounts.current?.id]);

  return (
    <div className={classes.root}>
      {isLoading && <Loader size="64px" className={classes.loader} />}
      <iframe className={classes.iframe} width="100%" src={iframeUrl} onLoad={handleLoad} />
    </div>
  );
});
