import {emotionWrapper} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    textAlign: 'justify',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '350px',
    overflow: 'hidden',
  },
  formBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  phoneContactContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  phoneContactTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneContact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    a: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
        color: COLORS.PURPLE_100,
      },
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
}));
