import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    width: '100%',
    height: 80,
    background: 'none',
    cursor: 'pointer',
    borderRadius: 10,
    border: `1px solid ${COLORS.DARK_20}`,
    color: COLORS.BLACK_40,
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: COLORS.GRAY_100,
      border: '1px solid transparent',
      transition: 'all .3s',
    },
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
  },
}));
