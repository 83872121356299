import {type FC} from 'react';

interface ILogoAnimated {
  color: 'green' | 'yellow' | 'purple' | 'gray';
  height?: number | 'string';
  width?: number | 'string';
}

export const LogoAnimated: FC<ILogoAnimated> = ({color, width = 24, height = 16}) => {
  if (color === 'yellow') {
    return <img width={24} height={16} src="/svg/logo-animated/yellow.gif" alt="logo" />;
  }
  if (color === 'purple') {
    return <img width={width} height={height} src="/svg/logo-animated/purple.gif" alt="logo" />;
  }
  if (color === 'green') {
    return <img width={width} height={height} src="/svg/logo-animated/green.gif" alt="logo" />;
  }
  if (color === 'gray') {
    return <img width={width} height={height} src="/svg/logo-animated/gray.png" alt="logo" />;
  }
};
