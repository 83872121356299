import {FC} from 'react';

import {ErrorBoundary} from '@sentry/react';
import {Button} from '@src/components/common/buttons/button';
import {ErrorIcon, RefreshIcon} from '@src/components/common/icons';
import {Typography} from '@src/components/common/typography';
import {COLORS} from '@src/shared/const/appPalette';

interface IErrorBoundaryWrapper {
  children: React.ReactNode;
}

export const ErrorBoundaryWrapper: FC<IErrorBoundaryWrapper> = ({children}) => {
  const errorMessage = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        width: '100%',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <div style={{display: 'flex', alignItems: 'center', gap: 15, justifyContent: 'center'}}>
        <ErrorIcon size="35" />
        <div style={{textAlign: 'center'}}>
          <Typography align="center" size={14} color={COLORS.DARK_70} weight={700}>
            Упс! Что-то пошло не так. <br /> Пожалуйста, перезагрузите страницу
          </Typography>
        </div>
      </div>
      <Button icon={<RefreshIcon />} onClick={() => window.location.reload()} variant="outlined">
        Обновить страницу
      </Button>
    </div>
  );

  return (
    <ErrorBoundary onError={(error) => console.log(error)} fallback={errorMessage}>
      {children}
    </ErrorBoundary>
  );
};
