import {keyframes} from '@emotion/react';
import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';
import {TStatus} from './toggle';

interface IToggleStyle {
  isChecked: boolean;
  size: 'small' | 'large';
  isLoading: boolean;
  disabled: boolean;
  color: string;
  status: TStatus;
}

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const sideIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const getBorderColor = (status: TStatus) => {
  switch (status) {
    case 'error':
      return COLORS.RED_100;
    case 'warning':
      return COLORS.YELLOW_100;
    default:
      return undefined;
  }
};

export const useEmotionWrapper = emotionWrapper(
  ({isChecked, size, isLoading, color, status, disabled}: IToggleStyle) => ({
    root: {
      width: 100,
      display: 'block',
      // maxWidth: size === 'small' ? 49 : 70,
      maxWidth: 'max-content',
      input: {
        display: 'none',
      },
      cursor: isLoading || disabled ? 'not-allowed' : 'pointer',
    },
    toggle: {
      zIndex: 0,

      position: 'relative',
      width: size === 'small' ? 38 : 70,
      height: size === 'small' ? 22 : 40,
      borderRadius: 25,
      backgroundColor: isChecked ? color : COLORS.DARK_20,
      boxShadow: '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1), 0 0 #0000',
      border: getBorderColor(status) ? `1px solid ${getBorderColor(status)}` : '1px solid transparent',
    },
    iconLoading: {
      boxShadow: '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1), 0 0 #0000',
      position: 'absolute',
      bottom: 4,
      right: size === 'small' ? (isChecked ? 5 : 20) : isChecked ? 4 : 34,
      top: 4,
      borderRadius: '100%',
      height: size === 'small' ? 12 : 32,
      width: size === 'small' ? 12 : 32,
      backgroundColor: COLORS.WHITE,
      transition: 'right 0.3s ease-in-out',
    },
    spinner: {
      position: 'absolute',
      width: size === 'small' ? 7 : 21,
      height: size === 'small' ? 7 : 21,
      top: size === 'small' ? 5 : 5.5,
      right: size === 'small' ? 5 : 5.5,
      border: `${size === 'small' ? '2px' : '3px'} solid ${COLORS.BLACK_6}`,
      borderTop: `${size === 'small' ? '2px' : '3px'} solid #393939`,
      borderRadius: '50%',
      animation: `${spin} 1.5s linear infinite`,
    },
    toggleIcon: {
      animation: `${sideIn} 0.1s ease-in-out`,
      color: isChecked ? color : COLORS.DARK_20,
      transition: 'color 0.3s ease-in-out',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
  })
);
