import {type FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {decodeJWT} from '@shared/utils/decodeJWT';
import {getJWTToken} from '@shared/utils/getJWTToken';
import {Typography} from '@src/components/common/typography';
import {ChangelogModal} from '@src/components/specific/modals/changelog-modal/changelog-modal';
import {UserContactsModal} from '@src/components/specific/modals/user-contacts/user-contacts';
import {IS_VIEWED_ONBOARDING_TOUR} from '@src/shared/const/localStorage';
import {getRouteLogin, getRouteProducts} from '@src/shared/const/router';
import {yandexMetrika} from '@src/shared/utils/yandex-metrika';
import dayjs from 'dayjs';

import {useRouterApp} from '../../../hooks';
import {useStore} from '../../../stores';

import {useEmotionWrapper} from './auth-wrapper.s';
interface IAuthWrapper {
  children: React.ReactNode;
}

export const AuthWrapper: FC<IAuthWrapper> = observer(({children}) => {
  const {mpAccounts, mpItems, productGroups, rules, user, tariffs, utils} = useStore();
  const {classes} = useEmotionWrapper();
  const {navigate} = useRouterApp();
  const {setIsOpen} = useTour();
  const isViewedOnboarding = localStorage.getItem(IS_VIEWED_ONBOARDING_TOUR) === 'true';

  const checkErrorCode = (errorCode: string) => {
    if (errorCode === 'ERR_NETWORK') {
      utils.setNetworkError(true);
    }
  };

  const checkExpireJWTToken = () => {
    if (getJWTToken().accessToken) {
      const now = dayjs();
      const expToken = decodeJWT(getJWTToken().accessToken);
      if (expToken) {
        const diffInMinutes = now.diff(expToken, 'minute');
        if (diffInMinutes > -4) {
          if (user.isAuth) {
            user
              .refreshToken()
              .then(() => utils.setNetworkError(false))
              .catch((e) => checkErrorCode(e.code));
          } else {
            user
              .refreshToken()
              .catch((e) => checkErrorCode(e.code))
              .then(() => {
                user
                  .getUserInfo()
                  .then(() => {
                    if (user.isAuth) {
                      mpAccounts
                        .getMpAccountList()
                        .then(() => {
                          utils.setNetworkError(false);
                          navigate(getRouteProducts());
                        })
                        .catch(() => {});
                    }
                  })
                  .catch((e) => checkErrorCode(e.code));
              });
          }
        }
      } else {
        if (getJWTToken().refreshToken) {
          user
            .refreshToken()
            .then(() => utils.setNetworkError(false))
            .catch((e) => checkErrorCode(e.code));
        } else {
          navigate(getRouteLogin());
        }
      }
    }
  };

  useEffect(() => {
    getJWTToken().accessToken && checkExpireJWTToken();
    user
      .initUser()
      .then(() => utils.setNetworkError(false))
      .catch((e) => checkErrorCode(e.code));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => checkExpireJWTToken(), 20000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const initUserData = async () => {
    try {
      if (mpAccounts.current?.id) {
        await mpItems.getShopsByAccountId(mpAccounts.current.id);
        await mpItems.getVendorsByAccountId(mpAccounts.current?.id);
        await mpItems.getCategoriesByAccountId(mpAccounts.current.id);
        await mpItems.getStrategiesByAccountId(mpAccounts.current.id);
        await productGroups.getGroupList(mpAccounts.current.id);
        await rules.getRulesList(mpAccounts.current?.id);
        await tariffs.getTariffsList(mpAccounts.current?.id);
        await mpAccounts.getRegionList(mpAccounts.current?.id);
        !isViewedOnboarding && setIsOpen(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    initUserData();
  }, [mpAccounts.current?.id]);

  useEffect(() => {
    if (user.isNewUser) {
      yandexMetrika().targetFulfilled.newReg();
      mpAccounts.getMpAccountList().then(() => {
        if (mpAccounts.items.length > 0) {
          yandexMetrika().targetFulfilled.newCabinet();
        }
      });
    }
  }, [user.isNewUser]);

  useEffect(() => {
    if (
      mpAccounts.current?.hadPaidTariff === false &&
      user._init &&
      !window.location.pathname?.includes('/yandex-auth')
    ) {
      setTimeout(() => {
        utils.openOfferTrialModal();
      }, 2000);
    }
  }, [user._init, mpAccounts.current?.tariff]);

  return (
    <div>
      <UserContactsModal />
      <ChangelogModal />
      {!user._init ? (
        <div className={classes.root}>
          <img width={120} src="/svg/smart-seller-logo-animated.svg" />
          <Typography size={28}>Smart Seller</Typography>
        </div>
      ) : (
        children
      )}
    </div>
  );
});
