import {type FC} from 'react';

import {COLORS} from '@shared/const/appPalette';

import {useEmotionWrapper} from './notifications.s';

interface INotifications {
  children: React.ReactNode;
  color?: string;
}

export const Notifications: FC<INotifications> = ({children, color = COLORS.PURPLE_20}) => {
  const {classes} = useEmotionWrapper(color);
  return <div className={classes.root}>{children}</div>;
};
