import {emotionWrapper, keyframes} from '@shared/lib';

interface IPulseWrapperStyle {
  color: string;
}

const pulse = keyframes`
  0% {
    border-radius: 50%;
    width: 0px;
    height: 0;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    opacity: 0;
  }
`;
export const useEmotionWrapper = emotionWrapper(({color}: IPulseWrapperStyle) => ({
  default: {},
  pulse: {
    position: 'relative',
    pointerEvents: 'none',
    '&:before': {
      content: "''",
      position: 'absolute',
      border: `2px solid ${color}`,
      opacity: '0',
      left: '50%',
      right: '50%',
      bottom: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 50,
      animation: `${pulse} 1s linear 3`,
    },
  },
  childrenPointerEventsAuto: {
    pointerEvents: 'auto',
  },
}));
