import {SUBSCRIPTION_DURATION} from '@src/shared/enums/subscription-duration';

export const getDiscountDurationByNumber = (duration: number | null) => {
  switch (duration) {
    case 1:
      return SUBSCRIPTION_DURATION.MONTH;
    case 3:
      return SUBSCRIPTION_DURATION.MONTHS_3;
    case 6:
      return SUBSCRIPTION_DURATION.MONTHS_6;
    case 12:
      return SUBSCRIPTION_DURATION.YEAR;
    default:
      return null; //all periods
  }
};
