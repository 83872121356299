import React from 'react';

import {SkeletonElement} from './skeleton-element/skeleton-element';

export interface SharedSkeletonProps {
  children: React.ReactNode;
  count?: number;
}

export const Skeleton = ({children, count}: SharedSkeletonProps) => {
  const renderElements = (count: number) => {
    const elements = [];
    for (let i = 0; i < count; i++) {
      elements.push(children);
    }
    return elements;
  };

  return <React.Fragment>{count && count > 1 ? renderElements(count) : children}</React.Fragment>;
};

Skeleton.Element = SkeletonElement;
