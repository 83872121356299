import {type FC} from 'react';

import {Typography} from '../..';
import {RightArrowIcon} from '../../common/icons';

import {useEmotionWrapper} from './action-button.s';

interface IActionButton {
  onClick?: () => void;
  children?: React.ReactNode;
}

export const ActionButton: FC<IActionButton> = ({onClick, children, ...props}) => {
  const {classes} = useEmotionWrapper();
  return (
    <div className={classes.settingButton} onClick={onClick && onClick} {...props}>
      <Typography whiteSpace="nowrap">{children}</Typography>
      <RightArrowIcon />
    </div>
  );
};
