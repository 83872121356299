import {emotionWrapper} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper((isRepricer: boolean) => ({
  root: {display: 'flex', flexDirection: 'column'},
  tableRoot: {
    width: '100%',
    borderCollapse: 'collapse',
    thead: {
      borderBottom: `1.2px dashed ${COLORS.BLACK_10}`,
    },
    tr: {
      '&: not(:last-child)': {
        borderBottom: `1.2px dashed ${COLORS.BLACK_10}`,
      },
      td: {
        padding: 10,
      },
      th: {
        padding: 10,
      },
    },
  },
  productName: {
    maxWidth: isRepricer ? 400 : 300,
    textAlign: 'justify',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  shopName: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  marginBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 10,
  },
  setShopsPriceButton: {
    color: COLORS.PURPLE_100,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
