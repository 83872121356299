import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    textDecoration: 'none',
    color: COLORS.PURPLE_100,
    cursor: 'pointer',
    '&:hover': {
      color: COLORS.PURPLE_100,
      textDecoration: 'underline',
      transition: 'color .3s',
    },
  },
}));
