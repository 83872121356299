import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Button, Modal, Typography} from '@components/index';
import {EditIcon, TelegramBlackIcon} from '@src/components/common/icons';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';
import dayjs from 'dayjs';

import {useCurrentTariff} from '../../../../hooks/useCurrenTariff';
import {useStore} from '../../../../stores';

import {useEmotionWrapper} from './invite-consultation.s';

export const InviteConsultationModal = observer(() => {
  const {classes} = useEmotionWrapper();
  const {user} = useStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {currentTariff} = useCurrentTariff();
  const lastOpenedModal = JSON.parse(localStorage.getItem('invite-consultation') || '{}').lastOpened;
  const periodTariffDays = () => {
    const periodFrom = dayjs(currentTariff.periodFrom);
    const periodTo = dayjs(currentTariff.periodTo);
    return periodTo.diff(periodFrom, 'day');
  };

  const openModalAndSetLocalStorage = (currentDate: dayjs.Dayjs = dayjs()) => {
    setTimeout(() => {
      setIsOpenModal(true);
      localStorage.setItem(
        'invite-consultation',
        JSON.stringify({
          lastOpened: currentDate,
        })
      );
    }, 20000);
  };

  useEffect(() => {
    const registrationDate = dayjs(user.data.createdAt);
    const currentDate = dayjs();
    const diffDays = currentDate.diff(registrationDate, 'day');
    if (lastOpenedModal) {
      if (dayjs(lastOpenedModal).startOf('day').isSame(dayjs(currentDate).startOf('day'), 'day')) {
        return;
      } else {
        if (diffDays < 4) {
          openModalAndSetLocalStorage(currentDate);
        }
      }
    } else {
      if (diffDays < 4) {
        openModalAndSetLocalStorage(currentDate);
      }
    }
  }, [JSON.stringify(user.data.createdAt)]);

  if (!user.isAuth || !user._init || periodTariffDays() > 3) {
    return null;
  }

  return (
    <Modal
      isCloseOnClickOverlay={false}
      title="Запишитесь на демонстрацию"
      opened={isOpenModal}
      onClose={() => setIsOpenModal(false)}
    >
      <div className={classes.root}>
        <Typography align="justify">
          Добавим ещё 7 дней бесплатного периода после консультации со специалистом
        </Typography>
        <div className={classes.consultationButton}>
          <Button
            onClick={() => window.open(RESOURCE_URLS.SUPPORT_TELEGRAM, '_blank')}
            variant="outlined"
            icon={<TelegramBlackIcon size="16" />}
          >
            Записаться сейчас
          </Button>
          <Button
            onClick={() => window.open(RESOURCE_URLS.CONSULTATION, '_blank')}
            variant="outlined"
            icon={<EditIcon size="16" />}
          >
            Забронировать время
          </Button>
        </div>
      </div>
    </Modal>
  );
});
