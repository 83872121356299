interface IScrollToTop {
  blockRef?: HTMLDivElement;
  blockId?: string;
}

export const scrollToTop = ({blockRef, blockId}: IScrollToTop) => {
  const scrollConfig = {
    top: 0,
    behavior: 'smooth',
  } as ScrollToOptions;

  if (blockRef) {
    blockRef.scrollTo(scrollConfig);
  }

  if (blockId) {
    document.getElementById(blockId)?.scrollTo(scrollConfig);
  }

  if (!blockRef && !blockId) {
    window.scrollTo(scrollConfig);
  }
};
