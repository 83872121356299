export const SaveIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M19 10.9411V16.9015C18.9988 17.8295 18.6297 18.719 17.9736 19.3752C17.3175 20.0313 16.4279 20.4004 15.5 20.4015H8.49999C7.57208 20.4004 6.68249 20.0313 6.02635 19.3752C5.37022 18.719 5.00111 17.8295 5 16.9015V7.10157C5.00111 6.17365 5.37022 5.28406 6.02635 4.62793C6.68249 3.97179 7.57208 3.60269 8.49999 3.60158H11.6605C12.3042 3.59992 12.9419 3.72589 13.5366 3.9722C14.1314 4.21851 14.6714 4.58027 15.1255 5.03657L17.5643 7.47677C18.0208 7.93053 18.3828 8.47038 18.6293 9.06504C18.8757 9.6597 19.0017 10.2974 19 10.9411ZM14.1357 6.02637C13.9154 5.81298 13.668 5.62942 13.4 5.48037V8.50156C13.4 8.68721 13.4737 8.86526 13.605 8.99654C13.7363 9.12781 13.9143 9.20156 14.1 9.20156H17.1212C16.972 8.93359 16.7882 8.68646 16.5745 8.46656L14.1357 6.02637ZM17.6 10.9411C17.6 10.8256 17.5776 10.715 17.5671 10.6016H14.1C13.543 10.6016 13.0089 10.3803 12.6151 9.98648C12.2212 9.59266 12 9.05852 12 8.50156V5.03447C11.8866 5.02397 11.7753 5.00157 11.6605 5.00157H8.49999C7.94304 5.00157 7.40889 5.22282 7.01507 5.61665C6.62124 6.01047 6.4 6.54461 6.4 7.10157V16.9015C6.4 17.4585 6.62124 17.9926 7.01507 18.3865C7.40889 18.7803 7.94304 19.0015 8.49999 19.0015H15.5C16.0569 19.0015 16.5911 18.7803 16.9849 18.3865C17.3787 17.9926 17.6 17.4585 17.6 16.9015V10.9411Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8355 13.3701C15.0577 13.1479 15.0577 12.7874 14.8355 12.5652C14.6132 12.3429 14.2528 12.3429 14.0305 12.5652L11.5869 15.0087L10.2818 13.7036C10.0595 13.4813 9.69907 13.4813 9.47678 13.7036C9.25449 13.9259 9.25449 14.2863 9.47678 14.5086L11.1844 16.2162C11.4067 16.4385 11.7671 16.4385 11.9894 16.2162L14.8355 13.3701Z"
        fill={color}
      />
    </svg>
  );
};
