import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {COLORS} from '@shared/const/appPalette';
import {TARIFFS_TYPES} from '@shared/enums/tariffs';
import {BellIcon, RightArrowIcon} from '@src/components/common/icons';
import {getRoutePricing} from '@src/shared/const/router';
import dayjs from 'dayjs';

import {useCurrentTariff} from '../../../hooks/useCurrenTariff';
import {useStore} from '../../../stores';
import {Button, Typography} from '../..';

import {useEmotionWrapper} from './tariff-notification.s';

export const TariffNotification = observer(() => {
  const {classes} = useEmotionWrapper();
  const navigate = useNavigate();
  const {tariffs, mpAccounts} = useStore();
  const {currentTariff} = useCurrentTariff();

  const getRemainingDays = useMemo(() => {
    const now = dayjs(new Date());
    const expirationDate = dayjs(currentTariff.periodTo);
    return expirationDate.diff(now, 'day');
  }, [currentTariff.periodTo]);

  const isViewNotification = useMemo(() => {
    const daysLeft = getRemainingDays;
    const isStartTariff = currentTariff.type === TARIFFS_TYPES.START;
    const isTrialTariff = currentTariff.isTrial;

    if (!isTrialTariff && !isStartTariff && daysLeft < 0) {
      return true;
    } else {
      return false;
    }
  }, [mpAccounts.current?.id, currentTariff.id, JSON.stringify(tariffs.list)]);

  if (!isViewNotification) return null;

  return (
    <div className={classes.root}>
      <div>
        <BellIcon />
      </div>
      <Typography weight="bold">
        Оплата не поступила. Пожалуйста, оплатите тарифный план для продолжения работы.
      </Typography>
      <div className={classes.buttonContainer}>
        <Button onClick={() => navigate(getRoutePricing())} color={COLORS.PURPLE_30}>
          <div className={classes.buttonContent}>
            <Typography size={13} weight="bold" color={COLORS.DARK_100}>
              Выбрать тариф
            </Typography>
            <RightArrowIcon color={COLORS.DARK_100} size="16px" />
          </div>
        </Button>
      </div>
    </div>
  );
});
