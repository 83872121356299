export const TelegramIcon = ({size = '24px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="url(#paint0_linear_11573_5705)"
      />
      <path
        d="M16.9899 7.03611C17.0834 6.3457 16.509 5.80076 15.9719 6.07028L5.27361 11.4384C4.88842 11.6317 4.9166 12.2985 5.3161 12.4439L7.52232 13.2469C7.94344 13.4001 8.39937 13.3209 8.76709 13.0305L13.7412 9.10312C13.8912 8.98466 14.0547 9.22843 13.9266 9.37937L10.3461 13.5982C9.99874 14.0075 10.0677 14.701 10.4855 15.0004L14.4942 17.8734C14.9439 18.1956 15.5223 17.8719 15.6063 17.2509L16.9899 7.03611Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_11573_5705" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#37BBFE" />
          <stop offset="1" stopColor="#007DBB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
