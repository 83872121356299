import {Typography} from '../..';

export const StrategyTooltip = () => {
  return (
    <Typography>
      Стратегия продвижения для товаров:
      <br />
      <Typography weight={700}>Икусственный интеллект</Typography> - Smart Seller сам назначает ставки в рамках
      разрешенного ДРР, оптимизируя расход и увеличивая продажи.
      <br />
      <Typography weight={700}>Рекомендации Я.Маркета</Typography> - Smart Seller назначает ставки, которые рекомендует
      Я.Маркет для достижения нужного количества показов, не выходя за рамки ДРР.
      <br />
      <Typography weight={700}> Ручное управление </Typography> - Вы устанавливаете нужную ставку, а Smart Seller
      отправляет ее на Я.Маркет.
    </Typography>
  );
};
