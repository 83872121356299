import {useLocation, useNavigate} from 'react-router-dom';

export const useRouterApp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getAccessToken = () => {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    return {
      value: hashParams.get('access_token'),
      expiresIn: Number(hashParams.get('expires_in')),
    };
  };

  return {
    location,
    navigate,
    getAccessToken,
  };
};
