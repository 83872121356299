export function extractUsernameFromEmail(email: string) {
  const regex = /^(.+)@/;
  const match = email.match(regex);

  if (match) {
    return match[1];
  } else {
    return null;
  }
}
