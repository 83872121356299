import {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {CopyIcon} from '@src/components/common/icons';
import {useStore} from '@src/stores';

import {TShop} from '../../types';
import {getAnalysisLink} from '../../utils/get-analysis-link';

import {useEmotionWrapper} from './analysis-shop-tooltip.s';

interface IAnalysisShopTooltip {
  productShops: TShop[];
  onClose: () => void;
  link: string;
  visible: boolean;
}

export const AnalysisShopTooltip: FC<IAnalysisShopTooltip> = observer(({productShops, link, onClose, visible}) => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts} = useStore();

  const onClickShopItem = (shopId: number) => {
    const businessId = mpAccounts.current?.businessId;
    const searchQuery = new URLSearchParams(new URL(link).search).get('text');
    let analysisUrl = '';

    if (businessId && searchQuery) {
      analysisUrl = getAnalysisLink({
        businessId,
        shopId,
        searchQuery,
      });
      window.open(analysisUrl, '_blank');
    }

    onClose();
  };

  useEffect(() => {
    if (visible) {
      if (productShops.length === 1) {
        onClickShopItem(productShops[0].id);
      }
    }
  }, [visible]);

  return (
    <ul className={classes.shopsList}>
      {productShops.map((shop) => (
        <li key={shop.id} onClick={() => onClickShopItem(shop.id)}>
          <div className={classes.shopLinkItemContainer}>
            Анализ в {shop.name}
            <div className={classes.buttonContainer}>
              <CopyIcon size="18" />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
});
