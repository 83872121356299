import {type FC} from 'react';

import {COLORS} from '@shared/const/appPalette';
import {FaqIcon} from '@src/components/common/icons';
import {PositionType} from '@src/components/common/tooltip/tooltip';

import {Tooltip} from '../..';

interface IFaqBlock {
  content: string | React.ReactNode;
  destroy?: boolean;
  position?: PositionType;
  width?: number | string;
  iconColor?: string;
}

export const FaqBlock: FC<IFaqBlock> = ({content, position, width, iconColor = COLORS.BLACK_15, destroy = false}) => {
  return (
    <Tooltip destroy={destroy} content={content} position={position} width={width}>
      <FaqIcon color={iconColor} />
    </Tooltip>
  );
};
