import {COLORS} from '../../../shared/const/appPalette';
import {emotionWrapper, keyframes} from '../../../shared/lib';

const slideIn = keyframes`
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }`;

export const useEmotionWrapper = emotionWrapper({
  root: {
    height: 32,
    color: '#393939',
    position: 'relative',
    cursor: 'pointer',
    border: `1px solid ${COLORS.DARK_20}`,
    borderRadius: '7px',
    '&:focus': {
      boxShadow: `0px 0px 0px 1px ${COLORS.PURPLE_100}`,
      borderColor: COLORS.PURPLE_100,
      outline: 'none',
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '7px',
    height: '100%',
    padding: '0 12px',
    fontSize: '14px',
    fontWeight: '500',
    span: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  dropdown: {
    width: '100%',
    height: 'fit-content',
    maxHeight: '175px',
    overflow: 'auto',
    position: 'absolute',
    backgroundColor: '#ffffff',
    borderRadius: '7px',
    border: '1px solid #e6e6e6',
    zIndex: 1,
    scrollbarWidth: 'none',
    animation: `${slideIn} 0.4s`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > :nth-of-type(2n - 1)': {
      backgroundColor: '#f8f8f8',
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
    height: '34px',
    fontSize: '13px',
    fontWeight: '500',
  },
  disabled: {
    backgroundColor: '#F3F3F3',
    color: '#868686',
    cursor: 'not-allowed',

    '&:focus': {
      boxShadow: 'none',
      borderColor: COLORS.DARK_20,
      outline: 'none',
    },
  },
});
