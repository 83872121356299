export const SortNoneIcon = ({size = '16px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60987 11.375C3.73089 11.4998 3.89744 11.5703 4.07135 11.5703C4.24527 11.5703 4.41181 11.4998 4.53284 11.375L7.9614 7.83925C8.2086 7.58437 8.20235 7.17735 7.94743 6.93024C7.69252 6.68304 7.28555 6.68929 7.03844 6.94421L4.71421 9.34104L4.71421 0.641728C4.71421 0.2867 4.42638 -0.00112915 4.07135 -0.00112915C3.71632 -0.00112915 3.4285 0.2867 3.4285 0.641728L3.4285 9.34104L1.10427 6.94421C0.857155 6.68929 0.450181 6.68304 0.195267 6.93024C-0.0596476 7.17735 -0.0659027 7.58437 0.181297 7.83925L3.60987 11.375Z"
        fill="#999999"
      />
    </svg>
  );
};
