export const RightArrowIcon = ({size = '17', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M6.60937 3.45671L11.276 8.12337L6.60938 12.79"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
