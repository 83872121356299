import {flow, Instance, types} from 'mobx-state-tree';
import api from '@shared/configs/axiosConfig';

import {handlingConsoleRequestError} from '@shared/utils/handlingConsoleRequestError';
import {TRecommendationStrategyValues} from '@src/shared/enums/bidder-strategy';

import {BidderStrategyModel} from '../shared-models/bidder-strategy-model';
import {FeeConditionsModel, TFeeConditionsModel} from '../shared-models/fee-conditions-model';
import {RecommendationStrategyModel} from '../shared-models/recommendation-strategy-model';

export const Rule = types.model('RuleItem', {
  id: types.identifierNumber,
  strategy: BidderStrategyModel,
  recommendationStrategy: RecommendationStrategyModel,
  feeConditions: types.array(FeeConditionsModel),
  name: types.string,
  createdAt: types.string,
  categoriesIds: types.array(types.number),
  shopsIds: types.array(types.number),
  vendorsIds: types.array(types.number),
  groupsIds: types.array(types.number),
  maxBidderFeePct: types.number,
  maxCurrentFeePct: types.number,
  minCurrentFeePct: types.number,
  skuCount: types.number,
  priority: types.number,
});

export const RulesStore = types
  .model('RulesStore', {
    list: types.optional(types.array(Rule), []),
    isLoading: types.optional(types.boolean, false),
    isOpenRuleModal: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    getRulesList: flow(function* (
      marketPlaceAccountId: number,
      {isHiddenLoad}: {isHiddenLoad: boolean | undefined} = {isHiddenLoad: false}
    ) {
      try {
        self.isLoading = isHiddenLoad ? false : true;
        const list = yield api
          .get(`${import.meta.env.WEB_API_BASE_URL}/rules/list`, {params: {marketPlaceAccountId}})
          .then((r) => r.data);
        self.list = list;
      } catch (e) {
        handlingConsoleRequestError(e);
      } finally {
        self.isLoading = false;
      }
    }),
    getRule: flow(function* (ruleId: number) {
      try {
        const r = yield api.get(`${import.meta.env.WEB_API_BASE_URL}/rules/${ruleId}`).then((r) => r.data);
        return r;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    deleteRule: flow(function* (ruleId: number) {
      try {
        yield api.delete(`${import.meta.env.WEB_API_BASE_URL}/rules/${ruleId}`).then((r) => r.data);
        const findIndex = self.list.findIndex((item) => item.id === ruleId);
        if (findIndex !== -1) {
          self.list.splice(findIndex, 1);
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateRule: flow(function* (
      marketPlaceAccountId: number,
      rule: {
        id: number;
        name?: string;
        strategy?: string;
        categoriesIds?: number[];
        shopsIds?: number[];
        vendorsIds?: number[];
        groupsIds?: number[];
        maxBidderFeePct?: number;
        recommendationStrategy: TRecommendationStrategyValues;
        feeConditions?: TFeeConditionsModel[];
      }
    ) {
      try {
        yield api
          .patch(`${import.meta.env.WEB_API_BASE_URL}/rules/${rule.id}`, {...rule, marketPlaceAccountId})
          .then((r) => r.data);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    createRule: flow(function* (
      marketPlaceAccountId: number,
      rule: {
        name?: string;
        strategy?: string;
        categoriesIds?: number[];
        vendorsIds?: number[];
        groupsIds?: number[];
        shopsIds?: number[];
        maxBidderFeePct?: number;
        recommendationStrategy: TRecommendationStrategyValues;
        feeConditions?: TFeeConditionsModel[];
      }
    ) {
      try {
        const {
          name,
          strategy,
          categoriesIds,
          vendorsIds,
          groupsIds,
          maxBidderFeePct,
          shopsIds,
          recommendationStrategy,
          feeConditions,
        } = rule;
        yield api
          .post(`${import.meta.env.WEB_API_BASE_URL}/rules/create`, {
            marketPlaceAccountId,
            categoriesIds,
            vendorsIds,
            groupsIds,
            shopsIds,
            name,
            strategy,
            maxBidderFeePct,
            recommendationStrategy,
            feeConditions,
          })
          .then((r) => r.data);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    changeRulePriority: flow(function* (ruleId: number, priority: number) {
      try {
        yield api.patch(`${import.meta.env.WEB_API_BASE_URL}/rules/${ruleId}/priority`, {priority}).then((r) => r.data);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    changeRuleModalStatus: (isOpen: boolean) => {
      self.isOpenRuleModal = isOpen;
    },
  }))
  .actions(() => ({}));

export type TRule = Instance<typeof Rule>;
