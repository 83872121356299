import {FC, useState} from 'react';

import {IconButton} from '@src/components/common/buttons/icon-button';
import {CopyToClipboardIcon, EditIcon, PriceMonitoringIcon} from '@src/components/common/icons';
import {Tooltip} from '@src/components/common/tooltip';
import {Typography} from '@src/components/common/typography';
import {COLORS} from '@src/shared/const/appPalette';

import {TProductOtherLink, TShop} from '../../types';
import {handleClickToClipboard} from '../../utils/click-to-clipboard';
import {AnalysisShopTooltip} from '../analysis-shop-tooltip/analysis-shop-tooltip';

import {useEmotionWrapper} from './search-link.s';

interface ISearchLink {
  link: TProductOtherLink;
  isDisabled: boolean;
  onChangeCurrentLink: (link: TProductOtherLink) => void;
  productShops: TShop[];
}

export const SearchLink: FC<ISearchLink> = ({link, isDisabled, onChangeCurrentLink, productShops}) => {
  const {classes} = useEmotionWrapper();
  const [isOpenAnalysisTooltip, setIsOpenAnalysisTooltip] = useState(false);

  return (
    <>
      <div className={classes.liTitle} onClick={() => window.open(link.link, '_blank')}>
        <Typography color={isDisabled ? COLORS.DARK_20 : COLORS.DARK_100}>{link.name}</Typography>
      </div>
      <IconButton size="small" onClick={() => link && onChangeCurrentLink(link)}>
        <EditIcon />
      </IconButton>
      <IconButton size="small" onClick={() => handleClickToClipboard(link.link)}>
        <CopyToClipboardIcon />
      </IconButton>
      <Tooltip destroy={isOpenAnalysisTooltip} content="Анализ  товаров в поиске">
        <Tooltip
          interactive
          onClickOutside={() => setIsOpenAnalysisTooltip(false)}
          visible={isOpenAnalysisTooltip}
          content={
            <AnalysisShopTooltip
              link={link.link}
              productShops={productShops}
              visible={isOpenAnalysisTooltip}
              onClose={() => setIsOpenAnalysisTooltip(false)}
            />
          }
        >
          <IconButton size="small" onClick={() => setIsOpenAnalysisTooltip(!isOpenAnalysisTooltip)}>
            <PriceMonitoringIcon size="15" />
          </IconButton>
        </Tooltip>
      </Tooltip>
    </>
  );
};
