import {type FC} from 'react';

import {useEmotionWrapper} from './table-header.s';

interface ITableHeader {
  children?: React.ReactNode;
}
export const TableHeader: FC<ITableHeader> = ({children}) => {
  const {classes} = useEmotionWrapper();

  return <thead className={classes.tableHeader}>{children}</thead>;
};
