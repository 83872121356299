import {emotionWrapper, keyframes} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';
import {TIconButtonColor, TIconButtonSize} from './types';

interface IIconButtonStyles {
  color: TIconButtonColor;
  size: TIconButtonSize;
}

const getIconColor = (color: TIconButtonColor) => {
  switch (color) {
    case 'primary':
      return COLORS.PURPLE_100;
    case 'error':
      return COLORS.RED_100;
    case 'warning':
      return COLORS.YELLOW_100;
    case 'success':
      return COLORS.GREEN_100;
    default:
      return COLORS.DARK_70;
  }
};
const cssBorder = keyframes({
  '0%': {
    backgroundColor: 'transparent',
  },
  '100%': {
    backgroundColor: '#F8F8F8',
  },
});

const getSize = (size: TIconButtonSize) => {
  switch (true) {
    case size === 'large':
      return 52;
    case size === 'medium':
      return 44;
    case size === 'small':
      return 32;
    default:
      return size;
  }
};

export const useEmotionWrapper = emotionWrapper(({size, color}: IIconButtonStyles) => ({
  root: {
    display: 'inline-block',
    userSelect: 'none',
    width: getSize(size),
    height: getSize(size),
    minWidth: getSize(size),
    minHeight: getSize(size),
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    cursor: 'pointer',
    textAlign: 'center',
    padding: 0,
    '&:hover': {
      animation: `${cssBorder} .3s forwards cubic-bezier(0.4, 0, 0.2, 1)`,
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.6,
    },
    '&:not(:disabled)': {
      '&:active': {
        transform: 'scale(0.9)',
      },
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    svg: {
      color: getIconColor(color),
    },
  },
}));
