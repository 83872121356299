import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper({
  contentEnter: {
    opacity: 0,
    transform: 'scale(0.01)',
  },
  contentEnterActive: {
    transition: 'opacity 100ms ease-out 200ms, transform 100ms ease-out 200ms',
    opacity: 1,
    transform: 'scale(1)',
  },
  contentExit: {
    opacity: 1,
    transform: 'scale(1)',
  },
  contentExitActive: {
    opacity: 0,
    transform: 'scale(0.01)',
    transition: 'opacity 300ms ease-out, transform 300ms ease-out',
  },
  overlayEnter: {
    opacity: 0,
  },
  overlayEnterActive: {
    transition: 'opacity 300ms ease-out',
    opacity: 1,
  },
  overlayExit: {
    opacity: 1,
  },
  overlayExitActive: {
    opacity: 0,
    transition: 'opacity 300ms ease-out',
  },
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '36px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2,
    cursor: 'pointer',
  },
  content: {
    border: '1px solid #e6e6e6',
    boxSizing: 'border-box',
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    overflow: 'auto',
    backgroundColor: COLORS.WHITE,
    zIndex: 3,
    maxWidth: '90vw',
    maxHeight: '90vh',
    padding: '18px 24px',
  },
});
