import {cast, types} from 'mobx-state-tree';

import {RULE_CREATED} from '@shared/const/localStorage';

const NewRuleNotify = types.model({
  id: types.number,
  count: types.number,
  createdAt: types.string,
});

export const UtilsStore = types
  .model('UtilsStore', {
    newRuleNotify: types.optional(types.array(NewRuleNotify), []),
    isNetworkError: types.optional(types.boolean, false),
    isOpenOfferTrialModal: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    seTRuleUpdateRuleNotify: (currentMpAccId: number) => {
      const findIndex = self.newRuleNotify.findIndex((item) => item.id === currentMpAccId);
      if (findIndex !== -1) {
        self.newRuleNotify[findIndex].count = self.newRuleNotify[findIndex].count + 1;
        self.newRuleNotify[findIndex].createdAt = new Date().toISOString();
        localStorage.setItem(RULE_CREATED, JSON.stringify(self.newRuleNotify));
      } else {
        const newArr = [...self.newRuleNotify];
        newArr.push({id: currentMpAccId, count: 1, createdAt: new Date().toISOString()});
        self.newRuleNotify = cast(newArr);
        localStorage.setItem(RULE_CREATED, JSON.stringify(self.newRuleNotify));
      }
    },
    reseTRuleUpdateRuleNotify: (currentMpAccId: number) => {
      const findIndex = self.newRuleNotify.findIndex((item) => item.id === currentMpAccId);
      if (findIndex !== -1) {
        const newArr = [...self.newRuleNotify];
        newArr.splice(findIndex, 1);
        self.newRuleNotify = cast(newArr);
        localStorage.setItem(RULE_CREATED, JSON.stringify(self.newRuleNotify));
      }
    },
    getRuleNotify: (currentMpAccId: number) => {
      const findIndex = self.newRuleNotify.findIndex((item) => item.id === currentMpAccId);
      if (findIndex === -1) {
        return null;
      }
      return self.newRuleNotify[findIndex].count;
    },
    setNetworkError: (isNetworkError: boolean) => {
      self.isNetworkError = isNetworkError;
    },
    openOfferTrialModal: () => {
      self.isOpenOfferTrialModal = true;
    },
    closeOfferTrialModal: () => {
      self.isOpenOfferTrialModal = false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const notifyFromLocalStorage = JSON.parse(localStorage.getItem(RULE_CREATED) || JSON.stringify([]));
      if (notifyFromLocalStorage.length > 0) {
        self.newRuleNotify = cast([...notifyFromLocalStorage]);
      }
    },
  }));
