import {type FC, useRef} from 'react';

import {Button} from '@src/components';
import {PlusCircleIcon} from '@src/components/common/icons';
import {COLORS} from '@src/shared/const/appPalette';
import {ITEM_FEE_CONDITIONS} from '@src/shared/enums/fee-conditions';
import {TFeeConditionsModel} from '@src/stores/shared-models/fee-conditions-model';

import {PromoCondition} from './components/promo-condition/promo-condition';
import {StorageOrMarginCondition} from './components/storage-or-margin-condition/storage-or-margin-condition';
import {TimeCondition} from './components/time-condition/time-condition';

import {useEmotionWrapper} from './fee-conditions-edit-block.s';

interface IFeeConditionsEditBlock {
  conditionsList: TFeeConditionsModel[];
  addNewCondition: () => void;
  removeCondition: (index: number) => void;
  onChangeCondition: (index: number, condition: TFeeConditionsModel) => void;
  margin?: number | null;
  onChangeProductMargin?: (margin: string) => void;
  confilictingConditions: {id: number | null; isError: boolean}[];
}

export const FeeConditionsEditBlock: FC<IFeeConditionsEditBlock> = ({
  conditionsList,
  addNewCondition,
  removeCondition,
  onChangeCondition,
  confilictingConditions,
}) => {
  const {classes} = useEmotionWrapper();
  const listRef = useRef<HTMLDivElement>(null);

  const stocksConditionIsNotCreated = conditionsList.some((condition) => condition.type === ITEM_FEE_CONDITIONS.PROMO);

  const scrollToBottom = () => {
    if (listRef.current && listRef.current?.lastElementChild !== null) {
      listRef?.current.lastElementChild.scrollIntoView({behavior: 'smooth'});
    }
  };

  const handleAddNewCondition = () => {
    addNewCondition();
    setTimeout(scrollToBottom, 0);
  };

  const conditionsByType = () => {
    return conditionsList.map((condition, idx) => {
      switch (condition.type) {
        case ITEM_FEE_CONDITIONS.TIME:
          return (
            <TimeCondition
              hidePromoType={stocksConditionIsNotCreated}
              removeCondition={removeCondition}
              key={condition.id ? condition.id : idx + Math.random()}
              condition={condition}
              onChangeCondition={onChangeCondition}
            />
          );

        case ITEM_FEE_CONDITIONS.PROMO:
          return (
            <PromoCondition
              key={condition.id ? condition.id : idx + Math.random()}
              condition={condition}
              onChangeCondition={onChangeCondition}
              removeCondition={removeCondition}
            />
          );

        case ITEM_FEE_CONDITIONS.MARGIN:
        case ITEM_FEE_CONDITIONS.STORAGE_COUNT:
          return (
            <StorageOrMarginCondition
              hidePromoType={stocksConditionIsNotCreated}
              key={condition.id ? condition.id : idx + Math.random()}
              conditionsCount={conditionsList.length}
              isValidConditions={confilictingConditions.findIndex((item) => item.id === condition.id) === -1}
              removeCondition={removeCondition}
              onChangeCondition={onChangeCondition}
              condition={condition}
            />
          );
      }
    });
  };

  return (
    <div className={classes.root}>
      <div ref={listRef} className={classes.conditionList}>
        {conditionsByType()}
      </div>
      <div>
        <Button
          id="onboarding-sales-boost-create-fee-conditions"
          data-cy="add-condition-button"
          icon={<PlusCircleIcon size="17" />}
          onClick={handleAddNewCondition}
          color={COLORS.PURPLE_100}
          variant="text"
        >
          Добавить еще одно условие
        </Button>
      </div>
    </div>
  );
};
