export const UserAvatarIcon = ({size = '36px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M33 20C33 28.2843 26.2843 35 18 35C9.71573 35 3 28.2843 3 20C3 11.7157 9.71573 5 18 5C26.2843 5 33 11.7157 33 20Z"
        fill="#E5E5E5"
      />
      <path
        d="M33 20C33 28.2843 26.2843 35 18 35C9.71573 35 3 28.2843 3 20C3 11.7157 9.71573 5 18 5C26.2843 5 33 11.7157 33 20Z"
        fill="#E5E5E5"
      />
      <path
        d="M18 21.4994C19.5817 21.4994 20.9357 20.9362 22.0621 19.8098C23.1885 18.6835 23.7516 17.3294 23.7516 15.7477C23.7516 14.166 23.1885 12.812 22.0621 11.6856C20.9357 10.5593 19.5817 9.99609 18 9.99609C16.4183 9.99609 15.0643 10.5593 13.9379 11.6856C12.8115 12.812 12.2484 14.166 12.2484 15.7477C12.2484 17.3294 12.8115 18.6835 13.9379 19.8098C15.0643 20.9362 16.4183 21.4994 18 21.4994ZM18 33.0026C20.1569 33.0026 22.1819 32.5473 24.0752 31.6366C25.9684 30.7259 27.61 29.4318 29 27.7543C27.3224 26.6519 25.561 25.8131 23.7157 25.2379C21.8704 24.6628 19.9651 24.3752 18 24.3752C16.0349 24.3752 14.1296 24.6628 12.2843 25.2379C10.439 25.8131 8.67756 26.6519 7 27.7543C8.38998 29.4318 10.0316 30.7259 11.9248 31.6366C13.8181 32.5473 15.8431 33.0026 18 33.0026Z"
        fill="white"
      />
    </svg>
  );
};
