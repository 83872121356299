export const DraggableIcon = ({color = '#C3C3C3', ...others}) => {
  return (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M6.64827 0.348119C6.84311 0.155281 7.15689 0.15528 7.35173 0.348119L12.1979 5.14463C12.5154 5.45886 12.2929 6 11.8462 6H2.15378C1.70709 6 1.48457 5.45886 1.80206 5.14463L6.64827 0.348119Z"
        fill={color}
      />
      <path
        d="M6.64827 23.6519C6.84311 23.8447 7.15689 23.8447 7.35173 23.6519L12.1979 18.8554C12.5154 18.5411 12.2929 18 11.8462 18H2.15378C1.70709 18 1.48457 18.5411 1.80206 18.8554L6.64827 23.6519Z"
        fill={color}
      />
    </svg>
  );
};
