import {FC, HTMLAttributes} from 'react';

import {useEmotionWrapper} from './table-body.s';

interface ITableBodyProps extends HTMLAttributes<HTMLTableSectionElement> {}

export const TableBody: FC<ITableBodyProps> = ({children, ...other}) => {
  const {classes} = useEmotionWrapper();
  return (
    <tbody className={classes.tableBody} {...other}>
      {children}
    </tbody>
  );
};
