import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  shopsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    li: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        color: COLORS.PURPLE_100,
        transition: 'color .3s ease',
      },
    },
  },
  shopLinkItemContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));
