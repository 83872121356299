import {FC, useEffect, useState} from 'react';

import {CustomSelect, FaqBlockWrapper, Input, Typography} from '@src/components';
import {COLORS} from '@src/shared/const/appPalette';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';

import {useEmotionWrapper} from './rate-and-boost-control.s';

interface IRateAndBoostControl {
  percentValue: string | number | null;
  price?: number;
  onChange: (percentValue: number, units: 'PERCENT' | 'ROUBLE') => void;
  margin?: number;
  onChangeMargin?: (value: string) => void;
  isHideCalculation?: boolean;
}

const CALCULATION_VALUES = {
  PRICE: 'PRICE',
  MARGINALITY: 'MARGINALITY',
};
type TCurrentCalcOptions = {
  value: 'PRICE' | 'MARGINALITY';
  label: string;
};

const CALCULATION_OPTIONS: TCurrentCalcOptions[] = [
  {
    label: 'От цены товара',
    value: 'PRICE',
  },
  {
    label: 'От маржинальности',
    value: 'MARGINALITY',
  },
];

type TPriceUnits = {
  label: string;
  value: 'PERCENT' | 'ROUBLE';
};

const PRICING_UNIT: TPriceUnits[] = [
  {
    label: '%',
    value: 'PERCENT',
  },
  {
    label: '₽',
    value: 'ROUBLE',
  },
];

export const RateAndBoostControl: FC<IRateAndBoostControl> = ({
  percentValue,
  price,
  onChange,
  margin,
  onChangeMargin,
  isHideCalculation = false,
}) => {
  const {classes} = useEmotionWrapper();
  const [value, setValue] = useState(percentValue);
  const [currentCalcOption, setCurrentCalcOption] = useState<TCurrentCalcOptions | null>(CALCULATION_OPTIONS[0]);
  const [currentPricingUnits, setCurrentPricingUnits] = useState<TPriceUnits | null>(PRICING_UNIT[0]);
  const [marginalityValue, setMarginalityValue] = useState(margin ? margin.toString() : '0');

  const onChangePercentValue = (value: string) => {
    if (currentCalcOption?.value === CALCULATION_VALUES.MARGINALITY) {
      let formattedValue = stringToNumberWithDot(value);
      formattedValue = (Number(marginalityValue) * Number(formattedValue)) / 100;
      setValue(value);
      onChange(formattedValue, 'PERCENT');
    } else {
      setValue(value);
      const formattedValue = stringToNumberWithDot(value);
      onChange(formattedValue, currentPricingUnits?.value as 'PERCENT' | 'ROUBLE');
    }
  };

  useEffect(() => {
    if (currentPricingUnits?.value === 'PERCENT') {
      setValue(percentValue);
    } else {
      setValue(((Number(price) * Number(value)) / 100).toFixed(2));
    }
  }, [currentPricingUnits]);

  useEffect(() => {
    if (currentCalcOption?.value === CALCULATION_VALUES.MARGINALITY) {
      setValue(0);
    } else {
      setValue(percentValue);
    }
  }, [currentCalcOption]);

  useEffect(() => {
    if (currentCalcOption?.value === CALCULATION_VALUES.MARGINALITY && marginalityValue) {
      const formattedMarginalityValue = stringToNumberWithDot(marginalityValue);
      if (value) {
        const formattedValue = (Number(formattedMarginalityValue) * Number(value)) / 100;
        onChange(formattedValue, 'PERCENT');
      }
    }
  }, [marginalityValue]);

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        {!isHideCalculation ? (
          <CustomSelect
            value={currentCalcOption}
            onChange={(e) => setCurrentCalcOption(e)}
            isSearchable={false}
            options={CALCULATION_OPTIONS}
          />
        ) : null}
        <div>
          {currentCalcOption?.value === CALCULATION_VALUES.PRICE ? (
            <div className={classes.calcPriceContainer}>
              <div style={{width: price ? 67 : 80}} className={classes.calcPriceInputs}>
                <Input
                  data-cy="rate-and-boost-control-price"
                  placeholder="0"
                  value={value || ''}
                  onChange={(e) => onChangePercentValue(e.target.value)}
                />{' '}
                {!price && '%'}
              </div>
              {price && (
                <CustomSelect
                  width={60}
                  options={PRICING_UNIT}
                  onChange={setCurrentPricingUnits}
                  value={currentPricingUnits}
                />
              )}
            </div>
          ) : (
            <div className={classes.calcPercentInputs}>
              <Input
                data-cy="rate-and-boost-control-percent"
                placeholder="0"
                value={value || ''}
                onChange={(e) => onChangePercentValue(e.target.value)}
              />
              %
            </div>
          )}
        </div>
      </div>

      {currentCalcOption?.value === CALCULATION_VALUES.MARGINALITY && (
        <div className={classes.margContainer}>
          <FaqBlockWrapper content="Укажите маржинальность товара, чтобы более гибко управлять ставкой буста">
            <Typography size={18} weight={700} color={COLORS.DARK_50}>
              Маржинальность
            </Typography>
          </FaqBlockWrapper>
          <div className={classes.calcPercentInputs}>
            <Input
              placeholder="0"
              value={marginalityValue || ''}
              onChange={(e) => {
                onChangeMargin && onChangeMargin(e.target.value);
                setMarginalityValue(e.target.value);
              }}
            />
            %
          </div>
        </div>
      )}
    </div>
  );
};
