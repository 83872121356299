import {type FC} from 'react';
import {MultiValue} from 'react-select';
import {observer} from 'mobx-react-lite';

import {CustomSelect} from '@src/components';
import {mappedFilters} from '@src/components/specific/item-filters/utils/mapped-filters';
import {FILTER_TYPE_TITLE} from '@src/shared/const/filtersTypeTitle';
import {getSelectPlaceholder} from '@src/shared/utils/getPlaceholderFilterForSelect';

import {FILTER_TYPE} from '../enums';
import {IFilter} from '../types';

export const ShopsFilter: FC<IFilter> = observer(({options, onChange, value, isDisabled = false}) => {
  const optionsLength = options.length;

  const mappedReturnValues = (
    values: MultiValue<{
      label: string;
      value: string;
    }>
  ) => {
    const mappedValues = values.map((item) => {
      return {
        id: Number(item?.value),
        name: item?.label,
      };
    });
    onChange(mappedValues);
  };

  return (
    <CustomSelect
      isMulti
      isCheckboxOptions
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      width="140px"
      isDisabled={isDisabled}
      options={mappedFilters(options, FILTER_TYPE.SHOPS)}
      value={mappedFilters(value, FILTER_TYPE.SHOPS)}
      onChange={mappedReturnValues}
      noOptionsMessage={() => 'Нет магазинов'}
      isSelectedAllOptions={optionsLength > 1}
      isSelectedAllMessage="Все магазины"
      placeholder={getSelectPlaceholder({lengthFilterArray: value.length, title: FILTER_TYPE_TITLE.SHOPS})}
      isSelectedOptions={value.length > 0}
    />
  );
});
