export const BellIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.123047C6.90704 0.123047 2.77838 4.31908 2.77838 9.49514V10.3466C2.77838 11.1894 2.53292 12.0133 2.07293 12.7146L0.706227 14.7981C-0.880005 17.2163 0.330951 20.5032 3.08981 21.2679C3.9889 21.5171 4.89559 21.7279 5.80752 21.9003L5.80978 21.9065C6.7248 24.3878 9.17038 26.123 11.9999 26.123C14.8295 26.123 17.275 24.3878 18.1901 21.9065L18.1923 21.9003C19.1043 21.7279 20.011 21.5171 20.9102 21.2679C23.6691 20.5032 24.88 17.2163 23.2938 14.7981L21.9271 12.7146C21.4671 12.0133 21.2216 11.1894 21.2216 10.3466V9.49514C21.2216 4.31908 17.093 0.123047 12 0.123047ZM16.0175 22.2375C13.3487 22.5616 10.6511 22.5616 7.98229 22.2375C8.82825 23.4728 10.2996 24.3091 11.9999 24.3091C13.7002 24.3091 15.1716 23.4728 16.0175 22.2375ZM4.56321 9.49514C4.56321 5.3209 7.89278 1.937 12 1.937C16.1072 1.937 19.4368 5.3209 19.4368 9.49514V10.3466C19.4368 11.5475 19.7866 12.7216 20.442 13.7208L21.8087 15.8043C22.7192 17.1923 22.0241 19.0789 20.4406 19.5178C14.9144 21.0495 9.08556 21.0495 3.55943 19.5178C1.97589 19.0789 1.28083 17.1923 2.19129 15.8043L3.558 13.7208C4.21345 12.7216 4.56321 11.5475 4.56321 10.3466V9.49514Z"
        fill={color}
      />
    </svg>
  );
};
