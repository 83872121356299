import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  root: {
    display: 'flex',
    gap: 5,
  },
  calcPriceContainer: {
    display: 'flex',
    gap: 5,
  },
  calcPriceInputs: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  calcPercentInputs: {
    width: 80,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  margContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  faqBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));
