export const GroupsIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 5.79086 3.79086 4 6 4H22C24.2091 4 26 5.79086 26 8V11.5H25.9691C25.9895 11.6638 26 11.8307 26 12V15.5V21C26 23.2091 24.2091 25 22 25H6C3.79086 25 2 23.2091 2 21V15.5V12C2 11.8307 2.01052 11.6638 2.03095 11.5H2V8ZM4 15.5V21C4 22.1046 4.89543 23 6 23H22C23.1046 23 24 22.1046 24 21V15.5C24 14.3954 23.1046 13.5 22 13.5H18.9C18.4367 15.7822 16.419 17.5 14 17.5C11.581 17.5 9.56329 15.7822 9.10002 13.5H6C4.89543 13.5 4 14.3954 4 15.5ZM24 12.0351C23.4117 11.6948 22.7286 11.5 22 11.5H18H17V12.5C17 14.1569 15.6569 15.5 14 15.5C12.3431 15.5 11 14.1569 11 12.5V11.5H10H6C5.27143 11.5 4.58835 11.6948 4 12.0351V12C4 10.8954 4.89543 10 6 10H22C23.1046 10 24 10.8954 24 12V12.0351ZM24 8V8.53513C23.4117 8.19479 22.7286 8 22 8H6C5.27143 8 4.58835 8.19479 4 8.53513V8C4 6.89543 4.89543 6 6 6H22C23.1046 6 24 6.89543 24 8Z"
        fill={color}
      />
    </svg>
  );
};
