import {type FC} from 'react';

import {BIDDER_STRATEGY} from '@shared/enums/bidder-strategy';
import {LogoAnimated} from '@src/components/common/icons';

interface IBidderStrategyIcon {
  strategy: BIDDER_STRATEGY | null | undefined;
}

export const BidderStrategyIcon: FC<IBidderStrategyIcon> = ({strategy}) => {
  if (strategy === BIDDER_STRATEGY.OFF) {
    return <LogoAnimated color="gray" />;
  }

  if (strategy === BIDDER_STRATEGY.AI) {
    return <LogoAnimated color="purple" />;
  }

  if (strategy === BIDDER_STRATEGY.MANUAL) {
    return <LogoAnimated color="green" />;
  }

  if (strategy === BIDDER_STRATEGY.RECOMMENDED) {
    return <LogoAnimated color="yellow" />;
  }

  return <LogoAnimated color="gray" />;
};
