import {type FC} from 'react';

import {FaqBlock} from '../faq-block';

import {useEmotionWrapper} from './faq-block-wrapper.s';

interface IFaqBlockWrapper {
  children: JSX.Element | string;
  content: string | JSX.Element;
}

export const FaqBlockWrapper: FC<IFaqBlockWrapper> = ({children, content, ...others}) => {
  const {classes} = useEmotionWrapper();
  return (
    <div className={classes.root} {...others}>
      {children}
      <FaqBlock content={content} />
    </div>
  );
};
