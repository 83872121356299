export const KnowledgeBaseIcon = ({size = '16px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35777 6.03341C5.13009 5.8102 4 6.75335 4 8.00115V17.9028C4 18.8694 4.69125 19.6976 5.64223 19.8705L13 21.2083V7.24109L6.35777 6.03341ZM15 7.24109V21.2083L22.3578 19.8705C23.3088 19.6976 24 18.8694 24 17.9028V8.00115C24 6.75335 22.8699 5.8102 21.6422 6.03341L15 7.24109ZM14 5.39012L6.71554 4.06567C4.26018 3.61924 2 5.50554 2 8.00115V17.9028C2 19.8359 3.3825 21.4925 5.28446 21.8383L14 23.4229L22.7155 21.8383C24.6175 21.4925 26 19.8359 26 17.9028V8.00115C26 5.50554 23.7398 3.61925 21.2845 4.06567L14 5.39012Z"
        fill={color}
      />
    </svg>
  );
};
