import {emotionWrapper} from '../../../shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: 'red',
    height: 36,
    gap: 5,
  },
  pageCountText: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    alignItems: 'center',
    padding: '0 10px',
    height: '100%',
    borderRadius: 7,
    cursor: 'default',
    minWidth: 150,
  },
}));
