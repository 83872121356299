export const RESOURCE_URLS = {
  INSTRUCTION: 'https://help.smartseller.me/',
  CONSULTATION: 'https://my.smartseller.me/demo',
  SUPPORT_TELEGRAM: 'https://t.me/smartsellermemp',
  INTEGRATION_FAQ: 'https://help.smartseller.me/integration',
  OFERTA: 'https://smartseller.me/oferta',
  TELEGRAM_CHANEL: 'https://t.me/smartsellerme',
  BUSINESS_CARD_PAYMENT_POST: 'https://help.smartseller.me/oplata',
  PRAVO: 'https://smartseller.me/pravo',
};
