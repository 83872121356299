import {FC} from 'react';
import React from 'react';

import {TIconButtonColor, TIconButtonSize} from './types';

import {Loader} from '../../loader';

import {useEmotionWrapper} from './icon-button.s';

interface IIconButton {
  children: JSX.Element;
  color?: TIconButtonColor;
  size?: TIconButtonSize;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string | string[] | Record<string, boolean>;
  stopPropagation?: boolean;
}

export type IIconButtonProps = IIconButton & Omit<React.ComponentPropsWithoutRef<'button'>, keyof IIconButton>;

export const IconButton: FC<IIconButtonProps> = ({
  children,
  color = 'default',
  size = 'medium',
  onClick,
  disabled,
  loading = false,
  className,
  stopPropagation = false,
  ...props
}) => {
  const {classes, cx} = useEmotionWrapper({size, color});

  const mergedStyles = cx(classes.root, className ? className : '');

  const getIconSize = () => {
    switch (size) {
      case 'large':
        return 28;
      case 'medium':
        return 22;
      case 'small':
        return 20;
      default:
        return size;
    }
  };

  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={mergedStyles}
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        onClick && onClick();
      }}
    >
      <div className={classes.iconContainer}>
        {loading ? (
          <Loader size={getIconSize() + 'px'} />
        ) : (
          children && React.cloneElement(children, {size: getIconSize()})
        )}
      </div>
    </button>
  );
};
