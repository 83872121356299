export const ChartIcon = ({size = '24px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M5 19V15.8889M9.66667 19V12.7778M14.3333 19V14.3333M19 19V11.2222M9.2306 6.99162C9.44173 7.20275 9.73339 7.33333 10.0556 7.33333C10.3777 7.33333 10.6694 7.20275 10.8805 6.99162M9.2306 6.99162C9.01947 6.7805 8.88889 6.48883 8.88889 6.16667C8.88889 5.52233 9.41122 5 10.0556 5C10.6999 5 11.2222 5.52233 11.2222 6.16667C11.2222 6.48883 11.0916 6.7805 10.8805 6.99162M9.2306 6.99162L6.99162 9.2306M10.8805 6.99162L13.1195 9.2306M6.99162 9.2306C6.7805 9.01947 6.48883 8.88889 6.16667 8.88889C5.52233 8.88889 5 9.41122 5 10.0556C5 10.6999 5.52233 11.2222 6.16667 11.2222C6.811 11.2222 7.33333 10.6999 7.33333 10.0556C7.33333 9.73339 7.20275 9.44173 6.99162 9.2306ZM13.1195 9.2306C12.9084 9.44173 12.7778 9.73339 12.7778 10.0556C12.7778 10.6999 13.3001 11.2222 13.9444 11.2222C14.5888 11.2222 15.1111 10.6999 15.1111 10.0556C15.1111 9.73339 14.9805 9.44173 14.7694 9.2306M13.1195 9.2306C13.3306 9.01947 13.6223 8.88889 13.9444 8.88889C14.2666 8.88889 14.5583 9.01947 14.7694 9.2306M14.7694 9.2306L17.0083 6.99162M17.0083 6.99162C17.2195 7.20275 17.5112 7.33333 17.8333 7.33333C18.4776 7.33333 19 6.811 19 6.16667C19 5.52233 18.4776 5 17.8333 5C17.189 5 16.6667 5.52233 16.6667 6.16667C16.6667 6.48883 16.7973 6.7805 17.0083 6.99162Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
