import {FC, ThHTMLAttributes} from 'react';

import {useEmotionWrapper} from './table-header-cell.s';

interface ITableHeaderCell extends ThHTMLAttributes<HTMLTableCellElement> {}

export const TableHeaderCell: FC<ITableHeaderCell> = ({children, ...other}) => {
  const {classes} = useEmotionWrapper();
  return (
    <th className={classes.root} {...other}>
      {children}
    </th>
  );
};
