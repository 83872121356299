export const CloseIcon = ({size = '16px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M1 8.5L8 1.5M1 1.5L8 8.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
