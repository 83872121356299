export const PriceMonitoringSidebarIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2483 6H13.3932C14.6244 6 15.6858 6.20026 16.5631 6.61721C17.4339 7.0243 18.105 7.59772 18.5617 8.33939C19.0237 9.07089 19.2502 10.1983 19.2502 11.13C19.2502 12.1318 19.026 13.3933 18.5619 14.1352C18.1037 14.8726 17.4297 15.4336 16.5555 15.8203L16.5532 15.8212C15.6788 16.2006 14.6212 16.3828 13.3932 16.3828H11.9294L11.9292 17.5H14.5486V18.9791H11.9292L11.9294 21.7013H10.2483L10.2481 18.9791H8V17.5H10.2481L10.2483 16.3828H8.00018V14.7753H10.2483V6ZM11.9294 7.61771L13.8528 7.59723C14.7152 7.59723 15.4448 7.74637 16.0148 8.07317C16.5697 8.38726 16.9853 8.7966 17.2426 9.30436C17.4965 9.79128 17.6248 10.5948 17.6248 11.13C17.6248 11.766 17.4929 12.7183 17.2161 13.2103C16.9406 13.713 16.5121 14.0968 15.9476 14.3629C15.3792 14.6308 14.6749 14.7548 13.8528 14.7548L11.9294 14.7753V7.61771Z"
        fill={color}
      />
    </svg>
  );
};
