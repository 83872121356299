import {FC} from 'react';

import {useEmotionWrapper} from './price-button.s';

interface IPriceButton {
  children?: React.ReactNode;
}

type IPriceButtonProps = IPriceButton & Omit<React.ComponentPropsWithoutRef<'button'>, keyof IPriceButton>;

export const PriceButton: FC<IPriceButtonProps> = ({children, ...props}) => {
  const {classes} = useEmotionWrapper();
  return (
    <button className={classes.root} {...props}>
      {children}
    </button>
  );
};
