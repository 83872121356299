import {Suspense, useCallback} from 'react';
import {Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {ErrorBoundaryWrapper, RequireAuth} from '@src/components';
import {Loader} from '@src/components/common/loader';
import {routeConfig} from '@src/shared/configs/router-config';
import {AppRoutesProps} from '@src/shared/types/router';

export const AppRouter = observer(() => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const element = (
      <Suspense
        fallback={
          <div style={{width: '100%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loader size="42px" />
          </div>
        }
      >
        {route.element}
      </Suspense>
    );

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          route.authOnly ? (
            <ErrorBoundaryWrapper>
              <RequireAuth>{element}</RequireAuth>
            </ErrorBoundaryWrapper>
          ) : (
            <ErrorBoundaryWrapper>{element}</ErrorBoundaryWrapper>
          )
        }
      />
    );
  }, []);
  return <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>;
});
