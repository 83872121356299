import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

interface ICheckboxStyles {
  checked: boolean;
}

export const useEmotionWrapper = emotionWrapper(({checked}: ICheckboxStyles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    userSelect: 'none',
    '&:hover': {
      div: {
        border: `1px solid #AC79CC`,
      },
    },
    span: {
      cursor: 'pointer',
      color: COLORS.DARK_100,
      fontWeight: 500,
      fontSize: 12,
    },
  },

  input: {
    appearance: 'none',
  },
  checkIconBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    border: `1px solid ${checked ? '#AC79CC' : COLORS.DARK_20}`,
    minWidth: 16,
    height: 16,
    backgroundColor: checked ? '#F5E5FF' : 'white',
  },
}));
