import {useState} from 'react';

export const useModal = () => {
  const [opened, setOpened] = useState(false);

  const onOpenModal = () => setOpened(true);
  const onCloseModal = () => setOpened(false);
  const toggleModalState = () => setOpened((prevState) => !prevState);

  return [opened, onOpenModal, onCloseModal, toggleModalState] as [boolean, () => void, () => void, () => void];
};
