import {useEffect, useState} from 'react';
import {SingleValue} from 'react-select';
import {observer} from 'mobx-react-lite';

import {CustomSelect} from '@src/components';
import {useStore} from '@src/stores';

const strategyFilterOptions = [
  {
    label: 'Все товары',
    value: null,
  },
  {
    label: 'Только с остатками',
    value: true,
  },
  {
    label: 'Только без остатков',
    value: false,
  },
];

type TIsInStockFilterOption = {
  label: string;
  value: boolean | null;
};

export const IsInStockFilter = observer(() => {
  const {mpItems} = useStore();
  const [selectedStrategyFilter, setSelectedStrategyFilter] = useState<SingleValue<TIsInStockFilterOption> | undefined>(
    undefined
  );

  useEffect(() => {
    const stateFilter = strategyFilterOptions.find((item) => item.value === mpItems.filters.selected.isInStock);
    if (stateFilter?.value === null) {
      setSelectedStrategyFilter(undefined);
    } else {
      setSelectedStrategyFilter(
        strategyFilterOptions.find((item) => item.value === mpItems.filters.selected.isInStock)
      );
    }
  }, [mpItems.filters.selected.isInStock]);

  const onChange = (state: SingleValue<TIsInStockFilterOption>) => {
    if (!state) {
      mpItems.selectIsInStockItems(null);
      return;
    } else {
      mpItems.selectIsInStockItems(state?.value);
      if (state?.value === null) {
        setSelectedStrategyFilter(undefined);
      } else {
        setSelectedStrategyFilter(state);
      }
    }
  };

  return (
    <div style={{minWidth: 175, display: 'flex', justifyContent: 'center'}}>
      <CustomSelect
        isSelectedOptions={selectedStrategyFilter !== undefined}
        width="max-content"
        isSearchable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        isBordered={false}
        enableFocusEffect={false}
        backgroundColor="transparent"
        options={strategyFilterOptions}
        onChange={(selectedStrategies) => onChange(selectedStrategies)}
        value={selectedStrategyFilter}
        placeholder="Остатки"
      />
    </div>
  );
});
