export const DropdownIcon = ({color = 'currentColor', size = '12px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M5.40277 5.56412C5.20339 5.79413 4.8465 5.79413 4.64712 5.56412L0.541444 0.827493C0.260797 0.503716 0.490785 0 0.919265 0L9.13062 0C9.5591 0 9.78909 0.503716 9.50845 0.827493L5.40277 5.56412Z"
        fill={color}
      />
    </svg>
  );
};
