export const ChevronDoubleIcon = ({size = '19px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <g clipPath="url(#clip0_8127_22455)">
        <path
          d="M7.58366 2.5L11.667 6L7.58366 9.5M2.91699 2.5L7.00033 6L2.91699 9.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8127_22455">
          <rect width="12" height="14" fill="white" transform="translate(0 12) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};
