export enum ITEM_FEE_CONDITIONS {
  STORAGE_COUNT = 'STORAGE_COUNT',
  MARGIN = 'MARGIN',
  EXPENDITURE = 'EXPENDITURE',
  TIME = 'TIME',
  PROMO = 'PROMO',
}

export const ITEM_FEE_CONDITIONS_TYPES = [
  {label: 'Остатки', value: ITEM_FEE_CONDITIONS.STORAGE_COUNT},
  {label: 'Маржинальность', value: ITEM_FEE_CONDITIONS.MARGIN},
  // {label: 'При расходах', value: ITEM_FEE_CONDITIONS.EXPENDITURE},
  {label: 'Дата и время (по МСК)', value: ITEM_FEE_CONDITIONS.TIME},
  {label: 'При акции', value: ITEM_FEE_CONDITIONS.PROMO},
];

export const ITEM_FEE_CONDITIONS_SELECT_OPTIONS = [
  {label: 'При остатках товара', value: ITEM_FEE_CONDITIONS.STORAGE_COUNT},
  {label: 'При маржинальности', value: ITEM_FEE_CONDITIONS.MARGIN},
];

export const getSelectLabelByConditionType = (conditionType: ITEM_FEE_CONDITIONS) => {
  switch (conditionType) {
    case ITEM_FEE_CONDITIONS.MARGIN:
      return 'По остаткам товара';
    case ITEM_FEE_CONDITIONS.STORAGE_COUNT:
      return 'По маржинальности';
    default:
      return null;
  }
};
