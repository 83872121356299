export const DiamondIcon = ({size = '24px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 147 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.1828 3.06055C44.063 3.06055 39.2819 5.61928 36.442 9.8792L5.03628 56.9877C3.11362 59.8717 2.88369 63.5648 4.43379 66.665C21.6197 101.037 45.5033 131.626 74.6807 156.636L89.9995 169.766C94.0278 173.219 99.972 173.219 104 169.766L119.319 156.636C148.496 131.626 172.38 101.037 189.566 66.665C191.116 63.5648 190.886 59.8717 188.963 56.9877L157.558 9.8792C154.718 5.61928 149.937 3.06055 144.817 3.06055H49.1828ZM47.3626 17.1596C47.7683 16.5511 48.4514 16.1855 49.1828 16.1855H69.6561L52.8988 56.403C52.5128 57.3295 52.2215 58.2849 52.0251 59.2551C45.8666 58.7968 39.7133 58.2441 33.5674 57.5972L21.2674 56.3024L47.3626 17.1596ZM20.6881 69.439C36.3804 98.2262 57.1017 123.998 81.8905 145.521L54.5427 72.5939C47.0847 72.0812 39.6338 71.4333 32.1934 70.6501L20.6881 69.439ZM68.867 73.412L96.9999 148.433L125.133 73.412C106.387 74.2647 87.6126 74.2647 68.867 73.412ZM139.457 72.5939L112.109 145.521C136.898 123.998 157.619 98.2262 173.312 69.439L161.806 70.6501C154.366 71.4333 146.915 72.0812 139.457 72.5939ZM172.732 56.3024L160.432 57.5972C154.286 58.2441 148.133 58.7968 141.975 59.2551C141.778 58.2849 141.487 57.3295 141.101 56.403L124.344 16.1855H144.817C145.548 16.1855 146.231 16.5511 146.637 17.1596L172.732 56.3024ZM128.427 60.111C107.49 61.1984 86.51 61.1984 65.5726 60.111L83.8748 16.1855H110.125L128.427 60.111Z"
        fill={color}
        stroke={color}
        strokeWidth="0"
      />
    </svg>
  );
};
