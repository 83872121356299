export function makeColorDarker(hexColor: string, amount: number) {
  const color = hexColor.replace('#', '');

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const newR = Math.max(0, r - amount);
  const newG = Math.max(0, g - amount);
  const newB = Math.max(0, b - amount);

  const newColor = `#${newR.toString(16)}${newG.toString(16)}${newB.toString(16)}`;

  return newColor;
}

export function decreaseHexOpacity(hexColor: string, opacity: number) {
  const color = hexColor.replace('#', '');

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${Math.max(0, 1 - opacity)})`;
}
