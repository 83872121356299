import {flow, types} from 'mobx-state-tree';
import api from '@shared/configs/axiosConfig';

import {handlingConsoleRequestError} from '@shared/utils/handlingConsoleRequestError';
import {TGetItemFilters, TGroupCreatePayload, TGroupUpdatePayload} from './types';

import {TMpAccountItem} from '../market-place-account-item/types';

export const GroupOnList = types.model('GroupOnList', {
  id: types.identifierNumber,
  name: types.string,
  countSku: types.number,
  picture: types.maybeNull(types.string),
});

export const ProductGroupsStore = types
  .model('ProductGroupsStore', {
    list: types.array(GroupOnList),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    getGroupList: flow(function* (
      marketPlaceAccountId: number,
      {isHiddenLoad}: {isHiddenLoad: boolean | undefined} = {isHiddenLoad: false}
    ) {
      try {
        self.isLoading = isHiddenLoad ? false : true;
        const list = yield api
          .get(`${import.meta.env.WEB_API_BASE_URL}/groups/list`, {params: {marketPlaceAccountId}})
          .then((r) => r.data);
        self.list = list;
      } catch (e) {
        handlingConsoleRequestError(e);
      } finally {
        self.isLoading = false;
      }
    }),
    deleteGroup: flow(function* (groupId: number) {
      try {
        yield api.delete(`${import.meta.env.WEB_API_BASE_URL}/groups/${groupId}`).then((r) => r.data);
        const findIndex = self.list.findIndex((item) => item.id === groupId);
        if (findIndex !== -1) {
          self.list.splice(findIndex, 1);
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getGroupInfo: flow(function* (groupId: number) {
      try {
        const groupData = yield api.get(`${import.meta.env.WEB_API_BASE_URL}/groups/${groupId}`).then((r) => r.data);
        return groupData;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    createGroup: flow(function* (marketPlaceAccountId: number, group: TGroupCreatePayload) {
      try {
        yield api.post(`${import.meta.env.WEB_API_BASE_URL}/groups/create`, {
          marketPlaceAccountId,
          name: group.name,
          items: group.items,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getProductsByFilters: flow(function* (mpAccountId: number, offset: number, filters: TGetItemFilters) {
      const {vendorsIds, categoriesIds, shopsIds, search, groupsIds} = filters;

      const itemsData = yield api.post(`item/list`, {
        pagination: {
          offset,
          limit: 100,
        },
        filters: {
          categoryIds: categoriesIds && categoriesIds?.length > 0 ? categoriesIds : undefined,
          groupIds: groupsIds && groupsIds?.length > 0 ? groupsIds : undefined,
          shopIds: shopsIds && shopsIds?.length > 0 ? shopsIds : undefined,
          vendorIds: vendorsIds && vendorsIds?.length > 0 ? vendorsIds : undefined,
          search: search && search?.length > 0 ? search : undefined,
        },
        marketPlaceAccountId: mpAccountId,
      });
      const {data} = itemsData;
      return {
        list: data.items.map((item: TMpAccountItem) => ({
          id: item.id,
          name: item.name,
          sku: item.sku,
          vendor: item.vendor,
          category: item.category,
          picture: item.picture,
        })),
        count: data.count,
        offset: offset,
      };
    }),
    updateGroup: flow(function* (group: TGroupUpdatePayload) {
      try {
        yield api.patch(`${import.meta.env.WEB_API_BASE_URL}/groups/${group.id}`, {
          name: group.name,
          items: group.items,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
  }))
  .actions(() => ({}));
