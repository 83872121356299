import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    width: '100%',
    backgroundColor: COLORS.PURPLE_20,
    height: 74,
    paddingLeft: 46,
    paddingRight: 46,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
  },
  buttonContainer: {
    marginLeft: 'auto',
  },
}));
