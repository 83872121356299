export const AuthIcon = ({size = '24px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M12 1H15.5C18.5577 1 18.5 5 18.5 9C18.5 13 18.5577 17 15.5 17H12M13 9H1M13 9L9 13M13 9L9 5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
