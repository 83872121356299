import {TFilters} from '@src/stores/market-place-account-item/types';

export const mappedFilters = (
  filterArray: {name: string; id: number; marketShopId?: number; placementType?: string}[],
  filterType: TFilters
) => {
  switch (filterType) {
    case 'vendors':
      return filterArray.map((item) => {
        return {
          label: item.name || 'Без бренда',
          value: item.id.toString(),
        };
      });
    case 'shops':
      return filterArray.map((item) => {
        return {
          label: `${item.name} ${item.placementType ? ` / (${item.placementType})` : ''}`,
          value: item.id.toString(),
        };
      });
    case 'categories':
      return filterArray.map((item) => {
        return {
          label: item.name || 'Без категории',
          value: item.id.toString(),
        };
      });
    case 'groups':
      return filterArray.map((item) => {
        return {
          label: item.name || 'Без группы',
          value: item.id.toString(),
        };
      });
  }
  return;
};
