import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    marginBottom: 15,
  },
  closeButton: {
    cursor: 'pointer',
    color: COLORS.RED_100,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  actionButtonBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  contentContainer: {
    maxHeight: '75vh',
    overflow: 'auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
});
