export const DownloadIcon = ({size = '24px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M15 9.66797C16.45 9.67604 17.2353 9.74034 17.7475 10.2526C18.3333 10.8384 18.3333 11.7812 18.3333 13.6668V14.3335C18.3333 16.2191 18.3333 17.1619 17.7475 17.7477C17.1617 18.3335 16.2189 18.3335 14.3333 18.3335H9C7.11438 18.3335 6.17157 18.3335 5.58579 17.7477C5 17.1619 5 16.2191 5 14.3335V13.6668C5 11.7812 5 10.8384 5.58579 10.2526C6.09804 9.74034 6.88331 9.67604 8.33333 9.66797"
        stroke="#9E6CF7"
        strokeLinecap="round"
      />
      <path
        d="M11.667 5V13.6667M11.667 13.6667L9.66699 11.3333M11.667 13.6667L13.667 11.3333"
        stroke="#797979"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
