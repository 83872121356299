import {ChangeEventHandler, useEffect, useState} from 'react';
import {type FC} from 'react';

import {COLORS} from '@src/shared/const/appPalette';

import {useEmotionWrapper} from './toggle.s';

export type TStatus = 'default' | 'error' | 'warning';

interface IToggle {
  size?: 'small' | 'large';
  initialChecked: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  color?: string;
  status?: TStatus;
}

const closeIcon = (
  <svg width={12} height={12} fill="none" viewBox="0 0 12 12">
    <path
      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

const checkedIcon = (
  <svg fill="currentColor" viewBox="0 0 12 12">
    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"></path>
  </svg>
);

export const Toggle: FC<IToggle> = ({
  size = 'small',
  disabled = false,
  initialChecked,
  onChange,
  color = COLORS.PURPLE_100,
  status = 'default',
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(initialChecked);
  const {classes} = useEmotionWrapper({
    isChecked: isChecked,
    size,
    disabled,
    isLoading: false,
    color: color,
    status: status,
  });

  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);

  const handleToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsLoading(true);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 300);
    if (!disabled) {
      setIsChecked(event.target.checked);
      if (onChange) {
        onChange(event);
      }
    }
  };

  const toggleIcon = <div className={classes.toggleIcon}>{isChecked ? checkedIcon : closeIcon}</div>;

  const renderIcon = () => {
    // if (isLoading) {
    //   return <span className={classes.spinner} />;
    // }
    return toggleIcon;
  };

  return (
    <label className={classes.root}>
      <input disabled={disabled} type="checkbox" checked={isChecked} onChange={handleToggle} />
      <div className={classes.toggle}>
        <div className={classes.iconLoading}>{renderIcon()}</div>
      </div>
    </label>
  );
};
