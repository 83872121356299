import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {Modal} from '@src/components/common/modal';
import {IS_VIEWED_LAST_CHANGE_LOG_VERSION} from '@src/shared/const/localStorage';
import {useStore} from '@src/stores';
import {LOGS_TYPES} from './consts';
import {TLogType} from './types';

import {LogItem} from './log-item/log-item';
import packageJson from '../../../../../package.json';

const logs: {
  date: string;
  items: TLogType[];
}[] = [
  {
    date: '11.09.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content: 'Теперь будем уведомлять обо всех улучшениях и обновлениях сервиса',
      },

      {
        type: LOGS_TYPES.feature,
        content: 'Стратегии для Мониторинга цен - "Быть на позиции" и "Лучшая цена"',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Стратегия "Выключено" для Мониторинга цен, чтобы получать данные о конкурентах без автостратегий',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Кнопка “Удалить все”, чтобы удалить товары из Мониторинга цен за один раз',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'График изменения цены на товар',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'В Аналитику вернули обновленные Метрики просмотров и добавлений в корзину',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Новые настройки стратегий в Бусте продаж, стало удобнее и понятнее. Дополнительные условия работы стратегии теперь легче настраивать',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Улучшили информацию о ценах для товаров добавленных в Мониторинг цен: теперь помимо рекомендаций Маркета мы выводим конкурентов, их цены, рейтинг и позицию',
      },
    ],
  },
];

export const ChangelogModal = observer(() => {
  const {isOpen: isOpenTour} = useTour();
  const {user} = useStore();

  const [isOpen, setIsOpen] = useState(false);

  const currentAppVersion = packageJson.version;
  const appVersionOnLocalStorage = localStorage.getItem(IS_VIEWED_LAST_CHANGE_LOG_VERSION);

  useEffect(() => {
    let intervalId = null;
    if (appVersionOnLocalStorage !== currentAppVersion && user.isAuth && !isOpenTour) {
      intervalId = setTimeout(() => setIsOpen(true), 6000);
    }
    if (isOpenTour) {
      setIsOpen(false);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isOpenTour, user.isAuth]);

  const onClose = () => {
    setIsOpen(false);
    localStorage.setItem(IS_VIEWED_LAST_CHANGE_LOG_VERSION, currentAppVersion);
  };

  return (
    <Modal isCloseOnClickOverlay={false} title="История обновлений" opened={isOpen} onClose={onClose}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 35, padding: 1}}>
        {logs.map((log) => (
          <LogItem key={log.date} date={log.date} items={log.items} />
        ))}
      </div>
    </Modal>
  );
});
