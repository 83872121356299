export const DropdownFilledIcon = ({size = '11px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M5.88399 6.53955C5.6841 6.77924 5.3159 6.77924 5.11601 6.53955L0.554868 1.07023C0.283309 0.744603 0.514857 0.25 0.938861 0.25L10.0611 0.25C10.4851 0.25 10.7167 0.744602 10.4451 1.07023L5.88399 6.53955Z"
        fill={color}
      />
    </svg>
  );
};
