import {FC, useEffect, useState} from 'react';

import {Button, CustomSelect} from '@src/components';
import {ITEMS_ON_PAGE_OPTIONS} from '@src/pages/products/const/items-on-page-options';
import {COLORS} from '@src/shared/const/appPalette';
import {useStore} from '@src/stores';

import {ChevronIcon} from '../icons';
import {Input} from '../input';
import {Typography} from '../typography';

import {useEmotionWrapper} from './table-pagination.s';

interface ITablePagination {
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
  onLimitChange: (limit: number) => void;
}

export const TablePagination: FC<ITablePagination> = ({
  totalItems,
  itemsPerPage,
  onPageChange,
  onLimitChange,
}: ITablePagination) => {
  const {classes} = useEmotionWrapper();
  const {priceMonitoring} = useStore();
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState<number | string>(1);

  const handlePageChange = (page: number | string) => {
    setCurrentPage(page);
    onPageChange(Number(page));
  };

  useEffect(() => {
    if (
      priceMonitoring.pageParams.count !== undefined &&
      priceMonitoring.pageParams.offset > priceMonitoring.pageParams.count
    ) {
      handlePageChange(1);
    }
  }, [priceMonitoring.pageParams.count]);

  return (
    <div className={classes.root}>
      <Typography whiteSpace="nowrap">Товаров на странице:</Typography>
      <CustomSelect
        height={32}
        menuPlacement="top"
        isSearchable={false}
        value={{label: itemsPerPage.toString(), value: itemsPerPage}}
        options={ITEMS_ON_PAGE_OPTIONS}
        width={80}
        onChange={(value) => onLimitChange(value?.value || 1)}
      />
      <Button
        onClick={() => handlePageChange(Number(currentPage) - 1)}
        disabled={Number(currentPage) <= 1}
        color={COLORS.GRAY_100}
        variant="contained"
        icon={<ChevronIcon color={COLORS.DARK_100} style={{transform: 'rotate(180deg)'}} />}
      />
      <Typography>Страница</Typography>
      <div style={{width: 52}} className={classes.input}>
        <Input height={26} onChange={(e) => handlePageChange(e.target.value)} value={currentPage} width={52} />
      </div>
      <Typography size={12} weight="bold" whiteSpace="nowrap">
        из {totalPages}
      </Typography>
      <Button
        onClick={() => handlePageChange(Number(currentPage) + 1)}
        disabled={Number(currentPage) >= totalPages}
        color={COLORS.GRAY_100}
        variant="contained"
        icon={<ChevronIcon color={COLORS.DARK_100} />}
      />
    </div>
  );
};
