import {emotionWrapper, keyframes} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';

const animationSelected = keyframes({
  '0%': {
    width: 0,
    opacity: 0,
  },
  '100%': {
    width: '100%',
    opacity: 1,
  },
});

export const useEmotionWrapper = emotionWrapper(() => ({
  container: {
    padding: '30px 10px',
    borderTop: `1px solid ${COLORS.BLACK_6}`,
  },
  tab: {
    padding: '5px 10px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    borderRadius: '3px 3px 0 0',
    cursor: 'pointer',
    transition: 'color .3s ease',
    color: COLORS.BLACK_50,
    '&:hover': {
      color: COLORS.PURPLE_100,
      transition: 'color .3s ease',
    },
  },
  selected: {
    position: 'relative',
    color: `${COLORS.PURPLE_100} !important`,
    fontWeight: 500,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: -3,
      left: 0,
      width: '100%',
      height: '3px',
      backgroundColor: COLORS.PURPLE_100,
      animation: `0.3s ease ${animationSelected}`,
    },
  },
}));
