import {type FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Button, CustomSelect, FaqBlock, IconButton, Input, Typography} from '@src/components';
import {CheckedIcon, RemoveIcon} from '@src/components/common/icons';
import {COLORS} from '@src/shared/const/appPalette';
import {PRODUCT_LINKS_TYPE} from '@src/shared/enums/product-links';
import {useStore} from '@src/stores';

import {PRODUCT_SEARCH_BASE_URL} from '../../consts';
import {TProductOtherLink, TUpdateItemsMethod} from '../../types';

import {useEmotionWrapper} from './create-and-edit-links-block.s';

interface ICreateAndEditLinksBlock {
  productId: number;
  currentOtherLink: TProductOtherLink | null;
  onToggleEditMode: () => void;
  loadOtherLinks: TUpdateItemsMethod;
}

const options = [
  {value: PRODUCT_LINKS_TYPE.SEARCH, label: 'Поиск'},
  {value: PRODUCT_LINKS_TYPE.CUSTOM, label: 'Переход по ссылке'},
];

export const CreateAndEditLinksBlock: FC<ICreateAndEditLinksBlock> = observer(
  ({productId, currentOtherLink, onToggleEditMode, loadOtherLinks}) => {
    const {mpItems, mpAccounts} = useStore();
    const {classes} = useEmotionWrapper();
    const [linkData, setLinkData] = useState<{
      type: PRODUCT_LINKS_TYPE | undefined;
      name: string;
      link: string;
    }>({
      type: undefined,
      name: '',
      link: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const marketPlaceAccountId = mpAccounts.current?.id;

    const onCreateAndUpdateLink = async () => {
      const newLink =
        linkData.type === PRODUCT_LINKS_TYPE.SEARCH ? PRODUCT_SEARCH_BASE_URL + linkData.link : linkData.link;
      setIsLoading(true);

      try {
        if (currentOtherLink?.id) {
          await mpItems.updateItemLink({
            id: currentOtherLink?.id,
            link: newLink,
            name: linkData.name,
            type: linkData.type as PRODUCT_LINKS_TYPE,
          });
        } else {
          await mpItems.createItemLink(productId, {
            ...linkData,
            link: newLink,
            type: linkData.type as PRODUCT_LINKS_TYPE,
          });
        }
        if (marketPlaceAccountId) {
          await loadOtherLinks();
        }
      } catch (error) {
        console.error(error);
      } finally {
        onToggleEditMode();
        setIsLoading(false);
      }
    };

    const onDeleteLink = () => {
      if (currentOtherLink?.id) {
        setIsLoading(true);
        mpItems
          .deleteItemLink(currentOtherLink?.id)
          .then(() => {
            if (marketPlaceAccountId) {
              loadOtherLinks();
            }
          })
          .finally(() => {
            onToggleEditMode();
            setIsLoading(false);
          });
      }
    };

    useEffect(() => {
      if (currentOtherLink?.id) {
        setLinkData({...currentOtherLink});
      }
    }, [currentOtherLink]);

    return (
      <div className={classes.root}>
        <div className={classes.faqBlock}>
          <CustomSelect
            isUsePortal={false}
            isSearchable={false}
            menuPlacement="auto"
            placeholder="Выберите тип ссылки"
            options={options}
            value={options.find((option) => option.value === linkData.type)}
            width={215}
            onChange={(e) => {
              setLinkData({...linkData, type: e?.value});
            }}
          />
          <FaqBlock
            content={
              <Typography>
                <Typography weight="bold">Переход по ссылке</Typography> - введите или вставьте адрес ссылки, на который
                хотите переходить. <br />{' '}
                <Typography weight={300} italic>
                  Пример:
                </Typography>{' '}
                карточка конкурента с похожим товаром.
                <Typography>
                  <br />
                  <br />
                  <Typography weight="bold">Поисковый запрос</Typography> - введите поисковый запрос на Я.Маркете,
                  который автоматически будет открываться по клику на кнопку. <br />
                  <Typography weight={300} italic>
                    Пример:
                  </Typography>{' '}
                  пеленка детская 80х120.
                </Typography>
              </Typography>
            }
          />
        </div>
        {linkData.type && (
          <>
            <Input
              value={linkData.name}
              placeholder="Введите название"
              onChange={(e) => setLinkData({...linkData, name: e.target.value})}
            />
            <Input
              value={linkData.link}
              placeholder={
                linkData.type === PRODUCT_LINKS_TYPE.SEARCH ? 'Введите поисковой запрос' : 'Введите адрес ссылки'
              }
              onChange={(e) => setLinkData({...linkData, link: e.target.value})}
            />
            <div className={classes.actionsBlock}>
              {currentOtherLink?.id && (
                <IconButton onClick={onDeleteLink} disabled={isLoading}>
                  <RemoveIcon />
                </IconButton>
              )}
              <Button
                disabled={!linkData.name || !linkData.link || !linkData.type || isLoading}
                style={{width: 'inherit'}}
                loading={isLoading}
                onClick={onCreateAndUpdateLink}
                variant="outlined"
                color={COLORS.GREEN_100}
                icon={<CheckedIcon />}
              >
                {currentOtherLink?.id ? 'Сохранить' : 'Создать'}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
);
