import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {CURRENT_MP_ACCOUNT} from '@shared/const/localStorage';

import {useStore} from '../../../stores';
import {Dropdown} from '../../common/dropdown/dropdown';

export const DropdownAccounts = observer(() => {
  const {mpAccounts, mpItems} = useStore();
  const currentAccountOnLocalStorage = localStorage.getItem(CURRENT_MP_ACCOUNT);

  useEffect(() => {
    if (currentAccountOnLocalStorage && mpAccounts.items.length > 0) {
      if (mpAccounts.items.find((item) => item.id === Number(currentAccountOnLocalStorage))) {
        mpAccounts.setActiveAccount(Number(currentAccountOnLocalStorage));
      }
    } else {
      mpAccounts.items.length > 0 && mpAccounts.setActiveAccount(mpAccounts.items[0].id);
    }
  }, [mpAccounts.count]);

  const mappedData = () => {
    return mpAccounts.items.map((shop) => {
      return {
        title: shop.name,
        value: shop.id,
      };
    });
  };

  return (
    <Dropdown
      currentItemId={mpAccounts.current?.id}
      items={mappedData()}
      onClick={(shop) => {
        // FIXME: Resetting the individual strategy in filters. For correct processing of filter state when redirecting from the rules page with preselected filter "Individual settings"
        mpItems.selectHaveIndividualStrategy(false);
        mpAccounts.setActiveAccount(Number(shop.value));
      }}
    />
  );
});
