import {type FC, useEffect, useState} from 'react';

import {IconButton} from '@src/components/common/buttons/icon-button';
import {CloseIcon} from '@src/components/common/icons';

import {useEmotionWrapper} from './info-block.s';

interface IInfoBlock {
  content: JSX.Element | string;
  icon: JSX.Element;
  tagInLocalStorage: string;
}
export const InfoBlock: FC<IInfoBlock> = ({content, icon, tagInLocalStorage}) => {
  const {classes} = useEmotionWrapper();
  const [isClosed, setIsClosed] = useState(1);

  useEffect(() => {
    const state = localStorage.getItem(tagInLocalStorage);
    if (state === '1') {
      setIsClosed(1);
    } else {
      setIsClosed(0);
    }
  }, [tagInLocalStorage]);

  const onClickClose = () => {
    setIsClosed(1);
    localStorage.setItem(tagInLocalStorage, '1');
  };

  if (isClosed) return null;

  return (
    <div className={classes.root}>
      <div className={classes.contentBlock}>
        <div>{icon}</div>
        {content}
      </div>
      <IconButton onClick={onClickClose} color="error" size={14}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};
