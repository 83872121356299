export const ConsultationIcon = ({size = '28px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M18.0181 24.2898C16.7546 24.8138 15.3949 25.0869 13.9988 25.0869C8.19979 25.0869 3.49878 20.3859 3.49878 14.5869C3.49878 8.78792 8.19979 4.08691 13.9988 4.08691C19.7978 4.08691 24.4988 8.78792 24.4988 14.5869C24.4988 15.983 24.2256 17.3428 23.7017 18.6063C23.5052 19.0802 23.2742 19.5386 23.0109 19.9778L24.4339 23.7325C24.7393 24.5384 23.9503 25.3274 23.1444 25.022L19.3897 23.599C18.9504 23.8623 18.4921 24.0933 18.0181 24.2898ZM20.9637 20.2195C20.849 19.9169 20.8878 19.5776 21.0678 19.3088C21.3774 18.8463 21.641 18.3545 21.8542 17.8402C22.2779 16.8185 22.4988 15.719 22.4988 14.5869C22.4988 9.89249 18.6932 6.08691 13.9988 6.08691C9.30436 6.08691 5.49878 9.89249 5.49878 14.5869C5.49878 19.2813 9.30436 23.0869 13.9988 23.0869C15.1308 23.0869 16.2303 22.866 17.2521 22.4424C17.7664 22.2291 18.2582 21.9655 18.7206 21.6559C18.9895 21.476 19.3288 21.4372 19.6313 21.5518L21.7768 22.3649L20.9637 20.2195ZM8.99878 14.5869C8.99878 14.0346 9.44649 13.5869 9.99878 13.5869C10.5511 13.5869 10.9988 14.0346 10.9988 14.5869C10.9988 15.1392 10.5511 15.5869 9.99878 15.5869C9.44649 15.5869 8.99878 15.1392 8.99878 14.5869ZM12.9988 14.5869C12.9988 14.0346 13.4465 13.5869 13.9988 13.5869C14.5511 13.5869 14.9988 14.0346 14.9988 14.5869C14.9988 15.1392 14.5511 15.5869 13.9988 15.5869C13.4465 15.5869 12.9988 15.1392 12.9988 14.5869ZM16.9988 14.5869C16.9988 14.0346 17.4465 13.5869 17.9988 13.5869C18.5511 13.5869 18.9988 14.0346 18.9988 14.5869C18.9988 15.1392 18.5511 15.5869 17.9988 15.5869C17.4465 15.5869 16.9988 15.1392 16.9988 14.5869Z"
        fill={color}
      />
    </svg>
  );
};
