export const SortAscIcon = ({size = '16px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60938 11.375C3.73041 11.4998 3.89695 11.5703 4.07086 11.5703C4.24478 11.5703 4.41132 11.4998 4.53235 11.375L7.96092 7.83925C8.20812 7.58437 8.20186 7.17735 7.94695 6.93024C7.69203 6.68304 7.28506 6.68929 7.03795 6.94421L4.71372 9.34104L4.71372 0.641728C4.71372 0.2867 4.42589 -0.00112915 4.07086 -0.00112915C3.71584 -0.00112915 3.42801 0.2867 3.42801 0.641728L3.42801 9.34104L1.10378 6.94421C0.856667 6.68929 0.449693 6.68304 0.194778 6.93024C-0.0601356 7.17735 -0.0663908 7.58437 0.180809 7.83925L3.60938 11.375Z"
        fill="#9E6CF7"
      />
    </svg>
  );
};
