export const TelegramBlackIcon = ({size = '14', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <rect width="14" height="14" rx="7" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16908 6.92598C5.20972 6.0369 6.57047 5.45077 7.25133 5.16758C9.1953 4.35901 9.59924 4.21855 9.86252 4.21392C9.92043 4.2129 10.0499 4.22725 10.1338 4.2953C10.2046 4.35276 10.2241 4.43039 10.2334 4.48487C10.2427 4.53935 10.2543 4.66346 10.2451 4.76044C10.1398 5.8673 9.68394 8.55337 9.45204 9.79308C9.35391 10.3176 9.1607 10.4935 8.97365 10.5107C8.56715 10.5482 8.25847 10.2421 7.86475 9.98401C7.24866 9.58016 6.90061 9.32876 6.30259 8.93467C5.61147 8.47923 6.05949 8.22891 6.45336 7.81983C6.55643 7.71277 8.3475 6.08366 8.38216 5.93587C8.3865 5.91739 8.39052 5.84849 8.34959 5.81211C8.30866 5.77573 8.24825 5.78817 8.20466 5.79807C8.14287 5.81209 7.15865 6.46262 5.25202 7.74965C4.97265 7.94149 4.71961 8.03496 4.4929 8.03006C4.24296 8.02466 3.76218 7.88874 3.40477 7.77256C2.9664 7.63006 2.61799 7.55472 2.64833 7.31272C2.66413 7.18666 2.83771 7.05775 3.16908 6.92598Z"
        fill="white"
      />
    </svg>
  );
};
