export const ProductIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M15 23.0279L21.8944 19.5807C22.572 19.2419 23 18.5493 23 17.7918V9.5C23 9.42477 22.9958 9.35019 22.9875 9.27652L15 13.2643V23.0279ZM13 23.0279V13.2627L5.01534 9.25244C5.00519 9.33392 5 9.41656 5 9.5V17.7918C5 18.5493 5.428 19.2419 6.10557 19.5807L13 23.0279ZM21.77 7.64895L14.8944 4.21115C14.3314 3.92962 13.6686 3.92962 13.1056 4.21115L6.25445 7.63671L14.0013 11.5275L21.77 7.64895ZM15.7889 2.42229L22.7889 5.92229C24.144 6.59986 25 7.98491 25 9.5V17.7918C25 19.3069 24.144 20.6919 22.7889 21.3695L15.7889 24.8695C14.6627 25.4326 13.3373 25.4326 12.2111 24.8695L5.21115 21.3695C3.85601 20.6919 3 19.3069 3 17.7918V9.5C3 7.98491 3.85601 6.59986 5.21115 5.92229L12.2111 2.42229C13.3373 1.85924 14.6627 1.85924 15.7889 2.42229Z"
        fill={color}
      />
    </svg>
  );
};
