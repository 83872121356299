import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {COLORS} from '@shared/const/appPalette';
import {TARIFFS_TYPES} from '@shared/enums/tariffs';
import {ChevronIcon} from '@src/components/common/icons';
import {getRoutePricing} from '@src/shared/const/router';

import {useCurrentTariff} from '../../../hooks/useCurrenTariff';
import {Button, Notifications, Typography} from '../..';

import {useEmotionWrapper} from './price-monitoring-notify.s';

export const PriceMonitoringNotify = observer(() => {
  const [isView, setIsView] = useState(false);
  const location = useLocation();
  const {classes} = useEmotionWrapper();
  const {currentTariff} = useCurrentTariff();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === '/price-monitoring' && currentTariff.type === TARIFFS_TYPES.START) {
      setIsView(true);
    } else {
      setIsView(false);
    }
  }, [location.pathname, currentTariff.type]);

  return isView ? (
    <Notifications>
      <div className={classes.root}>
        <Typography weight="bold" color={COLORS.DARK_100} size={16}>
          Функция мониторинга цен доступна только в рамках платных тарифов.
          <br />
          Чтобы использовать этот функционал, пожалуйста, перейдите на один из наших платных тарифных планов!
        </Typography>
        <Button onClick={() => navigate(getRoutePricing())}>
          <div className={classes.button}>
            Выбрать тариф
            <ChevronIcon />
          </div>
        </Button>
      </div>
    </Notifications>
  ) : null;
});
