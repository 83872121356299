import React from 'react';

import {StyledSkeletonElementProps, useEmotionWrapper} from './skeleton-element.s';

export interface SharedSkeletonElementProps extends StyledSkeletonElementProps {}

export type SkeletonElementProps = SharedSkeletonElementProps &
  Omit<React.ComponentPropsWithoutRef<'span'>, keyof SharedSkeletonElementProps>;

export const SkeletonElement = ({
  width,
  height,
  background,
  highlightColor,
  radius,
  ...others
}: SkeletonElementProps) => {
  const {classes} = useEmotionWrapper({width, height, background, highlightColor, radius});

  return (
    <span className={classes.root} {...others}>
      &zwnj;
    </span>
  );
};
