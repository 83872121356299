import {type FC, ReactNode} from 'react';

import {COLORS} from '@shared/const/appPalette';

import {Loader} from '../../..';

import {useEmotionWrapper} from './button.s';

interface IButton {
  children?: string | ReactNode;
  variant?: 'outlined' | 'contained' | 'text' | 'icon';
  icon?: JSX.Element;
  loading?: boolean;
  disabled?: boolean;
  color?: string;
  size?: 'large' | 'medium' | 'small' | number;
}

type IButtonProps = IButton & Omit<React.ComponentPropsWithoutRef<'button'>, keyof IButton>;

export const Button: FC<IButtonProps> = ({
  variant = 'contained',
  loading = false,
  disabled = false,
  color = COLORS.PURPLE_100,
  size = 'medium',
  children,
  icon,
  ...others
}) => {
  const {classes} = useEmotionWrapper({variant, color, size});

  return (
    <button disabled={disabled || loading} className={classes.root} {...others}>
      {loading && <Loader />}
      {icon && !loading && icon}
      {children}
    </button>
  );
};
