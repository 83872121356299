export const EditItemIcon = ({size = '23px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M14.4002 3.19922H6.2002C4.54334 3.19922 3.2002 4.54237 3.2002 6.19922V17.7992C3.2002 19.4561 4.54334 20.7992 6.2002 20.7992H11.6802H17.9602C19.617 20.7992 20.9602 19.4561 20.9602 17.7992V9.59922"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M17.5377 4.13046L17.9529 3.71522C18.6409 3.02722 19.7564 3.02722 20.4444 3.71522C21.1324 4.40321 21.1324 5.51867 20.4444 6.20667L20.0292 6.62191M17.5377 4.13046C17.5377 4.13046 17.5896 5.01285 18.3682 5.79143C19.1468 6.57 20.0292 6.62191 20.0292 6.62191M17.5377 4.13046L12.1202 9.54797C11.8616 9.80654 11.7323 9.93583 11.6211 10.0784C11.49 10.2465 11.3775 10.4285 11.2858 10.621C11.208 10.7842 11.1502 10.9576 11.0346 11.3045L10.5446 12.7745M20.0292 6.62191C18.6149 8.03615 15.6536 11.3121 14.0812 12.5385C13.9131 12.6696 13.7311 12.7821 13.5386 12.8738C13.3754 12.9516 13.202 13.0094 12.8551 13.125L11.3851 13.615M11.3851 13.615L11.0257 13.7348C10.855 13.7917 10.6668 13.7473 10.5396 13.6201C10.4123 13.4928 10.3679 13.3046 10.4248 13.1339L10.5446 12.7745M11.3851 13.615L10.5446 12.7745"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};
