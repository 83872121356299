import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper((color: string) => ({
  root: {
    width: '100%',
    backgroundColor: color,
    height: 62,
    paddingLeft: 46,
    paddingRight: 46,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));
