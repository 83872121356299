import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    position: 'absolute',
    top: 200,
    left: '45%',
    transform: 'translate(-50%, -50%)',
  },
  iframe: {
    width: '100%',
    border: 'none',
    height: 'calc(100vh - 135px)',
  },
}));
