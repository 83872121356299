import {keyframes} from '@emotion/react';
import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

interface ISidebarMenuStyle {
  isCompact: boolean;
}

const fadeIn = keyframes({
  from: {
    backgroundColor: COLORS.GRAY_100,
  },
  to: {
    backgroundColor: COLORS.BLACK_6,
  },
});

const fadeInOpacity = keyframes({
  '0%': {
    height: 0,
    left: 0,
    opacity: 0,
  },
  '50%': {height: 0},
  '100%': {
    right: 0,
    height: 24,
    opacity: 1,
  },
});

export const useEmotionWrapper = emotionWrapper(({isCompact}: ISidebarMenuStyle) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
    width: isCompact ? 44 : '80%',
    willChange: 'width',
    transition: 'width .3s ease',
    '@media (max-height: 750px)': {
      gap: 4,
    },
  },
  menuItemBlock: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isCompact ? 'center' : 'flex-start',
    gap: 7,
    overflow: 'hidden',
    cursor: 'pointer',
    padding: 5,
    height: 34,
    borderRadius: 7,
    color: COLORS.DARK_100,
    willChange: 'color, span',
    '&:hover': {
      color: COLORS.PURPLE_100,
      span: {
        color: COLORS.PURPLE_100,
        transition: 'color .3s ease',
      },
      transition: 'color .3s ease',
    },
  },
  menuItemText: {
    willChange: 'opacity, transform',
    position: 'absolute',
    overflow: 'hidden',
    textOverflow: 'hidden',
    left: 30,
    padding: 5,
    opacity: isCompact ? 0 : 1,
    transform: isCompact ? 'translateX(-40%)' : 'translateX(0)',
    transition: 'opacity .3s ease, transform .3s ease',
    display: 'flex',
    alignItems: 'center',
    span: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      cursor: 'pointer',
    },
  },
  isActiveMenuItem: {
    willChange: 'background-color',
    backgroundColor: COLORS.BLACK_6,
    animation: `${fadeIn} .5s ease forwards`,
    '&:after': {
      content: "''",
      position: 'absolute',
      right: 0,
      height: 22,
      width: 3,
      borderRadius: 7,
      backgroundColor: COLORS.PURPLE_100,
      animation: isCompact ? 'none' : `${fadeInOpacity} .5s ease forwards`,
    },
    '&:hover': {
      color: COLORS.DARK_100,
      div: {
        span: {
          color: COLORS.DARK_100,
        },
      },
    },
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  notifyContainer: {
    position: 'absolute',
    right: -8,
    top: -4,
  },
  divider: {
    borderBottom: `1px solid ${COLORS.BLACK_10}`,
    margin: '10px 0px',
    '@media (max-height: 650px)': {
      display: 'none',
    },
  },
}));
