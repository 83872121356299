import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    marginBottom: 12,
    width: 'calc(100% - 42px)',
    position: 'sticky',
    top: 0,
    backgroundColor: COLORS.WHITE,
    zIndex: 1,
    paddingTop: 23,
  },
  divider: {
    marginBottom: 12,
    marginTop: 12,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accountBlock: {
    display: 'flex',
    gap: 30,
    alignItems: 'center',
  },
  yandexMarketTitle: {
    backgroundColor: COLORS.GRAY_100,
    padding: 10,
    borderRadius: 7,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '@media (max-width: 1100px)': {
      display: 'none',
    },
  },
  accountDropdown: {display: 'flex', alignItems: 'center', gap: 5, paddingRight: 10},
  rightBlock: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    marginLeft: 'auto',
  },
  consultationBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  tariffBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  tariffTitle: {
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    gap: 5,
    cursor: 'pointer',
  },
  userName: {
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    gap: 7,
  },
  currentTariffBlock: {
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
  tariffText: {
    display: 'flex',
    flexDirection: 'column',

    gap: 2,
    span: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
        transition: '0.3s',
      },
    },
  },
  logoutIcon: {
    backgroundColor: COLORS.BLACK_6,
    padding: '7px 7px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));
