export const RemoveIcon = ({size = '24px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M2.13333 5.26667V18.1333C2.13333 18.6856 2.58105 19.1333 3.13333 19.1333H12.8667C13.419 19.1333 13.8667 18.6856 13.8667 18.1333V4.73333M13.8667 4.73333H16M13.8667 4.73333H11.2M0 4.73333H4.8M11.2 4.73333V2C11.2 1.44772 10.7523 1 10.2 1H5.8C5.24772 1 4.8 1.44772 4.8 2V4.73333M11.2 4.73333H4.8M6.4 8.46667V14.8667M9.6 8.46667V14.8667"
        stroke={color}
      />
    </svg>
  );
};
