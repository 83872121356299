import {type FC, ReactNode} from 'react';
import {Link as ReactRouterLink} from 'react-router-dom';

import {useEmotionWrapper} from './link.s';
interface ILink {
  href: string;
  children: ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export const Link: FC<ILink> = ({href, target = '_self', children}) => {
  const {classes} = useEmotionWrapper();

  return (
    <ReactRouterLink target={target} className={classes.root} to={href}>
      {children}
    </ReactRouterLink>
  );
};
