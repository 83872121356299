import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    backgroundColor: COLORS.PURPLE_100,
    width: 14,
    height: 14,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.WHITE,
    fontSize: 10,
    fontWeight: 'bold',
  },
}));
