import {emotionWrapper} from '@shared/lib';
import {decreaseHexOpacity, makeColorDarker} from '@shared/utils/styles/colorHelpers';

type TButtonVariant = 'outlined' | 'contained' | 'text' | 'icon';
type TGetVariants = (variant: 'outlined' | 'contained' | 'text' | 'icon', color: string) => object;
type TButtonSize = 'large' | 'medium' | 'small' | number;
interface IIconButton {
  variant: TButtonVariant;
  color: string;
  size: 'large' | 'medium' | 'small' | number;
}

const getSize = (size: TButtonSize) => {
  switch (size) {
    case 'small':
      return {height: 24, width: 24, padding: '0px 5px'};
    case 'medium':
      return {height: 32, padding: '0px 12px'};
    case 'large':
      return {height: 52, padding: '0px 12px'};
    default:
      return {height: size, padding: '0px 12px'};
  }
};

const getVariants: TGetVariants = (variant: TButtonVariant, color: string) => {
  switch (variant) {
    case 'contained':
      return {
        border: 'none',
        color: 'white',
        backgroundColor: color,
        borderRadius: 7,
        '&:enabled:hover': {
          backgroundColor: makeColorDarker(color, 10),
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
          transition:
            'box-shadow .25s, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        },
      };
    case 'outlined':
      return {
        background: 'none',
        border: `1px solid ${color}`,
        borderRadius: 7,
        color: color,
        '&:enabled:hover': {
          borderColor: makeColorDarker(color, 30),
          color: makeColorDarker(color, 30),
          backgroundColor: decreaseHexOpacity(color, 0.9),
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
          transition: 'box-shadow .25s, backgroundColor .3s',
        },
      };
    case 'text':
      return {
        background: 'none',
        color: color,
        position: 'relative',
        border: 'none',
        width: 'max-content',
        padding: 0,
        '&:enabled:hover': {
          boxShadow: 'none',
          color: makeColorDarker(color, 30),
          '&: after': {
            content: "''",
            position: 'absolute',
            left: 0,
            bottom: 7,
            height: 1,
            width: '100%',
            backgroundColor: makeColorDarker(color, 30),
          },
        },
      };
    case 'icon':
      return {
        border: 'none',
        background: 'none',
        width: 46,
        height: 46,
        borderRadius: 7,
        color: color,
        '&:enabled:hover': {
          color: makeColorDarker(color, 30),
          // backgroundColor: decreaseHexOpacity(color, 0.9),
          // boxShadow:
          //   'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
          transition: 'color .3s',
        },
      };
    default:
      return {};
  }
};

export const useEmotionWrapper = emotionWrapper(({variant = 'outlined', color, size}: IIconButton) => ({
  root: {
    ...getSize(size),
    ...getVariants(variant, color),
    outline: 'none',
    display: 'flex',
    fontSize: 13,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    span: {
      whiteSpace: 'nowrap',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.7,
    },
  },
}));
