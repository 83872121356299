export const CheckedIcon = ({size = '16px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M3.84375 8.59375L6.21875 10.9688L12.1562 5.03125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
