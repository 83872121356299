import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

interface IInputStyles {
  width?: string | number;
  isBordered: boolean;
  height: number | string;
  isError: boolean;
  disabled: boolean;
}

export const useEmotionWrapper = emotionWrapper(({width, isBordered, height, isError, disabled}: IInputStyles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: isError ? `1px solid ${COLORS.RED_100}` : isBordered ? `1px solid ${COLORS.DARK_20}` : 'none',
    color: COLORS.DARK_100,
    borderRadius: 7,
    padding: 3,
    margin: '0',
    background: 'none',
    width: width ? width : '100%',
    backgroundColor: COLORS.WHITE,
    height: height,
    '&:focused:not(hover)': {
      border: isBordered ? (disabled ? `1px solid ${COLORS.DARK_20}` : `1px solid ${COLORS.PURPLE_100}`) : 'none',
      borderColor: 'none',
      outline: 'none',
    },
    '&:hover:not(hover)': {
      border: isBordered ? (disabled ? `1px solid ${COLORS.DARK_20}` : `1px solid ${COLORS.PURPLE_100}`) : 'none',
      borderColor: 'none',
      outline: 'none',
    },
  },
  input: {
    height: 24,
    flexGrow: 1,
    width: `calc(100% - 35px)`,
    background: 'none',
    backgroundColor: 'none',
    border: 'none',
    outline: 'none',
    fontSize: 14,
    // '-moz-appearance': 'textfield' /* Firefox */,
    // '-webkit-appearance': 'none',
    // '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    //   '-webkit-appearance': 'none',
    //   margin: 0,
    // },
    '::placeholder': {
      color: COLORS.BLACK_20, // Replace with the desired color
      fontSize: 14,
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: COLORS.DARK_20,
    },
  },
  focused: {
    border: isBordered ? `1px solid ${COLORS.PURPLE_100}` : 'none',
    '&:focused': {
      border: `1px solid ${COLORS.PURPLE_100}`,
      borderColor: 'none',
      outline: 'none',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    width: 32,
  },
}));
