import {type FC} from 'react';

import {IconButton} from '@src/components/common/buttons/icon-button';
import {CustomSelect} from '@src/components/common/custom-select';
import {RemoveIcon} from '@src/components/common/icons';
import {Input} from '@src/components/common/input';
import {Typography} from '@src/components/common/typography';
import {useConfirmationDialog} from '@src/components/providers/confirm-dialog';
import {COLORS} from '@src/shared/const/appPalette';
import {ITEM_FEE_CONDITIONS, ITEM_FEE_CONDITIONS_TYPES} from '@src/shared/enums/fee-conditions';
import {declOfNum} from '@src/shared/utils/declOfNum';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';
import {TFeeConditionsModel} from '@src/stores/shared-models/fee-conditions-model';

const DAYS_OPTIONS = [
  {label: 'Понедельник', value: 0},
  {label: 'Вторник', value: 1},
  {label: 'Среда', value: 2},
  {label: 'Четверг', value: 3},
  {label: 'Пятница', value: 4},
  {label: 'Суббота', value: 5},
  {label: 'Воскресенье', value: 6},
];

interface ITimeCondition {
  condition: TFeeConditionsModel;
  onChangeCondition: (conditionId: number, condition: TFeeConditionsModel) => void;
  removeCondition: (conditionId: number) => void;
  hidePromoType: boolean;
}

export const TimeCondition: FC<ITimeCondition> = ({condition, onChangeCondition, removeCondition, hidePromoType}) => {
  const {getConfirmation} = useConfirmationDialog();

  const selectValue = (condition.schedule?.days || []).map((day) =>
    DAYS_OPTIONS.find((option) => option.value === day)
  );

  const selectPlaceholder = () => {
    if (condition.schedule?.days?.length === 0 || !condition.schedule?.days) {
      return 'Дни недели';
    } else if (condition.schedule?.days?.length === 7) {
      return <Typography color={COLORS.PURPLE_100}>Все дни недели</Typography>;
    } else {
      return (
        <Typography size={12} color={COLORS.PURPLE_100}>
          {condition.schedule?.days?.length && declOfNum(condition.schedule?.days?.length, ['день', 'дня', 'дней'])}
        </Typography>
      );
    }
  };

  const handleRemoveCondition = async () => {
    const confirm = await getConfirmation({
      title: 'Удалить условие?',
      message: <Typography>Вы уверены, что хотите удалить это условие?</Typography>,
    });
    if (confirm) {
      condition.id !== null && removeCondition(condition.id);
    }
  };

  return (
    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
      <CustomSelect
        width={185}
        isClearable={false}
        isSearchable={false}
        onChange={(e) => {
          condition.id && e?.value && onChangeCondition(condition.id, {...condition, type: e?.value});
        }}
        value={ITEM_FEE_CONDITIONS_TYPES.find((option) => option.value === condition.type)}
        options={
          hidePromoType
            ? ITEM_FEE_CONDITIONS_TYPES.filter((option) => option.value !== ITEM_FEE_CONDITIONS.PROMO)
            : ITEM_FEE_CONDITIONS_TYPES
        }
      />
      <CustomSelect
        isSearchable={false}
        width={150}
        isMulti
        isCheckboxOptions
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSelectedAllOptions
        isClearable={false}
        placeholder={selectPlaceholder()}
        options={DAYS_OPTIONS}
        isSelectedAllMessage="Все дни недели"
        value={selectValue.length ? selectValue : []}
        isSelectedOptions={selectValue?.length > 0}
        onChange={(value) => {
          condition.id &&
            onChangeCondition(condition.id, {
              ...condition,
              //@ts-ignore
              schedule: {...condition.schedule, days: value.map((item) => item && item.value)},
            });
        }}
      />
      <Typography>с</Typography>
      <Input
        width={85}
        onChange={(e) =>
          condition.id &&
          onChangeCondition(condition.id, {
            ...condition,
            //@ts-ignore
            schedule: {...condition.schedule, timeFrom: e.target.value},
          })
        }
        value={condition.schedule?.timeFrom}
        type="time"
      />
      <Typography>по</Typography>
      <Input
        width={85}
        onChange={(e) => {
          condition.id &&
            onChangeCondition(condition.id, {
              ...condition,
              //@ts-ignore
              schedule: {...condition.schedule, timeTo: e.target.value},
            });
        }}
        value={condition.schedule?.timeTo}
        type="time"
      />
      <Typography whiteSpace="nowrap">установить ДРР</Typography>
      <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
        <Input
          width={60}
          onChange={(e) =>
            condition.id &&
            onChangeCondition(condition.id, {
              ...condition,
              maxBidderFeePct: stringToNumberWithDot(e.target.value),
            })
          }
          value={condition.maxBidderFeePct}
        />
        <Typography>%</Typography>
      </div>
      <IconButton style={{marginLeft: 'auto'}} data-cy="remove-condition" onClick={handleRemoveCondition}>
        <RemoveIcon />
      </IconButton>
    </div>
  );
};
