import React from 'react';

import {COLORS} from '@src/shared/const/appPalette';

import {useEmotionWrapper} from './typography.s';

interface ITypography {
  children: string | React.ReactNode;
  color?: string;
  align?: 'left' | 'center' | 'right' | 'justify';
  size?: string | number;
  weight?: 'normal' | 'bold' | 'lighter' | 'bolder' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
  paragraph?: boolean;
  italic?: boolean;
  variant?: 'primary' | 'secondary' | 'titleSecondary';
  cursor?: 'pointer' | 'default' | 'not-allowed' | 'inherit' | 'text' | 'none' | 'auto';
  className?: string;
}
export type ITypographyProps = ITypography & Omit<React.ComponentPropsWithoutRef<'span'>, keyof ITypography>;

export const Typography: React.FC<ITypographyProps> = ({
  children,
  color = COLORS.BLACK_100,
  align,
  size,
  weight,
  paragraph,
  italic,
  className,
  whiteSpace = 'normal',
  cursor = 'default',
  variant = 'primary',
  ...others
}) => {
  const {classes, cx} = useEmotionWrapper({color, align, size, weight, paragraph, italic, variant, whiteSpace, cursor});

  return (
    <span className={cx(className ? className : '', classes.root)} {...others}>
      {children}
    </span>
  );
};
