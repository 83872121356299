import React from 'react';

import {IStyledLoader, useEmotionWrapper} from './loader.s';

export interface ISharedLoader extends IStyledLoader {
  className?: string | string[] | Record<string, boolean>;
}

export type TLoader = ISharedLoader & Omit<React.ComponentPropsWithoutRef<'span'>, keyof ISharedLoader>;

export const Loader = ({size, className, ...others}: TLoader) => {
  const {classes, cx} = useEmotionWrapper({size});

  return <span className={cx(classes.root, className ? className : '')} {...others}></span>;
};
