import {emotionWrapper} from '../../../shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 20,
    maxWidth: '70%',
  },
  contentBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));
