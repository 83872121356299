export const SummaryAnalyticsIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        strokeWidth={2}
        d="M8.5 15.5V11.125M12 15.5V8.5M15.5 15.5V13.75M17.25 5H6.75C6.28587 5 5.84075 5.18437 5.51256 5.51256C5.18437 5.84075 5 6.28587 5 6.75V17.25C5 17.7141 5.18437 18.1592 5.51256 18.4874C5.84075 18.8156 6.28587 19 6.75 19H17.25C17.7141 19 18.1592 18.8156 18.4874 18.4874C18.8156 18.1592 19 17.7141 19 17.25V6.75C19 6.28587 18.8156 5.84075 18.4874 5.51256C18.1592 5.18437 17.7141 5 17.25 5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
