export const HeartIcon = ({size = '16px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 136 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.60352 54.4433C2.60352 25.413 28.0718 3.06055 57.966 3.06055C73.3758 3.06055 86.9356 10.286 96.666 19.6353C106.396 10.286 119.956 3.06055 135.366 3.06055C165.26 3.06055 190.729 25.413 190.729 54.4433C190.729 74.3295 182.248 91.8173 170.499 106.6C158.77 121.356 143.428 133.88 128.738 144.057C123.128 147.944 117.451 151.53 112.256 154.176C107.378 156.66 101.77 158.936 96.666 158.936C91.5625 158.936 85.9536 156.66 81.0756 154.176C75.8807 151.53 70.2045 147.944 64.5937 144.057C49.904 133.88 34.5619 121.356 22.8331 106.6C11.084 91.8173 2.60352 74.3295 2.60352 54.4433ZM57.966 19.1855C35.6141 19.1855 18.7285 35.6234 18.7285 54.4433C18.7285 69.5309 25.1332 83.578 35.4565 96.5664C45.8001 109.58 59.7174 121.062 73.7765 130.802C79.0964 134.488 84.1072 137.624 88.3934 139.807C92.9965 142.151 95.6626 142.811 96.666 142.811C97.6694 142.811 100.336 142.151 104.939 139.807C109.225 137.624 114.236 134.488 119.556 130.802C133.615 121.062 147.532 109.58 157.876 96.5664C168.199 83.578 174.604 69.5309 174.604 54.4433C174.604 35.6234 157.718 19.1855 135.366 19.1855C122.529 19.1855 110.542 26.8548 103.058 36.589C101.531 38.5738 99.1698 39.737 96.666 39.737C94.1622 39.737 91.8006 38.5738 90.2744 36.589C82.7899 26.8548 70.8031 19.1855 57.966 19.1855Z"
        fill={color}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
