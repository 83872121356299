import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  liTitle: {
    display: 'flex',
    alignItems: 'center',
    span: {
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:hover': {
        color: COLORS.PURPLE_100,
        transition: 'color .3s ease',
      },
    },
  },
  copyToClipboard: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      cursor: 'pointer',
      '&:hover': {
        color: COLORS.PURPLE_100,
        transition: 'color .3s ease',
      },
    },
  },
}));
