import {FC, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {COLORS} from '@shared/const/appPalette';
import {DropdownIcon, SearchIcon} from '@src/components/common/icons';

import {useStore} from '../../../stores';
import {Typography} from '../..';

import {useEmotionWrapper} from './dropdown.s';

type TDropdownItem = {
  value: number | string;
  title: string;
};

interface IDropdown {
  items?: Array<TDropdownItem>;
  onClick: (item: TDropdownItem) => void;
  currentItemId?: number;
}

export const Dropdown: FC<IDropdown> = observer(({items, currentItemId, onClick}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {mpAccounts} = useStore();
  const {classes} = useEmotionWrapper({isOpen});
  const targetRef = useRef<HTMLButtonElement>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getCurrentItem = () => {
    if (items) {
      const findIndex = items?.findIndex((item) => item.value === currentItemId);
      return findIndex !== -1 ? items[findIndex].title : null;
    }
  };

  useEffect(() => {
    if (!isOpen) setSearchQuery('');
  }, [isOpen]);

  const filteredAccounts = (items || []).filter((item) => item.title.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <button
      data-cy="dropdown-mp-accounts"
      onMouseDown={() => setIsOpen(true)}
      onMouseUp={() => setIsOpen(true)}
      onKeyUp={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === ' ') {
          e.preventDefault();
        }
      }}
      className={classes.root}
      ref={targetRef}
    >
      <Typography whiteSpace="nowrap" cursor="pointer" color={COLORS.PURPLE_100}>
        {getCurrentItem()}
      </Typography>
      <div className={classes.icon}>
        <DropdownIcon size="10px" color={COLORS.PURPLE_100} />
      </div>
      {items && items?.length > 0 && (
        <div className={isOpen ? classes.dropdownContent : classes.hideContent}>
          {mpAccounts.items.length > 5 ? (
            <div
              onKeyUp={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              className={classes.search}
            >
              <input
                data-cy="dropdown-mp-accounts-search"
                placeholder="Поиск..."
                value={searchQuery}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <SearchIcon size="12" color={COLORS.DARK_70} />
            </div>
          ) : null}
          {(filteredAccounts || []).map((item) => (
            <div
              style={{color: currentItemId === item.value ? COLORS.PURPLE_100 : ''}}
              onClick={() => {
                onClick(item);
                setIsOpen(false);
              }}
              key={item?.value}
            >
              {item?.title}
            </div>
          ))}
        </div>
      )}
    </button>
  );
});
