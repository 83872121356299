import {type FC} from 'react';

import {useEmotionWrapper} from './table.s';

interface ITable {
  children: React.ReactNode;
}

export const Table: FC<ITable> = ({children}) => {
  const {classes} = useEmotionWrapper();

  return <table className={classes.root}>{children}</table>;
};
