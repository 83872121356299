export const RefreshIcon = ({size = '24px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M3.18286 2.83336V6.37503H3.59511M14.4723 7.7917C14.3156 6.54706 13.7505 5.38957 12.8653 4.50062C11.9802 3.61168 10.8252 3.04152 9.58121 2.8795C8.33726 2.71749 7.07471 2.97277 5.99142 3.60535C4.90814 4.23792 4.06534 5.21204 3.59511 6.37503M3.59511 6.37503H6.72453M14.5162 14.1667V10.625H14.1047M14.1047 10.625C13.6337 11.7873 12.7907 12.7607 11.7075 13.3927C10.6244 14.0247 9.36218 14.2797 8.1186 14.1177C6.87502 13.9558 5.72025 13.386 4.83507 12.4977C3.94989 11.6093 3.38428 10.4525 3.22678 9.20836M14.1047 10.625H10.9745"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
