import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper, keyframes} from '@shared/lib';

const menuAnimation = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    position: 'relative',
    zIndex: 1,
    ul: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      li: {
        padding: '10px 14px',
        position: 'relative',
        cursor: 'pointer !important',
        fontSize: 12,
        fontWeight: 500,
        transition: 'background-color .3s ease',
        '&:hover': {
          transition: 'background-color .3s ease',
          backgroundColor: COLORS.GRAY_100,
          '&:first-of-type': {
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          },
          '&:last-child': {
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
          },
        },
        '&: not(:last-child)': {
          ':after': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 1,
            backgroundColor: COLORS.GRAY_100,
          },
        },
      },
    },
  },
  menu: {
    position: 'absolute',
    top: 'calc(100% + 5px)',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    width: 'max-content',
    borderRadius: 10,
    boxShadow: '0px 0px 25px 3px rgba(34, 60, 80, 0.2)',
    animation: `${menuAnimation} 0.3s ease`,
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));
