import {FC} from 'react';

interface ITab {
  title: string;
  children: JSX.Element | string;
  path: string;
}

export const Tab: FC<ITab> = ({children}) => {
  return children;
};
