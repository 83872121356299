export const EditIcon = ({size = '24px', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <mask id="mask0_185_7078" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width={size} height={size} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_185_7078)">
        <path
          d="M5 19.5619H6.425L16.2 9.69837L14.775 8.26046L5 18.124V19.5619ZM3 21.58V17.2915L16.2 3.9972C16.4 3.81221 16.6208 3.66926 16.8625 3.56835C17.1042 3.46744 17.3583 3.41699 17.625 3.41699C17.8917 3.41699 18.15 3.46744 18.4 3.56835C18.65 3.66926 18.8667 3.82061 19.05 4.02243L20.425 5.4351C20.625 5.6201 20.7708 5.83873 20.8625 6.09099C20.9542 6.34325 21 6.59552 21 6.84778C21 7.11686 20.9542 7.37333 20.8625 7.61719C20.7708 7.86104 20.625 8.08388 20.425 8.28569L7.25 21.58H3ZM15.475 8.99203L14.775 8.26046L16.2 9.69837L15.475 8.99203Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
