import {FC, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {useEmotionWrapper} from './tabs.s';

interface ITabs {
  children: JSX.Element[];
  defaultTabIndex: number;
}

export const Tabs: FC<ITabs> = ({children, defaultTabIndex}) => {
  const {classes} = useEmotionWrapper();
  const navigate = useNavigate();
  const location = useLocation();

  const buttons = children.map((child, index) => {
    const isSelected = location.pathname.endsWith(child.props.path);
    return (
      <button
        key={index}
        className={`${classes.tab} ${isSelected ? classes.selected : ''}`}
        onClick={() => navigate(child.props.path)}
      >
        {child.props.title}
      </button>
    );
  });

  useEffect(() => {
    for (let i = 0; i < children.length; i++) {
      if (location.pathname.endsWith(children[i].props.path)) {
        defaultTabIndex = i;
        break;
      }
    }

    if (children[defaultTabIndex]) {
      navigate(children[defaultTabIndex].props.path);
    } else {
      navigate(children[0].props.path);
    }
  }, [location.pathname]);

  return (
    <div>
      <div>{buttons}</div>
      <div className={classes.container}>
        {children.map((child) => (location.pathname.endsWith(child.props.path) ? child.props.children : null))}
      </div>
    </div>
  );
};
