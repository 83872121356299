import {type FC} from 'react';

import {useEmotionWrapper} from './counter.s';

interface ICounter {
  count: number;
}

export const Counter: FC<ICounter> = ({count}) => {
  const {classes} = useEmotionWrapper();

  if (!count) return null;

  return <div className={classes.root}>{count}</div>;
};
