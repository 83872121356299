import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    height: 20,
    alignItems: 'center',
    gap: 10,
    paddingRight: 54,
  },
  input: {
    width: 48,
    input: {
      width: 40,
    },
  },
}));
