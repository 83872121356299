const YANDEX_METRIKA_ID = 94516167;

export const yandexMetrika = () => {
  const pagination = () => ym(YANDEX_METRIKA_ID, 'reachGoal', 'pagination');
  const tryFree = () => ym(YANDEX_METRIKA_ID, 'reachGoal', 'try_free');
  const newReg = () => ym(YANDEX_METRIKA_ID, 'reachGoal', 'new_reg');
  const newCabinet = () => ym(YANDEX_METRIKA_ID, 'reachGoal', 'new_cabinet');
  const itemsOnPage = () => ym(YANDEX_METRIKA_ID, 'reachGoal', 'items_on_page');

  return {
    targetFulfilled: {
      pagination,
      tryFree,
      newReg,
      newCabinet,
      itemsOnPage,
    },
  };
};
