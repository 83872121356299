import {Instance, types} from 'mobx-state-tree';

import {RECOMMENDATION_STRATEGY} from '@src/shared/enums/bidder-strategy';

export const RecommendationStrategyModel = types.enumeration('RecommendationStrategyModel', [
  RECOMMENDATION_STRATEGY.HIGH,
  RECOMMENDATION_STRATEGY.MEDIUM,
  RECOMMENDATION_STRATEGY.LOW,
]);

export type TRecommendationStrategyModel = Instance<typeof RecommendationStrategyModel>;
